import React, { useEffect, useState } from 'react'
import * as styles from './itemQuantityModal.module.scss'
import { Row, Col } from 'react-grid-system'
import { Item, ToolStatus } from '../../../../types'
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai'

import { Button, Image, Modal } from '../../,,/../../common'
import { truncate } from '../../../../common/utils/functions'
import { ToolTypes } from '../../../../types'

interface Props {
   isModalOpen: boolean
   handleClose: () => void
   handleAdjustTotal: (item: Item, val: string) => void
   handleCustomTotalChange: (e: any, item: Item) => void
   startWorkflowAction: () => void
   checkoutAction: any
   maxWidth?: string
   items: Item[]
   zIndex?: string
}

export const ItemQuantityModal: React.FunctionComponent<Props> = ({
   isModalOpen,
   handleClose,
   handleAdjustTotal,
   handleCustomTotalChange,
   startWorkflowAction,
   checkoutAction,
   maxWidth,
   items,
   zIndex,
}) => {
   const [isQuantityValid, setIsQuantityValid] = useState<boolean>(true)
   const [modalMessage, setModalMessage] = useState<string>(``)

   const isQuantitiesDisabled =
      (checkoutAction?.status === ToolStatus.AVAILABLE &&
         checkoutAction?.label === 'Warehouse Transfer') ||
      checkoutAction?.label === 'Make Available' ||
      checkoutAction?.label === 'Make Unavailable'

   useEffect(() => {
      switch (checkoutAction?.label) {
         case 'Make Available':
         case 'Make Unavailable':
            setModalMessage(
               `Quantity Items can't have their availability changed. Total quantities shown will be made ${
                  checkoutAction?.label === 'Make Available'
                     ? 'available'
                     : 'unavailable'
               }.`
            )
            break
         case 'Warehouse Transfer':
            const modalMsg =
               checkoutAction?.status === ToolStatus.AVAILABLE
                  ? `Quantity Items can't be split between Warehouses. Total quantities shown will be transferred.`
                  : `How many of the following item do you want to ${checkoutAction.label}?`
            setModalMessage(modalMsg)
            break
         default:
            setModalMessage(
               `How many of the following items do you want to ${checkoutAction.label}?`
            )
            break
      }
   }, [checkoutAction])

   // validate quantity
   useEffect(() => {
      if (
         items.some(
            (x) =>
               x.quantityForCart < 1 ||
               x.quantityForCart > x.StatusQuantity ||
               x.quantityForCart === ''
         )
      ) {
         setIsQuantityValid(false)
      } else {
         setIsQuantityValid(true)
      }
   }, [items])

   const getQuantityItemsFromPage = (array: Item[]) => {
      return array.filter(
         (x) =>
            x.ToolType == ToolTypes['Consumable'] ||
            x.ToolType == ToolTypes['Quantity Item']
      )
   }

   return (
      <Modal
         isModalVisible={isModalOpen}
         closeModal={handleClose}
         title={'Select Quantity'}
         maxWidth={maxWidth}
         zIndex={zIndex}
      >
         <div className={styles.warningModalMessageWrapper}>
            <p>{modalMessage}</p>
         </div>

         <section className={styles.selectionWrapper}>
            {getQuantityItemsFromPage(items).map((item, i) => {
               const isValid =
                  item.quantityForCart > 0 &&
                  item.quantityForCart <= item.StatusQuantity &&
                  !isNaN(item.quantityForCart)

               return (
                  <div key={item.localUniqueId + '_' + i}>
                     <section className={styles.itemRow}>
                        <div
                           className={
                              !isQuantitiesDisabled
                                 ? styles.totalSelector
                                 : styles.totalSelectorDisabled
                           }
                        >
                           <div
                              className={styles.incDecButton}
                              onClick={() => handleAdjustTotal(item, 'dec')}
                           >
                              <AiOutlineMinusCircle size={25} />
                           </div>
                           <input
                              className={
                                 isValid
                                    ? styles.quantityInput
                                    : styles.quantityInputInvalid
                              }
                              type="number"
                              id="quantity"
                              name="quantity"
                              min="1"
                              max="10000"
                              value={
                                 !isQuantitiesDisabled
                                    ? item.quantityForCart
                                    : item?.StatusQuantity
                              }
                              onChange={(e) => handleCustomTotalChange(e, item)}
                              style={
                                 isQuantitiesDisabled
                                    ? {
                                         pointerEvents: 'none',
                                         background: '#F3F3F3',
                                         color: 'darkgrey',
                                      }
                                    : {}
                              }
                           ></input>

                           <div
                              className={styles.incDecButton}
                              onClick={() => handleAdjustTotal(item, 'inc')}
                           >
                              <AiOutlinePlusCircle size={25} />
                           </div>
                        </div>
                        <span className={styles.availableTotal}>
                           {`${item.StatusQuantity} Available`}
                        </span>
                        <span className={styles.quantityImage}>
                           <Image
                              url={item.PictureURL}
                              alt={item.PictureURL}
                              defaultImagePadding={2}
                              width={30}
                           />
                        </span>
                        <span className={styles.itemNameSection}>
                           {truncate(item.Title, 45)}
                        </span>
                     </section>
                     {!isValid && (
                        <p className={styles.invalidWarning}>
                           Invalid quantity
                        </p>
                     )}
                  </div>
               )
            })}
         </section>
         <div className={styles.buttonRow}>
            <Row>
               <Col xs={12} sm={6} md={6} lg={6}>
                  <div className={styles.buttonWrapper}>
                     <Button
                        variant={'primary'}
                        onClick={() => startWorkflowAction()}
                        type="button"
                        isReset
                        disabled={!isQuantityValid}
                     >
                        {'Next'}
                     </Button>
                  </div>
               </Col>
               <Col xs={12} sm={6} md={6} lg={6}>
                  <div className={styles.buttonWrapper}>
                     <Button
                        variant={'tertiary'}
                        onClick={handleClose}
                        type="button"
                        isReset
                     >
                        Cancel
                     </Button>
                  </div>
               </Col>
            </Row>
         </div>
      </Modal>
   )
}
