const mimeTypes = [
   'image/png',
   'image/jpeg',
   'image/webp',
   'image/svg+xml',
   'image/gif',
   'image/bmp',
   'image/x-icon',
   'image/tiff',
   'image/heic',
]

export { mimeTypes }
