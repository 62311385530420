import React from 'react'
import { AppInsightsContextProvider } from './src/appInsights/appInsightsContext'
import { reactPlugin } from "./AppInsights";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { setConfiguration } from 'react-grid-system';

import GlobalContextProvider from './src/context/globalContextProvider';

import { RegisterProvider } from './src/context/registerContext';
import App from './src/components/app';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
        }
    }
});
setConfiguration({ gutterWidth: 10 });

// Sets default layout component in all pages. we don't have to wrap the component each time in every page
export const wrapPageElement = ({ element, props }) => {
    return (
        <AppInsightsContextProvider value={reactPlugin}>
            <QueryClientProvider client={queryClient}>
                <GlobalContextProvider>
                    <RegisterProvider>
                        <App {...props}>
                            {element}
                            <ReactQueryDevtools position='bottom-right' initialIsOpen={false} />
                        </App>
                    </RegisterProvider>
                </GlobalContextProvider>
            </QueryClientProvider>
        </AppInsightsContextProvider>
    );
}
