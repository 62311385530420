import React, { useEffect, useState } from 'react';
import GoogleMapReact, { ChangeEventValue, MapOptions } from 'google-map-react';
import classNames from 'classnames';

export { default as Pin } from './mapPin/mapPin';
import { executeReverseGeocodeLookup } from './mapHelperFunctions';
import { Props } from './map.type';
import * as styles from './map.module.scss';

const GMAP_API_KEY = process.env.GMAP_API_KEY;

const Map: React.FC<Props> = ({
    className,
    children,
    fullScreenControl = false,
    mapOptions = {},
    markers = [],
    selectedLocation,
    retrieveGeoLookupResults,
    handleCloseInfoCard,
    handleOpenInfoCard,
    isInfoCardOpen,
    fitToBounds = true,
    setIsMapZooming
}) => {
    const [center, setCenter] = useState({ lat: 34.0522, lng: -118.2437 });
    const [defaultCenter, setDefaultCenter] = useState(null) // a copy to return to when zooming out function
    const [{ map, maps }, setMaps] = useState({});

    const maxZoom = 15;
    const minZoom = 6;

    const defaultMapOptions: MapOptions = {
        controlSize: 28,
        scrollwheel: false,
        fullscreenControl: fullScreenControl,
    }

    const getMapBounds = (map, maps, markers) => {
        if (!maps || !map) {
            return;
        }

        const bounds = new maps.LatLngBounds();

        markers.forEach((marker) => {
            bounds.extend(new maps.LatLng(
                marker.lat,
                marker.lng,
            ));
        });
        return bounds;
    };

    const bindResizeListener = (map, maps, bounds) => {
        maps.event.addDomListenerOnce(map, 'idle', () => {
            maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds);
                if (handleCloseInfoCard) {
                handleCloseInfoCard();
                }
            });
        });
    };

    const handleGoogleMapLoaded = () => {
        if (!markers.length || !map) return;

        // Get bounds by our places
        const bounds = getMapBounds(map, maps, markers);

        // Fit map to bounds
        if (fitToBounds) {
        map?.fitBounds(bounds);
        }
        // Set new center
        const newCenter = bounds.getCenter();
        setCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
        if (!defaultCenter) { /// set only once
            setDefaultCenter({ lat: newCenter.lat(), lng: newCenter.lng() });
        }
        // Bind the resize listener
        bindResizeListener(map, maps, bounds);
    }
    
    const handleMapChange = (event: ChangeEventValue) => {
        const { lat, lng } = event.center;

        setCenter({ lat, lng })
    }

    useEffect(() => {
        handleGoogleMapLoaded();
    }, [markers, map, maps])


    // used to zoom to location and display info card
    useEffect(() => {
        if (selectedLocation) {
            executeReverseGeocodeLookup(
                { lat: selectedLocation.Latitude, lng: selectedLocation.Longitude },
                retrieveGeoLookupResults);

            // start zoom to location
            handleGoogleMapLoaded();
            setCenter({ lat: selectedLocation.Latitude, lng: selectedLocation.Longitude })
            let zoomId = setInterval(() => zoomFunction(zoomId, map), 200)
        } else if (!selectedLocation && (map && map.getZoom() >= minZoom)) { // TODO FIND A BETTER CONDITION THAN THIS
            setCenter(defaultCenter);
            let zoomOutId = setInterval(() => zoomOutFunction(zoomOutId, map), 200)
        }
    }, [selectedLocation]);

    function zoomFunction(intId, map ) {
        
        const mapZoom = map.getZoom()
        if (mapZoom >= maxZoom) {
            clearInterval(intId);
            handleOpenInfoCard();
            setIsMapZooming(false);
        }
        if (mapZoom < maxZoom) {
            map.setZoom(mapZoom + 0.5);
            setIsMapZooming(true);
        }
    }

    function zoomOutFunction(intId, map) {
        const mapZoom = map.getZoom()
        if (mapZoom === minZoom) {
            clearInterval(intId);
            setIsMapZooming(false);
        }
        if (mapZoom > minZoom) {
            map.setZoom(mapZoom - 1);
            setIsMapZooming(true);
        }
    }


    return (
        <div className={classNames(styles.wrapper, className)}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: GMAP_API_KEY }}
                zoom={minZoom}
                center={center}
                options={{ ...defaultMapOptions, ...mapOptions }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => setMaps({ map, maps })}
                onChange={handleMapChange}
            >
                {children}
            </GoogleMapReact>
        </div>
    );
}

export default Map;