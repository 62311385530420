import { Paths } from '../../constants/structure'

const routesForExpiredEnterprise = [
   Paths.MY_PROFILE,
   Paths.MY_PROFILE_ACCOUNT_SETTINGS,
   Paths.MY_PROFILE_RESET_PASSWORD,
   Paths.MY_PROFILE_SUBSCRIPTION,
   Paths.LOGIN,
   Paths.LOGOUT,
]

const navItemsForExpiredEnterprise = [
   Paths.MY_PROFILE,
   Paths.MY_PROFILE_SUBSCRIPTION,
   Paths.HELP,
   Paths.LOGOUT,
]

const routesForCompanyStarter = [
   Paths.MY_PROFILE_SUBSCRIPTION,
   Paths.LOGIN,
   Paths.LOGOUT,
]

const navItemsForCompanyStarter = [
   Paths.MY_PROFILE_SUBSCRIPTION,
   Paths.HELP,
   Paths.LOGOUT,
]

export {
   routesForExpiredEnterprise,
   navItemsForExpiredEnterprise,
   routesForCompanyStarter,
   navItemsForCompanyStarter,
}
