import React, { ChangeEvent, useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'

import { Props } from './search.types'
import * as styles from './search.module.scss'
import Svg from '../svg/svg'

export const Search: React.FC<Props> = ({
   handleQuery,
   setSearchMode,
   searchMode,
   pageSized,
   placeHolder,
   setSearchCriteria,
   onKeyUp,
   onKeyDown,
   isPageSearchReset,
   setIsPageSearchReset,
}) => {
   const [inputValue, setInputValue] = useState<string>('')
   const [debouncedValue] = useDebounce(inputValue, 1000)

   const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
   }

   useEffect(() => {
      if (isPageSearchReset) {
         handleReset()
         setIsPageSearchReset(false)
      }
   }, [isPageSearchReset])

   const handleReset = () => {
      setInputValue('')
      handleQuery('', 0, pageSized)
      setSearchMode(false)
   }

   useEffect(() => {
      setSearchCriteria(debouncedValue)
      handleQuery(debouncedValue, 0, pageSized)
      setSearchMode(true)
   }, [debouncedValue])

   return (
      <div className={styles.searchBar}>
         <input
            type="text"
            placeholder={`Search ${placeHolder}`}
            value={inputValue}
            className={styles.search}
            onChange={handleInput}
            onKeyDown={(event) => {
               if (event.keyCode === 13 || event.code === 'Enter') {
                  event.preventDefault()
               }
            }}
         />
         <span className={styles.searchGlass}>
            <Svg id="glass" />
         </span>

         <div
            role="button"
            className={styles.searchReset}
            onClick={(el) => (inputValue !== '' ? handleReset() : null)}
         >
            &times;
         </div>
      </div>
   )
}
