import { useMutation } from 'react-query';

import { useAPI } from "..";
import { PATH } from '../../constants/global';

import { UseItemKit  } from './useItemKit.type';

export default function(): UseItemKit {
    const request = useAPI();
    const { error, isSuccess: isAddKitSuccess, reset: resetAddSuccess, mutate } = useMutation((obj: {}) =>
       request.post(PATH.ITEMS.ADD_TO_KIT, { ...obj }));

    const addToKit = (obj: {}) => {
        mutate(obj);
    };

    return {
        // @ts-ignore
        error: error?.response?.data?.Message,
        isAddKitSuccess: isAddKitSuccess ? true : error ? false : null,
        resetAddSuccess,
        addToKit,
    };
}