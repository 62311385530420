import * as React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import { ImSpinner4 as Spinner } from 'react-icons/im'

import { ButtonProps } from './types'
import * as styles from './button.module.scss'

const Button = ({
   children,
   className,
   disabled,
   external,
   download = false,
   href,
   icon,
   loading = false,
   minWidth = '10rem',
   marginRight = '0',
   additionalStyles,
   onClick,
   type,
   variant = 'primary',
   preserveText = false,
   isReset = false,
   small = false,
   buttonRef = null,
   title,
   form,
}: ButtonProps) => {
   const classes = classNames(
      styles.btn,
      {
         [styles.btnPrimary]: variant === 'primary',
         [styles.btnPrimaryAnchor]: variant === 'primary-anchor',
         [styles.btnSecondary]: variant === 'secondary',
         [styles.btnTertiary]: variant === 'tertiary',
         [styles.btnDanger]: variant === 'danger',
         [styles.btnSuccess]: variant === 'success',
         [styles.btnPlain]: variant === 'plain',
         [styles.btnPlainV2]: variant === 'plainV2',
         [styles.btnPreserveText]: preserveText,
         [styles.btnSmall]: small,
      },
      className
   )
   const baseProps = {
      className: classes,
      disabled,
      style: { minWidth, marginRight, ...additionalStyles },
      title,
      download,
   }

   const renderButtonContent = () =>
      loading ? (
         <Spinner className={styles.btnSpinner} />
      ) : (
         <>
            {icon && <span className={styles.btnIcon}>{icon}</span>}
            <span>{children}</span>
         </>
      )

   if (type === 'link') {
      return external ? (
         <a
            {...baseProps}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
         >
            {renderButtonContent()}
         </a>
      ) : (
         <Link {...baseProps} to={href}>
            {renderButtonContent()}
         </Link>
      )
   }

   return (
      <button
         {...baseProps}
         form={form}
         onClick={onClick}
         type={isReset ? 'reset' : type ? type : null} // this needs to be phased out and explicitly state type
         ref={buttonRef ? buttonRef : null}
         disabled={Boolean(disabled)}
      >
         {renderButtonContent()}
      </button>
   )
}

export default Button
