import { useMutation } from 'react-query';

import { useAPI } from "..";
import { PATH } from '../../constants/global';

import { useResetPassword, ResetPasswordValues } from './useResetPassword.type';

export default function(): useResetPassword {
    const request = useAPI();
    const { error, isLoading, isSuccess, mutate } = useMutation((values: ResetPasswordValues) =>
        request.post(PATH.AUTH.RESET_PASSWORD, { ...values }));

    const submit = (values: ResetPasswordValues) => {
        mutate(values);
    };

    return {
        // @ts-ignore
        error: error?.response?.data?.Message,
        isLoading,
        isSuccess,
        submit,
    };
}