import React from 'react'
import * as styles from '../checkoutModal.module.scss'

export const ReturnView = () => (
   <>
      <input type="hidden" name="Return" value={'True'} />
      <div className={styles.centeredViewWrapper}>
         <div className={styles.centeredViewHeading}>Confirm Return</div>
         <p className={styles.centeredViewMessage}>
            Are you sure all of the selected items have been returned to its
            original warehouse?
         </p>
      </div>
   </>
)
