// initial pagination default
export const PAGINATION = {
   query: '',
   skip: 0,
   take: 10,
   ellipsisSkip: 10,
   rangeLabel: 'Items per page',
   all: 'ALL',
   index: 4,
   range1: 10,
   range2: 20,
   range3: 50,
   range4: 100,
   pageSized: 10,
}

// Table consts
export const TABLE = {
   TITLE: {
      MANUFACTURERS: 'Manufacturers',
      CATEGORIES: 'Categories',
      NOTETYPES: 'Note Types',
      ITEM_HISTORY: 'Item History',
      AUDIT_HISTORY: 'Audit History',
      COSTING_HISTORY: 'Costing History',
      NOTES_HISTORY: 'Note History',
      ITEMS: 'Items',
      LOCATIONS: 'Locations',
      NOTES: 'Notes',
      CONNECTIONS: 'Connections',
      AUDITS: 'Audits',
      ACTIONS: 'Actions Page',
   },
   COUNT_LABEL: 'Results',
   NO_RESULTS: 'No results have been found',
   NO_RESULTS_CART: 'Your cart is empty',
   HEADER: 'Header',
   CELL: 'Cell',
}

export const CRUD = {
   primaryActionText: 'Save',
   secondaryActionText: 'Cancel',
   primaryDeleteText: 'Delete',
}

// Actions for crud form
export enum Actions {
   Edit = 1,
   Delete = 2,
   Add = 3,
   Info = 4,
   Copy = 5,
   Export = 6,
   Readonly = 7,
   Make_Available = 8,
   Make_Unavailable = 9,
   View = 10,
   Custom = 11,
}

export enum Modes {
   Copy = 1,
}

// Views for view changer
export enum Views {
   Tile = 1,
   Tabular = 2,
   Map = 3,
}

// Titles for crud form
export const ACTION_TITLE = {
   1: 'Edit',
   2: 'Delete',
   3: 'Add',
}
