import { Dispatch, SetStateAction } from "react";

export interface CsvFormProps {
    primaryActionText: string,
    secondaryActionText: string,
    criteria?: any,
    closeModal: () => void,
    data: any,
    fileName: string,
    path: string,
    query: string,
    fullDownload: {},
    setIsDownloading?: (isDownloading: boolean) => void,
    isDownloading?: boolean,
    filters?: {},
    defaultParams?: {},
    buttonRef: unknown;
}

export interface CsvInnerProps {
    height: number,
    id: string,
    overrideViewBox?: string,
    text: string,
    width: number,
}

export interface CsvAnchorProps {
    fileName: string,
    path: {},
    text: string,
    params: any,
    setIsDownloading: (isDownloading: boolean) => void,
    setDownloadPercentage?:  Dispatch<SetStateAction<number>>,
    isDownloading?: boolean,
    filters?: {},
    defaultParams?: {}
}

export enum CsvActions {
    Filtered = 1,
    Full = 2,
}