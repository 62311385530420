import React, { memo, useMemo } from 'react'

import { PageHeader } from '../../common'
import { LayoutLoggedIn } from '../layout'
import { ErrorViewProps } from './types'

const UnauthorisedView = ({ isLoggedIn }: ErrorViewProps) => {
   const content = useMemo(
      () => (
         <>
            <PageHeader title="Unauthorised" />
            <p>
               You do not have permission to see this page. Please try another.
            </p>
         </>
      ),
      []
   )
   return (
      <>{isLoggedIn ? <LayoutLoggedIn>{content}</LayoutLoggedIn> : content}</>
   )
}

export default memo(UnauthorisedView)
