export type FormState = {
   city?: string
   company?: string
   confirmPassword?: string
   country?: string
   email?: string
   firstName?: string
   flowType?: FLOW_TYPES
   industryId?: string
   lastName?: string
   phoneNumber?: string
   password?: string
   roleName?: string
   stateProvince?: string
   confirmEmail?: string
   recaptchaToken?: string
}

export enum FLOW_TYPES {
   EXISTING = 'existing',
   TRIAL = 'trial',
   UPGRADE = 'upgrade',
}

export interface RegisterContextType {
   formState: FormState
   save: (formState: FormState) => void
}
