import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { Severity } from '../../../types'

import { Alert } from '..'
import { SnackBarProps, SnackBarState } from './types'
import * as styles from './snackBar.module.scss'

export const closedSnackbarState: SnackBarState = {
   disableAutoClose: false,
   message: '',
   severity: Severity.INFO,
}

const SnackBar = ({
   disableAutoClose = false,
   message,
   onClose,
   open = false,
   severity = Severity.ERROR,
   resetMessageStateHandler,
}: SnackBarProps) => {
   const [showing, setShowing] = useState(open)

   const handleClose = () => {
      setShowing(false)

      if (onClose) {
         onClose()
      }
   }

   useEffect(() => {
      setShowing(open)
   }, [open])

   useEffect(() => {
      if (!disableAutoClose) {
         let timeout

         if (showing) {
            timeout = setTimeout(() => {
               setShowing(false)
               handleClose()
               if (resetMessageStateHandler) {
                  resetMessageStateHandler()
               }
            }, 5000)
         } else {
            if (timeout) clearTimeout(timeout)
         }

         return () => {
            clearTimeout(timeout)
         }
      }
   }, [showing, disableAutoClose])

   const classes = classNames(styles.snackbar, {
      [styles.snackbarShowing]: open,
   })

   if (!showing) return null

   return (
      <div className={classes}>
         <Alert message={message} action={handleClose} severity={severity} />
      </div>
   )
}

export default SnackBar
