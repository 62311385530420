
import { useMutation, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI } from '..';

export interface UseAttachmentUpload {
    upload: (file: File) => Promise<void>
    isUploading: boolean;
    isUploadError: any;
    isUploadSuccess: boolean
    isDeleteError: unknown
    isDeleteAttachmentPending: boolean
    isDeleteSuccess: boolean
    attachmentData: any[] 
    removeAttachment: (obj: any) => void;
}

export default function(): UseAttachmentUpload {
    const request = useAPI();
 //  const { data, isLoading, refetch } = useQuery('attachments', getAttachmentFromAPI);
    
    const { data: uploadData, error: isUploadError, isLoading: isUploading, isSuccess: isUploadSuccess, mutate: attachmentMutate } = useMutation(uploadAttachment);

    const { error: isDeleteError, isLoading: isDeleteAttachmentPending, isSuccess: isDeleteSuccess, mutate: deleteMutate } = useMutation(deleteAttachment);

    async function uploadAttachment(file: File): Promise<AxiosResponse<any>> {
        return await request.post(
           PATH.ATTACHMENTS.ATTACHMENT_UPLOAD,
           file,
           {
               headers: {
                   'Content-Type': 'multipart/form-data'
               }
           }
        );
    }

    async function deleteAttachment(file: File): Promise<AxiosResponse<any>> {
        return await request.post(
           PATH.ATTACHMENTS.ATTACHMENT_REMOVE,
           file
        );
    }

    // functions returned from hook
    const removeAttachment = async (file: File) => {
        await deleteMutate(file);
    }

    const upload = async (file: File) => {
        await attachmentMutate(file);
    }

    return {
        attachmentData: uploadData?.data || [],
        upload,
        isUploading,
        // @ts-ignore
        isUploadError: isUploadError?.response?.data?.Message as string,
        removeAttachment,
        isUploadSuccess,
      //  isLoading,
        isDeleteError,
        isDeleteAttachmentPending,
        isDeleteSuccess,
    };
};







