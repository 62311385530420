import React from 'react'
import { svgIcons } from '../../../assets/svg/icons'
import { SvgProps } from './types'

const prefix = 'img'

const Svg = ({
   id,
   isHidden,
   children,
   fill,
   width,
   height,
   overrideViewBox,
   style,
}: SvgProps) => {
   if (isHidden) {
      return <svg display="none">{children}</svg>
   }
   let file = svgIcons.byId[id]
   if (!file) return null

   let viewBox = file.viewBox
      ? file.viewBox.join(' ')
      : '0 0 ' + width + ' ' + height

   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         version="1.1"
         preserveAspectRatio="xMinYMin"
         width={width || file.width || null}
         height={height || file.height || null}
         fill={fill || file.fill || null}
         viewBox={overrideViewBox || viewBox}
         id={id}
         style={style}
      >
         <use xlinkHref={'#' + prefix + '-' + id} />
         {file.data || null}
      </svg>
   )
}

export default Svg
