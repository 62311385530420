// extracted by mini-css-extract-plugin
export var acceptDecline = "notificationItem-module--accept-decline--TF-Qk";
export var acceptDeclineButton = "notificationItem-module--accept-decline-button--niRFb";
export var content = "notificationItem-module--content--3s1Ic";
export var created = "notificationItem-module--created--9RdlY";
export var dataLabel = "notificationItem-module--data-label--yNtxW";
export var description = "notificationItem-module--description--hnXEn";
export var dismiss = "notificationItem-module--dismiss--CwFrK";
export var image = "notificationItem-module--image--FTAFK";
export var infoItems = "notificationItem-module--info-items--xLqke";
export var keyValue = "notificationItem-module--key-value--zM5Ge";
export var location = "notificationItem-module--location--DIAUY";
export var notificationNoteWrapper = "notificationItem-module--notificationNoteWrapper--gKdU8";
export var wrapper = "notificationItem-module--wrapper--Y5p0o";