import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal } from '../../common'
import { useMediaQuery } from 'react-responsive'
import * as styles from './importItemsModal.module.scss'
import { Col, Row } from 'react-grid-system'
import { ProgressIndicator } from '../progressIndicator/progressIndicator'
import {
   ImportItemModalViews,
   ImportItemsModalProps,
} from './importItemsModal.type'
import HelpLink from '../helpLink/helpLink'
import { StaticImage } from 'gatsby-plugin-image'
import ImportOptionsView from './importOptionsView'
import ImportStatusView from './importStatusView'

const ImportItemsModal = ({
   isOpen,
   handleClose,
   uploadPercentage,
   setUploadPercentage,
   currentView,
   setCurrentView,
   importSelectedFile,
   handleSuccessfulImport,
   isImportingData,
   importError,
   importFileErrors,
   isImportingSuccess,
   totalItemsImported,
   totalImagesImported,
}: ImportItemsModalProps) => {
   const [selectedFile, setSelectedFile] = useState<File | null>(null)
   const [addImagesWithAI, setAddImagesWithAI] = useState<boolean>(false)
   const minScreenWidth = useMediaQuery({ query: '(max-width: 575px)' })

   useEffect(() => {
      if (isImportingSuccess) {
         setUploadPercentage(100)
         setCurrentView(ImportItemModalViews.SUCCESS)
      }
   }, [isImportingSuccess])

   useEffect(() => {
      if (importError) {
         setUploadPercentage(100)
         setCurrentView(ImportItemModalViews.ERROR)
      }
   }, [importError])

   useEffect(() => {
      if (isImportingData) {
         setCurrentView(ImportItemModalViews.IMPORTING)
      }
   }, [isImportingData])

   useEffect(() => {
      if (uploadPercentage > 0 && uploadPercentage < 100) {
         setCurrentView(ImportItemModalViews.IMPORTING)
      } else {
         setCurrentView(ImportItemModalViews.IMPORT_OPTIONS)
      }
   }, [isOpen])

   const handleReset = () => {
      setUploadPercentage(0)
      setSelectedFile(null)
      setCurrentView(ImportItemModalViews.IMPORT_OPTIONS)
   }

   const leftButtonDisabled = useMemo(
      () =>
         currentView === ImportItemModalViews.IMPORTING ||
         currentView === ImportItemModalViews.SUCCESS ||
         currentView === ImportItemModalViews.ERROR,
      [currentView]
   )

   const leftButtonText = useMemo(
      () =>
         currentView === ImportItemModalViews.IMPORT_OPTIONS
            ? 'Cancel'
            : 'Cancel Import',
      [currentView]
   )

   const handleLeftButtonClick = useMemo(
      () =>
         currentView === ImportItemModalViews.IMPORTING
            ? handleReset
            : handleClose,
      [currentView]
   )

   const rightButtonDisabled = useMemo(
      () =>
         (currentView === ImportItemModalViews.IMPORT_OPTIONS &&
            !selectedFile) ||
         currentView === ImportItemModalViews.IMPORTING,
      [currentView, selectedFile]
   )

   const rightButtonText = useMemo(() => {
      switch (currentView) {
         case ImportItemModalViews.IMPORT_OPTIONS:
            return 'Import'
         case ImportItemModalViews.IMPORTING:
            return 'Close'
         case ImportItemModalViews.SUCCESS:
         case ImportItemModalViews.ERROR:
         default:
            return 'Close'
      }
   }, [currentView])

   const handleRightButtonClick = useMemo(() => {
      switch (currentView) {
         case ImportItemModalViews.IMPORT_OPTIONS:
            return async () => {
               setUploadPercentage(0)
               await importSelectedFile(selectedFile, addImagesWithAI)
               setCurrentView(ImportItemModalViews.IMPORTING)
            }
         case ImportItemModalViews.SUCCESS:
            return handleSuccessfulImport
         default:
            return handleClose
      }
   }, [currentView, selectedFile, addImagesWithAI])

   const progressView = useMemo(
      () => (currentView === ImportItemModalViews.ERROR ? 2 : currentView - 1),
      [currentView]
   )

   return (
      <Modal
         isModalVisible={isOpen}
         closeModal={handleClose}
         maxWidth={''}
         zIndex={'999'}
         stickyHeader={true}
         title={'Import Items'}
         headerColour={'#FFCA05'}
         hideCloseIcon
      >
         <div className={styles.contentWrapper}>
            <Row>
               <Col xs={6} sm={6} md={6} lg={6} className={styles.column}>
                  {currentView === ImportItemModalViews.IMPORT_OPTIONS ? (
                     <ImportOptionsView
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        addImagesWithAI={addImagesWithAI}
                        setAddImagesWithAI={setAddImagesWithAI}
                     />
                  ) : (
                     <ImportStatusView
                        addImagesWithAI={addImagesWithAI}
                        currentView={currentView}
                        uploadPercentage={uploadPercentage}
                        importError={importError}
                        importFileErrors={importFileErrors}
                        totalItemsImported={totalItemsImported}
                        totalImagesImported={totalImagesImported}
                     />
                  )}
                  <div className={styles.bottomSection}>
                     <div className={styles.buttonsWrapper}>
                        <Button
                           minWidth="18rem"
                           onClick={handleLeftButtonClick}
                           variant="tertiary"
                           disabled={leftButtonDisabled}
                           type="button"
                           className={leftButtonDisabled && styles.hideButton}
                        >
                           {leftButtonText}
                        </Button>
                        <Button
                           minWidth="18rem"
                           onClick={handleRightButtonClick}
                           variant="primary"
                           disabled={rightButtonDisabled}
                           type="button"
                           className={
                              rightButtonDisabled &&
                              currentView !==
                                 ImportItemModalViews.IMPORT_OPTIONS &&
                              styles.hideButton
                           }
                        >
                           {rightButtonText}
                        </Button>
                     </div>
                     <div
                        className={
                           minScreenWidth
                              ? styles.progressIndicatorWrapperLeft
                              : styles.progressIndicatorWrapperCenter
                        }
                     >
                        <ProgressIndicator
                           totalViews={3}
                           currentView={progressView}
                        />
                     </div>
                  </div>
               </Col>
               <Col xs={6} sm={6} md={6} lg={6} className={styles.column}>
                  <div className={styles.rightColumn}>
                     <div className={styles.helpLinkWrapper}>
                        <HelpLink href="https://help.sharemytoolbox.com/knowledge/item-import-and-export" />
                     </div>
                     <StaticImage
                        src={'../../../assets/images/importItems/excel.png'}
                        className={styles.topImage}
                        formats={['png']}
                        alt={'Download the import template'}
                     />
                     <StaticImage
                        src={'../../../assets/images/importItems/upload.png'}
                        className={styles.middleImage}
                        formats={['png']}
                        alt={'Upload your items'}
                     />
                     <StaticImage
                        src={'../../../assets/images/importItems/tools.png'}
                        className={styles.bottomImage}
                        formats={['png']}
                        alt={'Add images'}
                     />
                  </div>
               </Col>
            </Row>
         </div>
      </Modal>
   )
}

export default ImportItemsModal
