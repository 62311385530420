import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI } from '..';

import { UseAuditStatus, AuditStatus, AuditStatusRequest } from './useAuditStatus.type';

export default (): UseAuditStatus => {
    const request = useAPI();
    const { data, isLoading } = useQuery('audit-statuses', getAuditStatusesFromAPI);

    async function getAuditStatusesFromAPI(): Promise<AxiosResponse<AuditStatusRequest>> {
        try {
            return await request.get(PATH.AUDIT_HISTORY.LIST_STATUS);
        } catch (error) {
            // TODO: handle errors better
            console.log(error);
        }
    }

    return {
        isLoading,
        list: data?.data?.Data || [] as AuditStatus[],
    }
}