import React, { useMemo } from 'react'

import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import { IoIosArrowDown } from 'react-icons/io'
import classNames from 'classnames'

import * as styles from './navItem.module.scss'
import { isChildOfItem } from '../../../../common/utils/navigation'
import Svg from '../../svg/svg'
import { NavItemProps } from './types'

export const NavItem = ({
   children,
   className,
   currentNavItem,
   external,
   icon,
   iconSize,
   color,
   isOpen = false,
   isPrimary,
   label,
   route,
   onToggle,
}: NavItemProps) => {
   const { pathname } = useLocation()

   const hasIcon = isPrimary && icon
   const hasChildren = !!children?.length
   // const activeItemClass = 'nav-item-link-active';

   const currentItemHasSelectedChild = useMemo(
      () => isChildOfItem(children, pathname),
      [pathname, children]
   )

   const handleToggleClick = () => onToggle()

   const renderIcon = () =>
      hasIcon && (
         <span className={styles.navItemIcon} style={color ? { color } : null}>
            <Svg
               id={icon}
               height={iconSize || 26}
               width={icon === 'imageMatch' ? 26 : undefined}
            />
         </span>
      )

   const renderLink = () => {
      if (external) {
         return (
            <a className={styles.navItemLink} href={route.path} target="_blank">
               {renderIcon()}
               {label}
            </a>
         )
      }

      return (
         <Link
            className={styles.navItemLink}
            // activeClassName={activeItemClass}
            to={route.path}
         >
            {renderIcon()}
            {label}
         </Link>
      )
   }

   const renderToggleButton = () => (
      <button className={styles.navItemLink} onClick={handleToggleClick}>
         {renderIcon()}
         <span>{label}</span>
         <span
            className={classNames(styles.navItemArrow, {
               [styles.navItemArrowActive]:
                  currentItemHasSelectedChild || isOpen,
            })}
         >
            <IoIosArrowDown />
         </span>
      </button>
   )

   return (
      <li
         className={classNames(className, styles.navItem, {
            [styles.navItemPrimary]: isPrimary,
            [styles.isActive]: currentItemHasSelectedChild || isOpen,
         })}
      >
         {hasChildren ? renderToggleButton() : renderLink()}

         {hasChildren && (
            <ul hidden={!isOpen}>
               {children.map((child) => (
                  <NavItem
                     key={`${child.route.path}-${child.label}`}
                     {...child}
                     className={styles.navItemChild}
                     currentNavItem={currentNavItem}
                  />
               ))}
            </ul>
         )}
      </li>
   )
}
