import React from 'react';
import { RadioButtonProps } from './radioButton.type'
import classNames from 'classnames';

import * as styles from './radioButton.module.scss';

const RadioButton: React.FunctionComponent<RadioButtonProps> = ({
      children,
      id,
      groupName = 'radioGroup',
      action,
      getList,
      buttonRef,
      resetRef,
      variant = 'standard',
      disabled = false,
      checked = false,
      preSelected = false,
      style,
      hide=  false
    }) => {

    const classes = classNames(
        styles.radioButton,
        {
            [styles.radioButtonStandard]: variant === 'standard',
            [styles.radioButtonIcon]: variant === 'icon',
            [styles.radioButtonFilter]: variant === 'filter',
            [styles.buttonReset]: !!buttonRef,
            [styles.checked]: preSelected,
            [styles.checked]: checked,
        }
    );

    const baseProps = {
        className: classes,
    }

    const ref = buttonRef ? buttonRef : resetRef;

    return hide ? null : (
        <div { ...baseProps }>
            <input
                key={ id }
                disabled={ disabled }
                ref={ ref }
                type="radio"
                id={ id }
                name={ groupName }
                onClick={ getList || action }
                defaultChecked={ checked }
            />
            <label htmlFor={ id } style={style}>{ children }</label>
        </div>
    )
}

export default RadioButton;