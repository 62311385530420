import React, { createContext, useState } from 'react'

import { FormState, RegisterContextType } from './registerContext.type'

export const initialFormState: FormState = {}

const initialState = {
   formState: initialFormState,
}

export const RegisterContext =
   createContext<Partial<RegisterContextType>>(initialState)

export const RegisterProvider = ({ children }) => {
   const [formState, setFormState] = useState<FormState>(initialState.formState)

   return (
      <RegisterContext.Provider value={{ formState, save: setFormState }}>
         {children}
      </RegisterContext.Provider>
   )
}
