import React from 'react';
import { Row, Col } from 'react-grid-system';
import classNames from 'classnames';

import { Button } from '..';

import { Props } from './drawer.type';
import * as styles from './drawer.module.scss';

const Drawer: React.FC<Props> = props => {
    const { children, heading, icon, onReset, onSave } = props;

    return (
        <>
            <div className={styles.titleWrapper}>
                <div className={styles.titleIcon}>
                    {icon}
                </div>
                <h2 className={styles.title}>{heading}</h2>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <Row>
                        <Col xs={6}>
                            <Button
                                className={styles.actionButton}
                                onClick={onSave}
                                minWidth='auto'
                            >
                                Apply
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button
                                className={classNames(styles.actionButton, styles.actionButtonReset)}
                                onClick={onReset}
                                minWidth='auto'
                                variant='plain'
                                preserveText
                            >
                                Reset
                            </Button>
                        </Col>
                    </Row>
                </div>

                <div>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Drawer;