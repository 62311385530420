import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI } from '..';

import { UseAllItems, ItemsResponse } from './useAllItems.type';


export default function(filters?: { [key: string]: string | number }): UseAllItems {
    const request = useAPI();
    const { data, isLoading, isRefetching, refetch: refetchItems } = useQuery('all-items', getAllItemsFromAPI);

    async function getAllItemsFromAPI(): Promise<AxiosResponse<ItemsResponse>> {
        const { take, skip, ...rest  } = filters || {};

        return request.post(PATH.ITEMS.LIST, { ...rest, isForMultiselectFilter: true });
    }

    const refetch = () => {
        refetchItems();
    }

    return {
        isLoading,
        isRefetching,
        list: data?.data || [],
        refetch,
    };
};