import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import {
   UseMassTransferConnections,
   Connection,
   ConnectionsResponse,
} from './useMassTransferConnections.type'

export default (connectionRequestType): UseMassTransferConnections => {
   const request = useAPI()
   const { data, isLoading } = useQuery(
      'massTransfer-connections',
      getConnectionsFromAPI
   )

   async function getConnectionsFromAPI(): Promise<
      AxiosResponse<ConnectionsResponse>
   > {
      return request.get(
         `${PATH.CONNECTIONS.MASS_TRANSFER}${connectionRequestType}`
      )
   }

   return {
      isLoading,
      list: data?.data || ([] as Connection[]),
   }
}
