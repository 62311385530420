/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function Seo({
   description = '',
   footnote = '',
   lang = 'en',
   meta = [],
   title,
}) {
   const { site } = useStaticQuery(
      graphql`
         query {
            site {
               siteMetadata {
                  title
                  description
                  footnote
                  author
               }
            }
         }
      `
   )

   const metaDescription = description || site.siteMetadata.description
   const defaultTitle = site.siteMetadata?.title
   const footnoteMeta = footnote || site.siteMetadata.footnote

   return (
      <Helmet
         htmlAttributes={{
            lang,
         }}
         title={title}
         titleTemplate={defaultTitle ? `%s - ${defaultTitle}` : null}
         meta={[
            {
               name: `description`,
               content: metaDescription,
            },
            {
               name: `footnote`,
               content: footnoteMeta,
            },
            {
               property: `og:title`,
               content: title,
            },
            {
               property: `og:description`,
               content: metaDescription,
            },
            {
               property: `og:type`,
               content: `website`,
            },
            {
               name: `twitter:card`,
               content: `summary`,
            },
            {
               name: `twitter:creator`,
               content: site.siteMetadata?.author || ``,
            },
            {
               name: `twitter:title`,
               content: title,
            },
            {
               name: `twitter:description`,
               content: metaDescription,
            },
         ].concat(meta)}
      />
   )
}

export default Seo
