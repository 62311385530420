import { ObjectSchema } from 'yup';

import { Filters, FiltersForAPI, FilterErrors } from './useFilters.type';

export function flattenFilterObj (filters: Filters): FiltersForAPI {
    return Object.entries(filters).reduce((newFilters: FiltersForAPI, [name, filter]) => {
        if (Array.isArray(filter.value)) {
            return { ...newFilters, [name]: filter.value.map(option => option?.value)};
        }

        return { ...newFilters, [name]: filter.value };
    }, {});
}

export function cleanFilterObject (filters: Filters): Filters {
    return Object.entries(filters).reduce((newFilters, [key, valueObj]) => {
        // having to return data early when value is a date because value is
        // returned as 'false` for !value on line 15 below
        if (valueObj?.value instanceof Date) {
            return { ...newFilters, [key]: { label: valueObj?.label, value: valueObj?.value }};
        }

        if (typeof valueObj?.value === 'boolean') {
            return !valueObj?.value ? newFilters : {
                ...newFilters,
                [key]: { label: valueObj?.label, value: valueObj?.value }
            };
        }

        // @ts-ignore
        if (valueObj?.value instanceof Date || (!valueObj?.value || !valueObj?.value.length)) {
            return newFilters;
        }

        return { ...newFilters, [key]: { label: valueObj?.label, value: valueObj?.value } };
    }, {});
}

export function removeFilter (filters: Filters, name: string, id: string): Filters {
    const removedFilters = Object.entries(filters).reduce((newFilters, [filterName, filterValue]) => {
        if (name === filterName) {
            if (Array.isArray(filterValue?.value)) {
                const newArrayValues = filterValue?.value?.filter(option => option?.value !== id);

                return {...newFilters, [filterName]: {label: '', value: newArrayValues}};
            }

            return newFilters;
        }

        return { ...newFilters, [filterName]: filterValue};
    }, {});

    return cleanFilterObject(removedFilters);
}

export async function validate(schema: ObjectSchema<any>, values: any): Promise<FilterErrors> {
    await schema.validate(values, { abortEarly: false });
    return schema.cast(values);
}