import * as React from 'react'

interface Props {
   name: string
   value: boolean
   hideEnabledDisabled?: boolean
}

export const ToggleInfo: React.FC<Props> = ({
   name,
   value,
   hideEnabledDisabled,
}) => {
   return (
      <div className={`toggle-info toggle-info-${value ? '' : 'disabled'}`}>
         {name}
         {!hideEnabledDisabled && (
            <>
               <br />
               {value ? 'Enabled' : 'Disabled'}
            </>
         )}
      </div>
   )
}
