import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { UseTableData, AuditHistoryOptions } from '../../types'
import { useAPI } from '../'

export default function (): UseTableData {
   const request = useAPI()
   const [pageOptions, setPageOptions] = useState<AuditHistoryOptions>()

   const [config, setConfig] = useState({
      LIST: '',
      cacheIdentifier: '',
      key: '',
   })

   const cacheIdentifier = config.cacheIdentifier

   async function getItemsFromAPI(options: {}): Promise<AxiosResponse> {
      if (config.cacheIdentifier) {
         return request.post(config.LIST, {
            ...options,
         })
      }
   }

   const setPath = (path, cacheIdentifier) => {
      {
         path &&
            setConfig({
               LIST: path.LIST,
               cacheIdentifier,
               key: cacheIdentifier[0].toUpperCase() + cacheIdentifier.slice(1),
            })
      }
   }

   const { data, refetch, isLoading, isRefetching } = useQuery<
      Promise<AxiosResponse>
   >([cacheIdentifier], () => getItemsFromAPI(pageOptions))

   const list = (obj) => {
      setPageOptions(obj)
   }

   useEffect(() => {
      refetch()
   }, [pageOptions])

   return {
      data: data?.data?.[`${config.key}`] || [],
      list,
      setPath,
      setPageOptions,
      isLoading,
      isRefetching,
      totalItems: data?.data?.TotalCount,
   }
}
