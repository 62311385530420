import React, { memo, useMemo } from 'react'
import Button from '../button/button'
import Svg from '../svg/svg'
import { ImageMatchButtonProps } from './types'
import * as styles from './imageMatchButton.module.scss'

const ImageMatchButton = ({
   isTertiary,
   onClick,
   disabled,
   tooltip,
}: ImageMatchButtonProps) => {
   const icon = useMemo(
      () => (
         <Svg
            id="imageMatch"
            fill={isTertiary ? '#373737' : 'white'}
            width={24}
         />
      ),
      [isTertiary]
   )
   return (
      <Button
         type="button"
         className={styles.imageMatchButton}
         variant={isTertiary ? 'tertiary' : 'primary'}
         preserveText
         onClick={onClick}
         disabled={disabled}
         title={tooltip}
         icon={icon}
      >
         Image Match
      </Button>
   )
}

export default memo(ImageMatchButton)
