import React from 'react'

import { Button } from '../../../../common'

import * as styles from './authFlowForm.module.scss'
import { AuthFlowFormProps } from './types'

const AuthFlowForm = ({
   button,
   children,
   isLoading = false,
   onSubmit,
   returnLink,
   disabled = false,
   ...rest
}: AuthFlowFormProps) => (
   <form
      className={styles.wrapper}
      noValidate
      onSubmit={onSubmit && onSubmit}
      {...rest}
   >
      {children}

      {returnLink && <div className={styles.returnLink}>{returnLink}</div>}

      {button && (
         <Button
            loading={isLoading}
            minWidth="100%"
            variant="secondary"
            disabled={disabled}
         >
            {button?.label}
         </Button>
      )}
   </form>
)

export default AuthFlowForm
