// extracted by mini-css-extract-plugin
export var addItemsDraggable = "crudForm-module--add-items-draggable--6ZssS";
export var addToKitModalDropdown = "crudForm-module--addToKitModalDropdown--G3MTN";
export var addToKitModalMessageWrapper = "crudForm-module--addToKitModalMessageWrapper--9VKlG";
export var basicForm = "crudForm-module--basic-form--PCqq3";
export var buttonContainer = "crudForm-module--button-container--L17-C";
export var buttonItem = "crudForm-module--button-item--sfbvF";
export var centerText = "crudForm-module--center-text--qwPL6";
export var crudItemDescription = "crudForm-module--crudItem-description--hJ-dB";
export var crudItemDescriptionBefore = "crudForm-module--crudItem-description-before--jmsIT";
export var crudItemToggle = "crudForm-module--crudItem-toggle--NgSle";
export var crudItemToggleInfo = "crudForm-module--crudItem-toggleInfo--d3dRh";
export var crudItemWrapper = "crudForm-module--crudItem-wrapper--GKf5y";