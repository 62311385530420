import React from 'react'

import * as styles from './checklist.module.scss'
import { Props } from './checklist.type'
import Svg from '../svg/svg'

const Checklist: React.FC<Props> = ({ intro, items = [] }) => {
   return (
      <div>
         {intro && <p className={styles.intro}>{intro}</p>}
         {!!items.length && (
            <ul className={styles.list}>
               {items.map((item, index) => (
                  <li key={index} className={styles.listItem}>
                     <span className={styles.icon}>
                        <Svg id="tick-circle" width="25" height="25" />
                     </span>
                     <span>{item}</span>
                  </li>
               ))}
            </ul>
         )}
      </div>
   )
}

export default Checklist
