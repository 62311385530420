import React, { useState } from 'react'
import * as styles from './crudItems.module.scss'
import * as stylesCrudForm from '../crud/crudForm.module.scss'
import { Col, Row } from 'react-grid-system'
import { DeleteKitProps } from './crudItems.type'
import { Button } from '../../common'

export const DeleteKit: React.FunctionComponent<DeleteKitProps> = ({
   handleDeleteKit,
   cancel,
   title,
   subTitle = 'Are you sure you want to remove this item from Kit?',
   actionButton = 'REMOVE',
   itemForm,
}) => {
   return (
      <div>
         <div className={stylesCrudForm.basicForm}>
            <p>{title}</p>
            <Row>
               <Col>
                  <p>{subTitle}</p>
               </Col>
            </Row>

            <Row>
               <Col>
                  <Button
                     variant="danger"
                     onClick={() =>
                        handleDeleteKit({
                           KitRemoveIDs: [itemForm.KitPrimeKey],
                        })
                     }
                  >
                     {actionButton}
                  </Button>
               </Col>
               <Col>
                  <Button variant="tertiary" onClick={cancel}>
                     Cancel
                  </Button>
               </Col>
            </Row>
         </div>
      </div>
   )
}
