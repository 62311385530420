import React, { Children, forwardRef, useRef } from 'react'
import Select, { components, createFilter } from 'react-select'

import { FieldWrapper } from '..'

import { DropdownProps } from './dropdown.type'
import styles from './dropdown.styles'
import multiSelectStyles from './dropdownMultSelectStyles.styles'

const Dropdown = forwardRef(
   (
      {
         className,
         defaultValue,
         description,
         insetDescription = false,
         disabled = false,
         error,
         isClearable = true,
         isLoading,
         isMulti,
         id,
         name,
         noMargin = false,
         onChange,
         onTextChange,
         options,
         required,
         withWrappers = true,
         menuPosition = null,
         maxMenuHeight = 215,
         minMenuHeight = 215,
         placeholder,
         excludeOptionValueFromSearch = false,
         menuPlacement = 'bottom',
      }: DropdownProps,
      ref
   ): JSX.Element => {
      const inputRef = useRef()

      const elRef = ref || inputRef

      const renderElement = () => {
         const { ValueContainer, Placeholder } = components
         // custom value container allows for a persistent placeholder on select
         const CustomValueContainer = ({ children, ...props }) => {
            return (
               <ValueContainer {...props}>
                  <Placeholder {...props} isFocused={props.isFocused}>
                     {props.selectProps.placeholder}
                  </Placeholder>
                  {Children.map(children, (c) => {
                     return c && c.key !== 'placeholder' ? c : null
                  })}
               </ValueContainer>
            )
         }

         return (
            <Select
               filterOption={
                  excludeOptionValueFromSearch
                     ? createFilter(
                          {
                             matchFrom: 'any',
                             stringify: (option) => `${option.label}`,
                          } // stops the search from filtering on ID
                       )
                     : createFilter({
                          matchFrom: 'any',
                          stringify: (option) =>
                             `${option.value} ${option.label}`,
                       })
               }
               className={className}
               isClearable={isClearable}
               isLoading={isLoading}
               isMulti={isMulti}
               name={name}
               onChange={onChange}
               onBlur={
                  onTextChange
                     ? (e) => onTextChange(e.target.value, name)
                     : null
               }
               options={options}
               ref={elRef}
               styles={isMulti ? multiSelectStyles : styles}
               value={defaultValue}
               placeholder={required ? `${placeholder} *` : placeholder}
               isDisabled={disabled}
               menuPosition={menuPosition}
               menuPlacement={menuPlacement}
               maxMenuHeight={maxMenuHeight}
               minMenuHeight={minMenuHeight}
               components={
                  !isMulti ? { ValueContainer: CustomValueContainer } : null
               }
            />
         )
      }

      if (!withWrappers) {
         return renderElement()
      }

      return (
         <FieldWrapper
            description={description}
            insetDescription={insetDescription}
            disabled={disabled}
            error={error}
            id={id}
            required={required}
            noMargin={noMargin}
         >
            {renderElement()}
         </FieldWrapper>
      )
   }
)

export default Dropdown
