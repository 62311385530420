// extracted by mini-css-extract-plugin
export var column = "crudItems-module--column--kHfaz";
export var columnImageActionButtons = "crudItems-module--column-image-action-buttons--+Icfz";
export var columnImages = "crudItems-module--column-images--omcSF";
export var columnOne = "crudItems-module--column-one--iGgvk";
export var columnOneThree = "crudItems-module--column-one-three--NGYq7";
export var columnThreeThree = "crudItems-module--column-three-three--gYtWo";
export var columnTwo = "crudItems-module--column-two--QSOap";
export var columnTwoThree = "crudItems-module--column-two-three--zemlc";
export var combinedElement = "crudItems-module--combined-element--5hPjk";
export var copyPrompt = "crudItems-module--copy-prompt--gRdLw";
export var crudItems = "crudItems-module--crud-items--tyhkI";
export var currency = "crudItems-module--currency--CB5FZ";
export var detailsPanel = "crudItems-module--details-panel--M30Xy";
export var detailsPanelTrackingInfo = "crudItems-module--details-panel-tracking-info--QryDk";
export var dropdown = "crudItems-module--dropdown--ZG960";
export var dropdownClear = "crudItems-module--dropdown-clear--XfyFj";
export var dropdownWrapper = "crudItems-module--dropdown-wrapper---dkVB";
export var inputElement = "crudItems-module--input-element--zOOzp";
export var inputLabel = "crudItems-module--input-label--At5c6";
export var itemColumns = "crudItems-module--item-columns--nFrF7";
export var itemDetail = "crudItems-module--itemDetail--rdvfd";
export var itemDetailLabel = "crudItems-module--itemDetailLabel--2krxI";
export var itemDetailValue = "crudItems-module--itemDetailValue--W1zpG";
export var itemDetailValue_lat_lng = "crudItems-module--itemDetailValue_lat_lng--2zoHv";
export var itemDetailsBlock = "crudItems-module--item-details-block--T-pHF";
export var itemHistoryContainer = "crudItems-module--item-history-container--qSY1v";
export var itemMainImage = "crudItems-module--itemMainImage--VMZ7f";
export var itemNotesContainer = "crudItems-module--item-notes-container--W6ejU";
export var mask = "crudItems-module--mask--heUds";
export var notCompleted = "crudItems-module--not-completed--i6RLu";
export var note = "crudItems-module--note--tDf5N";
export var placeholder = "crudItems-module--placeholder--n3fS8";
export var row = "crudItems-module--row--Hmb0T";
export var status = "crudItems-module--status--ymCS5";
export var statusAvailable = "crudItems-module--status-available--2JFcf";
export var statusBorrowed = "crudItems-module--status-borrowed--vVjrO";
export var statusLoaned = "crudItems-module--status-loaned--Y-IZN";
export var statusPending = "crudItems-module--status-pending--FhN74";
export var statusUnavailable = "crudItems-module--status-unavailable--7B0b0";
export var timeline = "crudItems-module--timeline--Iz0Mk";
export var toggle = "crudItems-module--toggle--V4cfB";
export var toggleInfo = "crudItems-module--toggleInfo--1lTV3";
export var toggleWrapper = "crudItems-module--toggleWrapper--foNKG";
export var totalValue = "crudItems-module--total-value--AE6ri";
export var totalValueKey = "crudItems-module--total-value-key--VgzGZ";
export var zoomInIcon = "crudItems-module--zoomInIcon--QjmVj";