import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI } from '../';

import { UseIndustries } from './useIndustries.type';

const cacheIdentifier = 'industries';

export default function(): UseIndustries {
    const request = useAPI();
    const { data, isLoading } = useQuery(cacheIdentifier, getIndustriesFromAPI);

    async function getIndustriesFromAPI(): Promise<AxiosResponse> {
        return request.get(PATH.INDUSTRIES.GET);
    }

    return {
        industries: data?.data?.Data || [],
        isLoading,
    };
};