import React, { ChangeEvent, memo, useRef } from 'react'
import * as styles from './uploadImageButton.module.scss'
import { UploadImageButtonProps } from './types'
import Svg from '../svg/svg'
import { canvasSize, dataURLtoFile } from './helpers'

const UploadImageButton = ({
   onImageUpload,
   isDisabled,
}: UploadImageButtonProps) => {
   const ref = useRef<HTMLInputElement>()
   const canvasRef = useRef<HTMLCanvasElement>()

   const onClick = () => ref?.current?.click()

   const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event?.target?.files[0] ?? null
      if (file) {
         const fileData = new FileReader()
         fileData.readAsDataURL(file)
         fileData.onloadend = (e: ProgressEvent<FileReader>) =>
            handleFileChange(e, file)
      }
   }

   const handleFileChange = (e: ProgressEvent<FileReader>, file: Blob) => {
      // @ts-ignore
      const imageUrl: string = e.currentTarget.result // base64
      const mimeType: string = imageUrl.match(
         new RegExp('data:' + '(.*)' + ';base64')
      )[1]
      const thumbName = 'thumb_' + file['name']

      const ctx = canvasRef?.current.getContext('2d')
      function draw(ctx) {
         ctx.clearRect(0, 0, ctx.width, ctx.height)
         requestAnimationFrame(() => draw(ctx))
      }
      requestAnimationFrame(() => draw(ctx))

      const url = URL.createObjectURL(file)
      const img = new Image()

      img.src = url
      img.height = canvasSize
      img.width = canvasSize
      img.onload = function () {
         ctx.drawImage(img, 0, 0, canvasSize, canvasSize)
         const base64Full = ctx.canvas.toDataURL()

         const newFile = dataURLtoFile(base64Full, thumbName)
         onImageUpload(file, newFile, mimeType, imageUrl)
      }
   }

   return (
      <>
         <canvas
            ref={canvasRef}
            width={canvasSize}
            height={canvasSize}
            className={styles.hidden}
         />
         <div className={styles.uploadImageButton} onClick={onClick}>
            <input
               type="file"
               accept=".jpg, .png"
               ref={ref}
               onChange={onChange}
               disabled={isDisabled}
            />
            <Svg id="plus" width="40" height="40" />
         </div>
      </>
   )
}

export default memo(UploadImageButton)
