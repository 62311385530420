import React from 'react';
import classNames from 'classnames';

import { FieldWrapper } from '..';
import { BaseFormFieldProps} from '../form.base.type';

import * as styles from './select.module.scss';

type Option = {
    key: string | number;
    value: string | number;
}

interface Props extends BaseFormFieldProps {
    options: Option[];
    hideDefaultOption?: boolean;
}

const Select: React.FC<Props> = props => {
    const {
        description,
        disabled,
        error,
        hideDefaultOption = false,
        id,
        inputProps,
        isControlled = false,
        label,
        noMargin = false,
        onChange,
        options,
        required = false,
        withWrappers = true,
        value
    } = props;

    const renderElement = () => (
        
        <select
            {...(inputProps || {})}
            className={classNames(
                styles.select,
                { [styles.selectNolabel]: !label }
            )}
            onChange={onChange && onChange}
            {...(!isControlled && { defaultValue: value || '' })}
            {...(isControlled && { value: value || '' })}
            required={required}
            disabled={disabled}
            aria-invalid={!!error}
            id={id}
            name={id}
        >
            {!hideDefaultOption && <option value=''>- Choose -</option>}
            {options.map((option, i) =>
                <option key={option.key} value={option.key}>{option.value}</option>)}
        </select>
    );

    if (!withWrappers) {
        return renderElement();
    }

    return (
        <FieldWrapper
            description={description}
            disabled={disabled}
            error={error}
            hasFocus={true}
            id={id}
            label={label}
            required={required}
            noMargin={noMargin}
        >
            {renderElement()}
        </FieldWrapper>
    );
};

export default Select;