export enum Paths {
   LOGIN = '/login/',
   DASHBOARD = '/dashboard/',
   IMAGES = '/images/',
   SUBSCRIPTIONS = '/subscriptions/',
   USERS = '/users/',
   GLOBAL_PRICES = '/global-prices/',
   DISCOUNTS = '/discounts/',
   ITEMS_OLD = '/items-old/',
   ITEMS = '/items/',
   MASS_TRANSFER = '/items/mass-transfer/',
   ACTIONS = '/actions/',
   // ACTIONS_LOAN = '/actions/loan/',
   // ACTIONS_RETURN = '/actions/return/',
   // ACTIONS_FIELD_TRANSFER = '/actions/field-transfer/',
   // ACTIONS_WAREHOUSE_TRANSFER = '/actions/warehouse-transfer/',
   // ACTIONS_BORROW = '/actions/borrow/',
   CONNECTIONS = '/connections/',
   LOCATIONS = '/locations/',
   AUDITS = '/audits/',
   NOTES = '/notes/',
   HISTORY = '/history/item-history/',
   HISTORY_AUDIT = '/history/audit-history/',
   HISTORY_COSTING = '/history/costing-history/',
   SETTINGS = '/settings/system-settings/',
   SETTINGS_ITEMS = '/settings/item-record/',
   SETTINGS_MANUFACTURERS = '/settings/manufacturers/',
   SETTINGS_CATEGORIES = '/settings/categories/',
   SETTINGS_NOTES = '/settings/note-types/',
   SETTINGS_NOTIFICATIONS = '/settings/notifications/',
   MY_PROFILE = '/profile/details/',
   MY_PROFILE_RESET_PASSWORD = '/profile/reset-password/',
   MY_PROFILE_ACCOUNT_SETTINGS = '/profile/account-settings/',
   MY_PROFILE_SUBSCRIPTION = '/profile/subscription/',
   HELP = '/Help/',
   HELP_SEARCH = 'https://help.sharemytoolbox.com/knowledge',
   HELP_CONTACT = 'https://share.hsforms.com/1Rk5iaBX7TdyF-6cpXPtxdg4xbgj',
   HELP_ASSET_TAGS = 'https://www.etsy.com/shop/ShareMyToolbox',
   HOW_IT_WORKS = 'https://www.sharemytoolbox.com/tool-inventory-app/how-it-works/',
   LOGOUT = '/logout/',
   PASSWORD_RESET = '/password-reset/',
   PRIVACY_POLICY = 'https://www.sharemytoolbox.com/privacy/',
   FORGOTTEN_PASSWORD = '/forgotten-password/',
   LANDING = '/',
   NOTFOUND = '/404/',
   REGISTER = '/register/',
   REGISTER_TRIAL = '/register/trial/',
   REGISTER_TRIAL_VERIFY = '/register/trial/verify',
   REGISTER_TRIAL_CONFIRMATION = '/register/trial/confirmation/',
   REGISTER_VALIDATE = '/register/validate',
   REGISTER_UPGRADE = '/register/upgrade/',
   REGISTER_UPGRADE_CONFIRMATION = '/register/upgrade/confirmation/',
   REGISTER_EXISTING_USER = '/register/existing-account/',
   REGISTER_STARTER_CONFIRMATION = '/register/starter/confirmation/',
   ENTERPRISE_INFO = 'https://www.sharemytoolbox.com/enterprise-version-released/',
   SUBSCRIPTION_INFO = 'https://enterprise.sharemytoolbox.com/Billing',
}
