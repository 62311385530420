import React, { useEffect } from 'react'
import * as crudFormstyles from '../../common/crud/crudForm.module.scss'
import * as styles from './crudItems.module.scss'
import { Input, DateInput } from '../form/fields'
import { Toggle, ToggleInfo } from '../form/fields'
import { formatUsDate } from '../../../common/utils/functions'

import { parseDate } from '../../../common/utils/functions'

interface activeProps {
   Id: number | string
   Title: string
   ToolTypeDesc: string
   Quantity: number
   StatusDesc: string
   Description: string
   ModelNumber: number | string
   SerialNumber: number | string
   Barcode: number | string
   Category: number | string
   RentalReturnDate: string
   CurrentLocation: string
   DefaultLocation: string
   PurchasePrice: number | string
   WebSiteURL: string
   Vendor: string
   QuantityEnabled: boolean
   Manufacturer: string
   CustomRentalRate1: number
   CustomRentalRate2: number
   CustomRentalRate3: number
   CustomRentalRate4: number
}

interface Props {
   activeValue: activeProps
   updateForm: (string, unknown) => void
   itemForm: []
   QuantityEnabled?: boolean
   settings: any
   toggleValue: boolean
   handleToggle: () => void
   updateFormSelect: ({}) => void
   itemAction?: number
}

export const Rental: React.FC<Props> = ({
   activeValue,
   itemForm,
   updateForm,
   updateFormSelect,
   settings,
   toggleValue,
   handleToggle,
   RentalDate,
   RentalReturnDate,
}) => {
   const {
      Id,
      CustomRentalRate1Value,
      CustomRentalRate2Value,
      CustomRentalRate3Value,
      CustomRentalRate4Value,
   } = activeValue

   const {
      CustomRentalRate1Enabled,
      CustomRentalRate2Enabled,
      CustomRentalRate3Enabled,
      CustomRentalRate4Enabled,
   } = settings

   useEffect(() => {
      updateFormSelect({
         ...itemForm,
      })
   }, [])

   return (
      <>
         <div className={styles.itemColumns}>
            <div className={styles.columnOne}>
               <div className={crudFormstyles.crudItemWrapper}>
                  <div className={crudFormstyles.crudItemToggle}>
                     <Toggle
                        id="rentalToggle"
                        size="md"
                        isChecked={toggleValue}
                        //@ts-ignore
                        onToggle={handleToggle}
                     />
                  </div>
                  <div className={crudFormstyles.crudItemToggleInfo}>
                     <ToggleInfo name={'Rental Item'} value={toggleValue} />
                  </div>
               </div>
               <br />
               {toggleValue ? (
                  <>
                     <DateInput
                        id={`${Id}rateDate0`}
                        onChange={(e) => updateForm('RentalDate', e)}
                        selectedDate={parseDate(itemForm.RentalDate)}
                        label={'Rental Start Date'}
                     />
                  </>
               ) : (
                  <>
                     <Input
                        id={`${Id}rateDate00`}
                        label={'Rental Start Date'}
                        value={formatUsDate(itemForm.RentalDate)}
                        disabled
                     />
                  </>
               )}
               {toggleValue ? (
                  <>
                     <DateInput
                        id={`${Id}rateDate1`}
                        onChange={(e) => updateForm('RentalReturnDate', e)}
                        selectedDate={parseDate(itemForm.RentalReturnDate)}
                        label={'Rental Return Date'}
                     />
                  </>
               ) : (
                  <Input
                     id={`${Id}rateDate00`}
                     label={'Rental Return Date'}
                     value={formatUsDate(itemForm.RentalReturnDate)}
                     disabled={true}
                  />
               )}
               {CustomRentalRate1Enabled && (
                  <div className={styles.currency}>
                     <span />
                     <Input
                        id={`${Id}rate1`}
                        onChange={(e) =>
                           updateForm('CustomRentalRate1', e.target.value)
                        }
                        label={settings.CustomRentalRate1Label}
                        value={
                           itemForm.CustomRentalRate1Value ||
                           itemForm.CustomRentalRate1
                        }
                        disabled={!toggleValue}
                        inputProps={{
                           type: 'number',
                        }}
                     />
                  </div>
               )}
               {CustomRentalRate2Enabled && (
                  <div className={styles.currency}>
                     <span />
                     <Input
                        id={`${Id}rate2`}
                        onChange={(e) =>
                           updateForm('CustomRentalRate2', e.target.value)
                        }
                        label={settings.CustomRentalRate2Label}
                        value={
                           itemForm.CustomRentalRate2Value ||
                           itemForm.CustomRentalRate2
                        }
                        disabled={!toggleValue}
                        inputProps={{
                           type: 'number',
                        }}
                     />
                  </div>
               )}
               {CustomRentalRate3Enabled && (
                  <div className={styles.currency}>
                     <span />
                     <Input
                        id={`${Id}rate3`}
                        onChange={(e) =>
                           updateForm('CustomRentalRate3', e.target.value)
                        }
                        label={settings.CustomRentalRate3Label}
                        value={
                           itemForm.CustomRentalRate3Value ||
                           itemForm.CustomRentalRate3
                        }
                        disabled={!toggleValue}
                        inputProps={{
                           type: 'number',
                        }}
                     />
                  </div>
               )}
               {CustomRentalRate4Enabled && (
                  <div className={styles.currency}>
                     <span />
                     <Input
                        id={`${Id}rate4`}
                        onChange={(e) =>
                           updateForm('CustomRentalRate4', e.target.value)
                        }
                        label={settings.CustomRentalRate4Label}
                        value={
                           itemForm.CustomRentalRate4Value ||
                           itemForm.CustomRentalRate4
                        }
                        disabled={!toggleValue}
                        inputProps={{
                           type: 'number',
                        }}
                     />
                  </div>
               )}
            </div>
            <div className={styles.columnOne}></div>
         </div>
      </>
   )
}
