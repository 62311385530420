import React from 'react'
import { Row, Col } from 'react-grid-system'
import classNames from 'classnames'

import { Button, Svg } from '..'

import { FiltersProps } from './filters.type'
import * as styles from './filters.module.scss'

const Filters = ({ children, onReset, onSave }: FiltersProps) => (
   <>
      <div className={styles.titleWrapper}>
         <div className={styles.titleIcon}>
            <Svg
               id="filters"
               overrideViewBox="0,0,8,8"
               height="40"
               width="40"
            />
         </div>
         <h2 className={styles.title}>Filters</h2>
      </div>
      <div className={styles.wrapper}>
         <div className={styles.header}>
            <Row>
               <Col xs={6}>
                  <Button
                     className={styles.actionButton}
                     onClick={onSave}
                     minWidth="auto"
                  >
                     Apply
                  </Button>
               </Col>
               <Col xs={6}>
                  <Button
                     className={classNames(
                        styles.actionButton,
                        styles.actionButtonReset
                     )}
                     onClick={onReset}
                     minWidth="auto"
                     variant="plain"
                     preserveText
                  >
                     Reset
                  </Button>
               </Col>
            </Row>
         </div>

         <div>{children}</div>
      </div>
   </>
)

export default Filters
