import React from 'react'
import { PAGINATION } from '../../../constants/tables'
import * as styles from './pagination.module.scss'
import { PaginationSizeProps } from './pagination.types'

export const PaginationSize: React.FC<PaginationSizeProps> = (
    {
        pageSize,
        totalCount,
        handleSetPageSize,
        count,
        setCount
    }
) => {

  const allNumber = 999999;

    const handleCount = (num) => {
        setCount(count+1)
        handleSetPageSize(Number(num))
    }

    return (
        <div className={styles.pageSize}>
            <select
                value={pageSize}
                onChange={el => {
                    handleCount(el.target.value)
                }}
            >
                {[
                    PAGINATION.range1,
                    PAGINATION.range2,
                    PAGINATION.range3,
                    PAGINATION.range4,
                    allNumber
                ].map((pageSize, index) => (
                    <option key={index} value={pageSize}>
                        {index === PAGINATION.index ? PAGINATION.all : pageSize}
                    </option>
                ))}
            </select>
            { PAGINATION.rangeLabel }
        </div>
    )
}


