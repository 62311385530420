import { Paths } from '../../constants/structure'

const getRedirectAfterLoginURL = (redirectTo?: string) => {
   if (!redirectTo) {
      return Paths.LOGIN
   }

   return `${Paths.LOGIN}?returnUrl=${
      redirectTo === '/' ? Paths.DASHBOARD : redirectTo
   }`
}

const removeTrailingSlash = (path?: string) => {
   return path.replace(/\/$/, '')
}

export { getRedirectAfterLoginURL, removeTrailingSlash }
