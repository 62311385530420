import { useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { UseConnections, Connection } from './useConnections.type'
import { useState } from 'react'

export default (): UseConnections => {
   const request = useAPI()
   const [isImportSuccess, setIsImportSuccess] = useState<boolean>(false)
   const [importError, setImportError] = useState<string | null>(null)
   const [importFileErrors, setImportFileErrors] = useState<string[]>([])
   const [totalImported, setTotalImported] = useState<number>(0)

   const { data, isLoading } = useQuery('connections', getConnectionsFromAPI)

   async function getConnectionsFromAPI(): Promise<
      AxiosResponse<Connection[]>
   > {
      return await request.get(PATH.CONNECTIONS_FILTER.LIST)
   }

   const { isLoading: isImporting, mutate } = useMutation(
      importConnectionsFile,
      {
         onSuccess: (data) => {
            const { IsValid, SuccessCount } = data?.data
            if (IsValid) {
               setIsImportSuccess(true)
               setTotalImported(SuccessCount)
            } else {
               setIsImportSuccess(false)
               const msgs = data?.data?.Result ?? [
                  'Unknown error with the uploaded connections file.',
               ]
               setImportFileErrors(msgs)
               setImportError(
                  'There were errors with the uploaded connections file.'
               )
            }
         },
         onError: (error: any) => {
            setIsImportSuccess(false)
            const msg =
               error?.response?.data?.Message ??
               'Unknown error importing connections.'
            setImportError(msg)
         },
      }
   )

   const resetImportState = () => {
      setIsImportSuccess(false)
      setImportError(null)
      setImportFileErrors([])
   }

   async function importConnectionsFile(
      file: File
   ): Promise<AxiosResponse<any>> {
      const formData = new FormData()
      const requestOptions = {
         headers: {
            'Content-Type': 'multipart/form-data',
         },
      }
      formData.append('file', file)
      return await request.post(
         PATH.CONNECTIONS.IMPORT,
         formData,
         requestOptions
      )
   }

   const importConnections = async (file: File) => {
      resetImportState()
      await mutate(file)
   }

   return {
      isLoading,
      list: data?.data || ([] as Connection[]),
      importConnections,
      isImporting,
      isImportSuccess,
      isImportError: importError !== null || importFileErrors?.length > 0,
      importError,
      importFileErrors,
      totalImported,
   }
}
