// extracted by mini-css-extract-plugin
export var attachmentBtnContent = "notes-module--attachmentBtnContent--OGdtR";
export var attachmentHeader = "notes-module--attachmentHeader--HZ7Ff";
export var attachmentInner = "notes-module--attachmentInner--St1nh";
export var attachmentLoadingSpinner = "notes-module--attachmentLoadingSpinner--8PDCd";
export var attachmentWrapper = "notes-module--attachmentWrapper--F2kOl";
export var completedInputWrapper = "notes-module--completedInputWrapper--ab0+P";
export var completedInputWrapperMobile = "notes-module--completedInputWrapper-mobile--FN+P4";
export var coordinatesButton = "notes-module--coordinates-button--nLJfk";
export var currency = "notes-module--currency--bIg0D";
export var deleteForm = "notes-module--delete-form--Jqz+Y";
export var deleteIcon = "notes-module--deleteIcon--AvmwW";
export var description = "notes-module--description--kSPxP";
export var divider = "notes-module--divider--NReZ1";
export var fileText = "notes-module--fileText--sgU82";
export var form = "notes-module--form--9xxfq";
export var icon = "notes-module--icon--13uuf";
export var importDownloadExample = "notes-module--import-download-example--mho-x";
export var importForm = "notes-module--import-form--VK4QA";
export var infoText = "notes-module--infoText--p0RNV";
export var locationsWrapper = "notes-module--locationsWrapper--PrBFD";
export var noteInfoLeft = "notes-module--noteInfoLeft--TkwzD";
export var noteInfoRight = "notes-module--noteInfoRight--Kh5Lk";
export var semiBold = "notes-module--semiBold--Oi-SR";
export var toggle = "notes-module--toggle--RDnRu";
export var toggleColumn = "notes-module--toggleColumn---UVuB";
export var toggleField = "notes-module--toggle-field--ycNPg";
export var toggleLabel = "notes-module--toggle-label--qSXBI";
export var veryBold = "notes-module--veryBold--MrnAl";
export var warehouseDescription = "notes-module--warehouse-description--+TBtk";