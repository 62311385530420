import React from 'react'

import { Props } from './locationLabel.type'
import * as styles from './locationLabel.module.scss'
import Svg from '../svg/svg'

export const LocationLabel: React.FC<Props> = ({ label }) => {
   return (
      <span className={styles.wrapper}>
         <span className={styles.icon}>
            <Svg id="location" width="12" height="24" />
         </span>
         <span>{label}</span>
      </span>
   )
}

export default LocationLabel
