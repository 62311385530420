import React from 'react'
import CountUp from 'react-countup'
import classNames from 'classnames'

import { LoadingOverlay, Svg } from '../../common'

import { Props } from './statTile.type'
import * as styles from './statTile.module.scss'

const StatTile: React.FC<Props> = (props) => {
   const {
      color,
      icon,
      isDisabled = false,
      isLoading,
      label,
      num,
      onClick,
   } = props

   return (
      <button
         className={classNames(styles.wrapper, {
            [styles.wrapperDisabled]: isDisabled,
         })}
         onClick={onClick}
         style={{ color, fill: color }}
         disabled={isDisabled}
      >
         <span className={styles.icon}>
            <Svg id={icon} height={40} width={40} />
         </span>
         <span className={styles.number}>
            <CountUp
               start={0}
               end={isLoading ? 0 : num}
               duration={3}
               useEasing
            />
         </span>
         <span className={styles.label} style={{ backgroundColor: color }}>
            {label}
         </span>

         {isLoading && <LoadingOverlay positionAbsolute small />}
      </button>
   )
}

export default StatTile
