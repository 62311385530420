import { useMutation } from 'react-query'

import { useAPI } from '..'
import { PATH } from '../../constants/global'

import {
   UseAccountValidate,
   ValidateAPIResponse,
} from './useAccountValidate.type'
import { getFlowType } from './useAccountValidate.helpers'

export default function (): UseAccountValidate {
   const request = useAPI()
   const { data, error, isLoading, mutate, isSuccess } =
      useMutation(validateUserAPI)

   async function validateUserAPI(email: string) {
      return request.post<ValidateAPIResponse>(PATH.REGISTER.CHECK_EMAIL, {
         email,
      })
   }

   const validate = (email: string): void => {
      mutate(email)
   }

   const flowType = !!data?.data?.Data
      ? getFlowType(
           data?.data?.Data?.IsEmailRegistered,
           data?.data?.Data?.FreeUser
        )
      : null

   return {
      // @ts-ignore
      error: error?.response.data?.Message,
      data: data?.data?.Data,
      flowType,
      isLoading,
      isSuccess,
      validate,
   }
}
