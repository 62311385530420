import { useQuery } from 'react-query'

import { useAPI } from '..'
import { PATH } from '../../constants/global'
import { AxiosResponse } from 'axios'
import { UseItemGetItemsForKit } from './useItemKit.type'
import { useEffect, useState } from 'react'

export default function (): UseItemGetItemsForKit {
   const request = useAPI()

   const [pageOptions, setPageOptions] = useState(null)
   const [addItemsToKitOptions, setAddItemsToKitOptions] = useState(null)

   // get items for kit add to kit
   const {
      data: itemsForAddToKitList,
      isLoading: isLoadingItemsForAddToKit,
      refetch: refreshItemsForAddToKit,
      isRefetching: isRefetchingItemsForAddToKIt,
   } = useQuery<Promise<AxiosResponse>>(
      'get-items-for-add-to-kit',
      () => getItemsForAddToKit(addItemsToKitOptions),
      { enabled: !!addItemsToKitOptions }
   )

   async function getItemsForAddToKit(
      addItemsToKitOptions
   ): Promise<AxiosResponse> {
      return await request.post(
         PATH.ITEMS.GET_ITEMS_FOR_ADD_TO_KIT,
         addItemsToKitOptions
      )
   }

   const getAddToKitItems = (options) => {
      setAddItemsToKitOptions(options)
   }

   // get items for kit manage items already in kit
   const {
      data: itemsForKitList,
      isLoading: isLoadingItems,
      refetch: refreshItemsForKitList,
      isRefetching: isRefetchingItemsForKit,
   } = useQuery<Promise<AxiosResponse>>(
      'get-items-for-kit',
      () => getItemsForKit(pageOptions),
      { enabled: !!pageOptions }
   )

   async function getItemsForKit(options): Promise<AxiosResponse> {
      return await request.post(PATH.ITEMS.GET_ITEMS_FOR_KIT, options)
   }

   const paginateKitItems = (options) => {
      setPageOptions(options)
   }

   useEffect(() => {
      if (pageOptions?.kitID) {
         refreshItemsForKitList()
      }
   }, [pageOptions])

   return {
      // @ts-ignore
      itemsForKitList: itemsForKitList?.data,
      isLoadingItems,
      refreshItemsForKitList,
      paginateKitItems,
      isRefetchingItemsForKit,
      // @ts-ignore
      itemsForAddToKitList: itemsForAddToKitList?.data,
      refreshItemsForAddToKit,
      getAddToKitItems,
      isRefetchingItemsForAddToKIt,
      isLoadingItemsForAddToKit,
   }
}
