import { useState } from 'react'
import { AxiosResponse } from 'axios'
import { PATH } from '../../constants/global'
import { useAPI } from '..'
import { useBarcodeLookup } from './useBarcodeLookup.type'

export default function (): useBarcodeLookup {
   const [ErrorMessage, setError] = useState<string>('')
   const request = useAPI()
   async function getBarcodeLookupAPI(
      barcode: any,
      excludeItemId: any
   ): Promise<AxiosResponse> {
      try {
         return await request.get(`${PATH.ITEMS.BARCODE_LOOKUP}?barcode=${barcode}&excludeItemId=${excludeItemId}`)
      } catch (error) {
         console.warn(error)
      }
   }

   const onSubmitBarcodelookup = async (barcode, excludeItemId) => {
      if (barcode) {
         return await getBarcodeLookupAPI(barcode, excludeItemId).then((res) => {
            setError(res?.data?.ErrorMessage)
            return {
               response: res?.data?.IsValid === false && res?.data?.ErrorMessage ? true : false,
               ErrorMessage: res?.data?.ErrorMessage,
            }
         })
      }
      return { response: false}
   }

   return {
      ErrorMessage,
      onSubmitBarcodelookup,
   }
}
