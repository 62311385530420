import React, {
   useEffect,
   useState,
   MouseEvent,
   memo,
   useRef,
   useMemo,
} from 'react'
import classnames from 'classnames'
import {
   AuditNoteDetails,
   CheckoutAction,
   GetItemOptions,
   ItemAuditedListProps,
   ViewName,
} from './itemAuditedList.type'
import * as styles from './itemAuditList.module.scss'
import { Item, Severity, ToolStatus, ToolTypes } from '../../../types'
import {
   Alert,
   Button,
   CrudItems,
   IconButton,
   Image,
   LoadingOverlay,
   Modal,
   SnackBar,
} from '..'
import { formatPrice, PATH } from '../../../constants/global'
import {
   useSettings,
   useItemUpload,
   useItemNotes,
   useItemHistory,
   useItemsView,
   useItems,
   useProfile,
   useItemGetKits,
   useAudit,
   useAPI,
} from '../../../hooks'
import { TableWrapper } from '../table/TableWrapper'
import * as auditsStyles from '../../views/audits/audits.module.scss'
import { Col, Row } from 'react-grid-system'
import { AuditItem } from '../../../hooks/useAudit/useAudit.type'
import { AuditStatus } from '../../views/audits/enums'
import { CrudItemsParentView } from '../../../enums/CrudItemsEnums'
import { useMediaQuery } from 'react-responsive'
import { Actions } from '../../../constants/tables'
import { WarningModal } from '../warningModal/warningModal'
import { CRUD_TABLE } from '../../views/items/constants'
import { ManageKitModal } from '../../views/items/modals/manageKitModal'
import { AddToKitModal, DeleteKit } from '../crudItems'
import { convertNoteTypesToOptions } from '../filters/helpers'
import { SnackBarState } from '../snackBar/types'
import { closedSnackbarState } from '../snackBar/snackBar'
import { ACTION_CONSTANTS } from '../../views/actions/constants'
import { CheckoutModal } from '../../actionsWorkflow/checkoutModal'
import { ItemQuantityModal } from '../../views/items/modals/itemQuantityModal'
import { ItemButton } from '../modal/types'
import { View } from '../../actionsWorkflow/actionsWorkflow.type'
import AuditNoteModal from './auditNoteModal'

const ItemAuditedList = ({
   confirmed,
   unconfirmed,
   found,
   unconfirmedTotalCount,
   confirmedTotalCount,
   foundTotalCount,
   onRemoveFromAudit,
   selectedAuditFormData,
   itemTypeOptions,
   categoryOptions,
   manufacturerOptions,
   locationOptions,
   conditionOptions,
   auditIsLoading,
   handleAddAuditItem,
   setItemsWereAddedOrRemoved,
}: ItemAuditedListProps) => {
   const { settings } = useSettings()
   const [loading, setLoading] = useState<boolean>(false)
   const [selectedView, setSelectedView] = useState<ViewName>(
      ViewName.UNCONFIRMED
   )
   const [itemToRemove, setItemToRemove] = useState<Item | null>(null)
   const [unconfirmedTake, setUnconfirmedTake] = useState<number>(10)
   const [unconfirmedSkip, setUnconfirmedSkip] = useState<number>(0)
   const [confirmedTake, setConfirmedTake] = useState<number>(10)
   const [confirmedSkip, setConfirmedSkip] = useState<number>(0)
   const [foundTake, setFoundTake] = useState<number>(10)
   const [foundSkip, setFoundSkip] = useState<number>(0)
   const [pagedTableData, setPagedTableData] = useState<AuditItem[] | Item[]>(
      []
   )
   const [selectedItemUniqueId, setSelectedItemUniqueId] = useState<
      number | null
   >(null)
   const [selectedAuditItemId, setSelectedAuditItemId] = useState<
      number | null
   >(null)
   const [auditNoteModalOpen, setAuditNoteModalOpen] = useState<boolean>(false)
   const [auditNoteDetails, setAuditNoteDetails] =
      useState<AuditNoteDetails | null>(null)

   const { QuantityEnabled, KitEnabled, EnableLocationTracking } = settings

   const { getUnconfirmedItems, getConfirmedItems, getFoundItems } = useAudit()

   useEffect(() => {
      if (!auditIsLoading) {
         switch (selectedView) {
            case ViewName.UNCONFIRMED:
               const unconfirmedItems = unconfirmed ?? []
               setPagedTableData(unconfirmedItems)
               break
            case ViewName.CONFIRMED:
               const confirmedItems = confirmed ?? []
               setPagedTableData(confirmedItems)
               break
            case ViewName.FOUND:
               const foundItems = found ?? []
               setPagedTableData(foundItems)
               break
         }
      }
   }, [selectedView, auditIsLoading, unconfirmed, confirmed, found])

   const handleTabClick = (
      viewName: ViewName,
      event: MouseEvent<HTMLButtonElement>
   ) => {
      event.preventDefault()
      setSelectedView(viewName)
   }

   const removeItemFromAudit = (item: Item) => onRemoveFromAudit(item)

   const prepareItemForRemoval = (rowItem: Item) => setItemToRemove(rowItem)

   const handleConfirmRemoveItem = () => {
      if (!itemToRemove) return
      handleSnackbar('Item has been removed from the audit.', Severity.SUCCESS)
      onRemoveFromAudit(itemToRemove) // existing saved audit send auditId to be deleted
      setItemToRemove(null)
      setItemsWereAddedOrRemoved(true)
      setIsItemModalVisible(false)
   }

   const handleCancelRemoveItem = () => {
      setItemToRemove(null)
      setIsItemModalVisible(false)
   }

   const handleGetItems = async (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      setLoading(true)
      if (!selectedAuditFormData.AuditID) {
         const pagedData = unconfirmed.slice(skip, take + skip)
         setPagedTableData(pagedData)
      } else if (
         selectedAuditFormData.AuditID &&
         selectedView === ViewName.UNCONFIRMED &&
         selectedAuditFormData.Status !== AuditStatus.DRAFT
      ) {
         setUnconfirmedSkip(skip)
         setUnconfirmedTake(take)
         const items = await getUnconfirmedItems({
            auditID: Number(selectedAuditFormData.AuditID),
            skip: skip,
            take: take,
         })
         setPagedTableData(items)
      } else if (
         selectedAuditFormData.AuditID &&
         selectedView === ViewName.UNCONFIRMED &&
         selectedAuditFormData.Status === AuditStatus.DRAFT
      ) {
         const pagedData = unconfirmed.slice(skip, take + skip)
         setPagedTableData(pagedData)
      } else if (
         selectedAuditFormData.AuditID &&
         selectedView === ViewName.CONFIRMED
      ) {
         setConfirmedSkip(skip)
         setConfirmedTake(take)
         const items = await getConfirmedItems({
            auditID: Number(selectedAuditFormData.AuditID),
            skip: skip,
            take: take,
         })
         setPagedTableData(items)
      } else if (
         selectedAuditFormData.AuditID &&
         selectedView === ViewName.FOUND
      ) {
         setFoundSkip(skip)
         setFoundTake(take)
         const items = await getFoundItems({
            auditID: Number(selectedAuditFormData.AuditID),
            skip: skip,
            take: take,
         })
         setPagedTableData(items)
      }
      setLoading(false)
   }

   const handleViewNoteClick = (rowItem: Item) => {
      setAuditNoteDetails({
         ConfirmationStatus: rowItem?.Confirmation,
         Condition: rowItem?.ConditionDesc,
         AuditNote: rowItem?.AuditNote,
      })
      setAuditNoteModalOpen(true)
   }

   const handleAuditNoteModalClose = () => {
      setAuditNoteModalOpen(false)
      setAuditNoteDetails(null)
   }

   const columns = useMemo(
      () => [
         ...(selectedView === ViewName.CONFIRMED ||
         selectedAuditFormData?.Status === AuditStatus.DRAFT
            ? [
                 {
                    Header: '',
                    accessor: 'Id',
                    minWidth: 50,
                    maxWidth: 50,
                    align: 'center',
                    Cell: ({ row }) => {
                       const rowItem = row?.original as Item
                       return (
                          <>
                             {selectedView === ViewName.CONFIRMED &&
                                rowItem?.AuditNote && (
                                   <IconButton
                                      id="notes"
                                      tooltip="View Note"
                                      action={() =>
                                         handleViewNoteClick(rowItem)
                                      }
                                   />
                                )}
                             {selectedAuditFormData?.Status ===
                                AuditStatus.DRAFT && (
                                <IconButton
                                   id="delete"
                                   tooltip="Delete Item"
                                   action={() => prepareItemForRemoval(rowItem)}
                                />
                             )}
                          </>
                       )
                    },
                 },
              ]
            : []),
         {
            Header: 'Pic',
            accessor: 'PictureURL',
            id: 'PictureURL',
            allowBlank: true,
            maxWidth: 45,
            minWidth: 45,
            align: 'center',
            Cell: (cell: { row: { original: { PictureURL: string } } }) => (
               <Image
                  url={cell.row.original.PictureURL}
                  alt={cell.row.original.PictureURL}
                  defaultImagePadding={3}
                  width={25}
               />
            ),
         },
         {
            Header: 'Item Title',
            accessor: 'Title',
            id: 'Title',
            minWidth: 250,
         },
         ...(selectedView === ViewName.CONFIRMED
            ? [
                 {
                    Header: 'Confirmation',
                    accessor: 'Confirmation',
                    id: 'Confirmation',
                    minWidth: 150,
                 },
                 {
                    Header: 'Condition',
                    accessor: 'ConditionDesc',
                    id: 'ConditionDesc',
                    minWidth: 150,
                 },
                 {
                    Header: 'Audit Note',
                    accessor: 'AuditNote',
                    id: 'AuditNote',
                    Cell: (cell: {
                       row: { original: { AuditNote: string } }
                    }) => {
                       const { AuditNote } = cell.row.original
                       return (
                          <div title={AuditNote ?? ''}>{AuditNote ?? '-'}</div>
                       )
                    },
                 },
              ]
            : []),
         {
            Header: 'Status',
            accessor: 'StatusDesc',
            id: 'StatusDesc',
            minWidth: 150,
         },
         {
            Header: 'Loaned/Pending To',
            accessor: (row) => {
               return row.AssignedToUser ?? ''
            },
            id: 'AssignedToUser',
            minWidth: 250,
         },
         ...(!!EnableLocationTracking
            ? [
                 {
                    Header: 'Current/Pending Location',
                    accessor: 'CurrentLocation',
                    id: 'CurrentLocation',
                    minWidth: 250,
                 },
              ]
            : []),
         ...(!!KitEnabled
            ? [
                 {
                    Header: 'Assigned to Kit',
                    accessor: 'AssignedToKitName',
                    id: 'AssignedToKitName',
                    minWidth: 250,
                 },
              ]
            : []),
         ...(!!QuantityEnabled
            ? [
                 {
                    Header: 'Total Quantity',
                    id: 'Quantity',
                    accessor: 'Quantity',
                    minWidth: 250,
                 },
                 {
                    Header: 'Audit Quantity',
                    accessor: 'AuditQuantity',
                    id: 'AuditQuantity',
                    minWidth: 250,
                 },
                 {
                    Header: 'Status Quantity',
                    accessor: 'StatusQuantity',
                    id: 'StatusQuantity',
                    minWidth: 250,
                 },
              ]
            : []),
         {
            Header: 'Manufacturer',
            accessor: 'Manufacturer',
            id: 'Manufacturer',
            minWidth: 250,
         },
         {
            Header: 'Model No.',
            accessor: 'ModelNumber',
            id: 'ModelNumber',
            minWidth: 250,
         },
         {
            Header: 'Serial No.',
            accessor: 'SerialNumber',
            id: 'SerialNumber',
            minWidth: 250,
         },
         {
            Header: 'Barcode',
            accessor: 'Barcode',
            id: 'Barcode',
         },
         {
            Header: 'Category',
            accessor: 'Category',
            id: 'Category',
         },
         {
            Header: 'Type',
            accessor: 'ToolType',
            id: 'ToolType',
            minWidth: 150,
            Cell: ({ row }) => ToolTypes[row.original.ToolType],
         },
         ...(!!EnableLocationTracking
            ? [
                 {
                    Header: 'Return Warehouse',
                    accessor: 'DefaultLocation',
                    id: 'DefaultLocation',
                    minWidth: 250,
                 },
              ]
            : []),
         {
            Header: 'Purchase Price',
            accessor: (row) => formatPrice(row.PurchasePrice || 0),
            id: 'PurchasePrice',
            minWidth: 250,
         },
      ],
      [selectedAuditFormData, selectedView]
   )

   const noItemsText = 'No items to show'

   // Items modal functionality
   const { profile } = useProfile()
   const [isItemModalVisible, setIsItemModalVisible] = useState<boolean>(false)
   const [activeValue, setActiveValue] = useState({})
   const [itemForm, setItemForm] = useState<any>({})
   const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })
   const [itemImages, setItemImages] = useState<any>([])
   const [itemStatus, setItemStatus] = useState<number>(0)
   const [itemId, setItemId] = useState<number | null>(null)
   const [itemAction, setItemAction] = useState<Actions>(Actions.Readonly)
   const [isCopyPrompt, setIsCopyPrompt] = useState<boolean>(false)
   const [isDeletePrompt, setIsDeletePrompt] = useState<boolean>(false)
   const [isNewItemNote, setIsNewItemNote] = useState<boolean>(false)
   const [isManageKitModalOpen, setIsManageKitModalOpen] =
      useState<boolean>(false)
   const [isAddToKitModal, setIsAddToKitModal] = useState<boolean>(false)
   const [isRemoveFromKitModal, setIsRemoveFromKitModal] =
      useState<boolean>(false)
   const [addToKitQty, setAddToKitQty] = useState<number>(1)
   const [itemKitOption, setItemKitOptions] = useState([])
   const [isItemLimitPrompt, setIsItemLimitPrompt] = useState<boolean>(false)
   const [snackbar, setSnackbar] = useState<SnackBarState>(closedSnackbarState)
   const [selectedItemsForWorkflow, setSelectedItemsForWorkflow] = useState<
      Item[]
   >([])
   const [checkoutOptions, _setCheckoutOptions] = useState<CheckoutAction[]>(
      // employees cant access audits page
      ACTION_CONSTANTS.companyCheckoutOptions
   )
   const [checkoutAction, setCheckoutAction] = useState<CheckoutAction>(
      checkoutOptions[0]
   )

   const [isCheckoutModalOpen, setIsCheckoutModalOpen] =
      useState<boolean>(false)
   const [isQuantityModalOpen, setIsQuantityModalOpen] =
      useState<boolean>(false)
   const [workflowItem, setWorkflowItem] = useState<any>(null)

   const resetRef = useRef<HTMLInputElement | null>(null)

   const allItemButtons: ItemButton[] = [
      {
         name: 'actionLoan',
         title: 'Loan',
         action: () => handleWorkflowAction('Loan'),
         type: 'Loan',
      },
      {
         name: 'actionTransfer',
         title: 'Transfer',
         action: () => handleWorkflowAction('Transfer'),
         type: 'TransferTo',
      },
      {
         name: 'actionReturn',
         title: 'Return',
         action: () => handleWorkflowAction('Return'),
         type: 'CheckIn',
      },
      {
         name: 'copy',
         title: 'Copy',
         action: () =>
            profile.AllowAddTool
               ? handleCopyPrompt()
               : setIsItemLimitPrompt(true),
         overrideViewBox: '-.75,.55,5,7',
         type: '',
      },
      {
         name: 'remove',
         title: 'Delete',
         action: () => handleDeletePrompt(),
         overrideViewBox: '.75,.75,3,5',
         type: 'DeleteTool',
      },
      {
         name: 'add-note',
         title: 'Note',
         action: () => handleAddNote(),
         type: 'AddNote',
      },
      {
         name: 'manage-kit',
         title: 'Manage Kit',
         action: () => manageKit(),
         type: 'AddtokitOpenItems',
      },
      {
         name: 'add-to-kit',
         title: 'Add to Kit',
         action: () => addToKit(),
         type: 'AddtokitOpenKit',
      },
      {
         name: 'remove-from-kit',
         title: 'Remove from Kit',
         action: () => removeFromKit(),
         type: 'RemoveFromKit',
      },
   ]

   const {
      upload,
      changePosition,
      deleteImage,
      isPositionLoading,
      isPositionSuccess,
   } = useItemUpload()
   const { data: itemNotesData, itemNotesList, refetch } = useItemNotes()
   const { data: itemHistoryData, getItemHistoryData } = useItemHistory()

   const {
      data: itemResponse,
      getItemView,
      isLoading: isItemLoading,
      isRefetching: isItemRefetching,
      isSuccess: isItemSuccess,
   } = useItemsView()

   const {
      setPath,
      error,
      remove,
      add,
      isRemoving,
      isSuccessRemove,
      isErrorRemove,
      isAdding,
      isSuccessAdd,
      isErrorAdd,
   } = useItems()

   const {
      result: getItemKitData,
      addKit,
      getKits,
      deleteKit,
      isSuccessKits,
      isErrorCreate: isErrorAddToKit,
      isSuccessCreate: isSuccessAddToKit,
      isErrorDeleteKit,
      isSuccessDeleteKit,
      isLoadingKits,
      isCreating: isAddingToKit,
      isDeletingKit,
   } = useItemGetKits()

   useEffect(() => {
      setPath(CRUD_TABLE.crudPaths, CRUD_TABLE.key)
   }, [])

   useEffect(() => {
      // if itemform isnt empty then set selected item
      if (Object.keys(itemForm).length !== 0) {
         setSelectedItemsForWorkflow([itemForm])
      }
   }, [itemForm])

   useEffect(() => {
      //handles setting the item modal form
      if (itemResponse?.data?.Data && isItemSuccess) {
         let data = itemResponse?.data?.Data
         showActionForm(
            itemAction ? itemAction : Actions.Edit,
            data?.Id,
            data?.Title,
            data?.ManufacturerId,
            data?.Category,
            data
         )
      }
   }, [itemResponse, isItemSuccess])

   useEffect(() => {
      if (getItemKitData.data) {
         setItemKitOptions(convertNoteTypesToOptions(getItemKitData.data))
      }
   }, [isSuccessKits, getItemKitData])

   useEffect(() => {
      if (itemToRemove && isItemModalVisible) {
         setIsItemModalVisible(false)
      }
   }, [itemToRemove, isItemModalVisible])

   useEffect(() => {
      handleSnackbar('Item has been deleted.', Severity.SUCCESS)
   }, [isSuccessRemove])

   useEffect(() => {
      handleSnackbar(error, Severity.ERROR)
   }, [isErrorRemove])

   useEffect(() => {
      const copiedItemName = `${itemForm.Title} (Copy)`
      handleSnackbar(
         `Item has been copied with the name "${copiedItemName}"`,
         Severity.SUCCESS
      )
   }, [isSuccessAdd])

   useEffect(() => {
      handleSnackbar(error, Severity.ERROR)
   }, [isErrorAdd])

   useEffect(() => {
      handleSnackbar('Item has been added to the kit.', Severity.SUCCESS)
   }, [isSuccessAddToKit])

   useEffect(() => {
      handleSnackbar(error, Severity.ERROR)
   }, [isErrorAddToKit])

   useEffect(() => {
      handleSnackbar('Item has been removed from the kit.', Severity.SUCCESS)
   }, [isSuccessDeleteKit])

   useEffect(() => {
      handleSnackbar(error, Severity.ERROR)
   }, [isErrorDeleteKit])

   const handleSnackbar = (message: string, severity: Severity) => {
      setSnackbar((prevState) => ({ ...prevState, message, severity }))
   }

   const handleDeletePrompt = () => {
      setIsDeletePrompt(true)
      handleItemModalClose()
   }

   const handleDelete = (id: number) => {
      setItemId(id)
      removeItemFromAudit(itemForm)
      handleActionForm(Actions.Delete)
      setIsDeletePrompt(false)
   }

   const deleteItem = () => remove({ id: itemId })

   const handleCopyPrompt = () => {
      setIsCopyPrompt(true)
      handleItemModalClose()
   }

   const handleCopy = () => {
      handleActionForm(Actions.Copy)
      setIsCopyPrompt(false)
   }

   const copyItem = () => {
      const newItem = {
         ...itemForm,
         Title: `${itemForm.Title} (Copy)`,
         ManufacturerId: itemForm.ManufacturerId,
         copyId: itemForm.Id,
         SerialNumber: '',
         Barcode: '',
         Id: null,
         ToolLoanId: 0,
         localUniqueId: null,
         LocationTrackingLabel: null,
         CustomRentalRate1: itemForm.CustomRentalRate1Value,
         CustomRentalRate2: itemForm.CustomRentalRate2Value,
         CustomRentalRate3: itemForm.CustomRentalRate3Value,
         CustomRentalRate4: itemForm.CustomRentalRate4Value,
         itemImages: itemImages,
      }
      add(newItem)
      setItemAction(Actions.Readonly)
   }

   const handleAddNote = () => {
      setItemStatus(4)
      setIsNewItemNote(true)
   }

   const manageKit = () => {
      setIsManageKitModalOpen(true)
      setIsItemModalVisible(false)
      refreshItemInList(itemForm)
   }

   const addToKit = () => {
      handleItemModalClose()
      setIsAddToKitModal(true)

      getKits({
         defaultLocationID: itemForm.DefaultLocationID,
         status: itemForm.Status,
         assignedToUserID: itemForm.AssignedToUserID,
         requestedLocationId: itemForm.RequestedLocationId,
         locationID: itemForm.LocationID,
         skip: null,
         take: null,
         query: null,
      })
   }

   const handleAddToKit = (obj: any) => {
      addKit(obj)
      setIsAddToKitModal(false)
      const refreshItem = {
         ...itemForm,
         KitId: obj?.KitID,
      }
      refreshItemInList(refreshItem)
   }

   const removeFromKit = () => {
      handleItemModalClose()
      setIsRemoveFromKitModal(true)
   }

   const handleRemoveFromKit = (obj: any) => {
      deleteKit(obj)
      setIsRemoveFromKitModal(false)
      const refreshItem = {
         ...itemForm,
         KitId: obj?.KitID,
      }
      refreshItemInList(refreshItem)
   }

   const postItem = (item: {}, localUniqueId) => {
      const formData = new FormData()
      formData.append('toolId', localUniqueId)
      formData.append('position', item.Position)
      formData.append('file[0]', item['File[0]'])
      formData.append('file[1]', item['File[1]'])
      upload(formData)
   }

   const handleItemModalClose = () => {
      setIsItemModalVisible(false)
      setIsNewItemNote(false)
      setActiveValue({})
      setIsAddToKitModal(false)
      setIsRemoveFromKitModal(false)
      setItemAction(Actions.Readonly)
   }

   const getItem = (
      Id: number,
      ToolLoanId: number | null = null,
      KitId: number | null,
      e,
      _isCart,
      localUniqueId: number | null,
      AuditItemID: number | null
   ) => {
      if (e.target.className === 'Id' || e.target.closest('.Id')) {
         return // don't open item card if clicking on cta
      }
      getItemView({ Id, ToolLoanId, KitId })
      setItemId(Id)
      setSelectedItemUniqueId(localUniqueId)
      setSelectedAuditItemId(AuditItemID)
   }

   const showActionForm = (
      action: number | ((prevState: number) => number),
      id?: number,
      title?: string,
      manufacturerId?: number,
      category?: string,
      itemListData = []
   ) => {
      const obj = itemListData
      setItemId(id)
      setItemAction(action)

      setActiveValue(obj)
      setItemForm(obj)
      setItemImages([])

      itemNotesList(String(id))
      getItemHistoryData(String(id))
      setIsItemModalVisible(true)
   }

   const handleActionForm = (action: number, id?: number, obj?: Item) => {
      switch (action) {
         case Actions.Copy:
            copyItem()
            break
         case Actions.Delete:
            deleteItem()
            break
         default:
            break
      }
   }

   const refetchNoteTableData = () => refetch()

   // Workflow actions
   const isQuantityItemsSelected = (array: Item[]) => {
      return array.some(
         (x) =>
            (x.ToolType == ToolTypes['Consumable'] ||
               x.ToolType == ToolTypes['Quantity Item']) &&
            x.StatusQuantity > 1
      )
   }

   const handleIncrementDecrementQuantity = (item: Item, val: string) => {
      const itemToAdjust = selectedItemsForWorkflow.find(
         (y) => y.localUniqueId === item.localUniqueId
      )
      if (val === 'dec' && itemToAdjust.quantityForCart > 1) {
         itemToAdjust.quantityForCart = itemToAdjust.quantityForCart - 1
      } else if (
         val === 'inc' &&
         itemToAdjust.quantityForCart < itemToAdjust.StatusQuantity
      ) {
         itemToAdjust.quantityForCart = itemToAdjust.quantityForCart + 1
      } else {
         return
      }
      setSelectedItemsForWorkflow([...selectedItemsForWorkflow])
   }

   const handleCustomTotalChange = (e: any, item: Item) => {
      e.target.value = parseInt(e.target.value)
      const itemToAdjust = selectedItemsForWorkflow.find(
         (y) => y.localUniqueId === item.localUniqueId
      )
      const adjustedValue =
         e.target.value === '' ? e.target.value : Number(e.target.value)
      // @ts-ignore
      itemToAdjust.quantityForCart = adjustedValue
      setSelectedItemsForWorkflow([...selectedItemsForWorkflow])
   }

   const handleCommenceWorkflow = () => {
      if (isQuantityModalOpen) {
         setIsQuantityModalOpen(false)
      }
      setIsCheckoutModalOpen(true)
   }

   const request = useAPI()

   const getItemFromAPI = async ({ Id, ToolLoanId, KitId }: GetItemOptions) => {
      const { data } = await request.get(
         `${PATH.ITEMS.LIST_VIEW}?Id=${Id}&ToolLoanId=${ToolLoanId}&KitId=${KitId}`
      )
      return data?.Data
   }

   const refreshItemInList = async (rawItem: Item) => {
      setLoading(true)
      const itemToRefresh = {
         ...rawItem,
         localUniqueId: selectedItemUniqueId,
         AuditItemID: selectedAuditItemId,
      }
      const { Id, ToolLoanId, KitId } = itemToRefresh
      const workflowType = checkoutAction?.label
      setItemId(Id)
      // Quantity items
      if (itemToRefresh?.ToolType === 2) {
         removeItemFromAudit(itemToRefresh)
         // only add the original item if it wasn't field transferred
         if (workflowType !== View.Field_Transfer) {
            const originalItem = await getItemFromAPI({
               Id: Id,
               ToolLoanId: ToolLoanId,
               KitId: KitId,
            })
            const updatedOriginalItem = {
               ...originalItem,
               localUniqueId: selectedItemUniqueId,
               AuditItemID: null,
            }
            handleAddAuditItem(updatedOriginalItem)
         }
         // handle adding the new item created by the workflow request
         if (
            workflowType === View.Loan ||
            workflowType === View.Field_Transfer
         ) {
            const addedItem = await getItemFromAPI({
               Id: workflowItem?.ToolId,
               ToolLoanId: workflowItem?.ToolLoanId,
               KitId: workflowItem?.KitId,
            })
            handleAddAuditItem(addedItem)
         }
         // handle refreshing available items for return request
         if (workflowType === View.Return) {
            const availableItem: any = pagedTableData.find(
               (item) => item?.Id === itemToRefresh?.Id && item?.Status === 0
            )
            if (availableItem) {
               removeItemFromAudit(availableItem)
               const updatedItem = await getItemFromAPI({
                  Id: availableItem?.Id,
                  ToolLoanId: availableItem?.ToolLoanId,
                  KitId: availableItem?.KitId,
               })
               const updatedAvailableItem = {
                  ...updatedItem,
                  localUniqueId: selectedItemUniqueId,
                  AuditItemID: null,
               }
               handleAddAuditItem(updatedAvailableItem)
            }
         }
      }
      // None quantity items
      if (itemToRefresh?.ToolType !== 2) {
         removeItemFromAudit(itemToRefresh)
         if (workflowItem) {
            const addedWorkflowItem = await getItemFromAPI({
               Id: workflowItem?.ToolId ?? Id,
               ToolLoanId: workflowItem?.ToolLoanId ?? null,
               KitId: workflowItem?.KitId ?? null,
            })
            handleAddAuditItem(addedWorkflowItem)
         } else {
            const updatedItem = await getItemFromAPI({
               Id: Id,
               ToolLoanId: ToolLoanId,
               KitId: KitId,
            })
            handleAddAuditItem(updatedItem)
         }
      }
      handleItemModalClose()
      setLoading(false)
   }

   const handleResetPageAfterCheckout = () => {
      setIsCheckoutModalOpen(false)
      setIsQuantityModalOpen(false)
      refreshItemInList(itemForm)
   }

   const handleWorkflowAction = (workflowOption: string) => {
      let workflowPath
      if (workflowOption === 'Transfer') {
         if (selectedItemsForWorkflow[0].StatusDesc === 'Available') {
            workflowPath = checkoutOptions.find(
               (x) => x.label === 'Warehouse Transfer'
            )
         } else {
            workflowPath = checkoutOptions.find(
               (x) => x.label === 'Field Transfer'
            )
         }
      } else {
         workflowPath = checkoutOptions.find((x) => x.label === workflowOption)
      }
      setCheckoutAction(workflowPath)

      if (isQuantityItemsSelected(selectedItemsForWorkflow)) {
         let quantItems = selectedItemsForWorkflow
         //  setSelectedWorkflowActionForQuantityModal(() => selectedWorkflowAction); // handoff workflow action to quantity modal
         // there should only ever be one item in this array
         if (
            workflowPath.label === 'Warehouse Transfer' &&
            workflowPath.status === ToolStatus.AVAILABLE
         ) {
            quantItems[0].quantityForCart = quantItems[0].StatusQuantity
         } else {
            quantItems[0].quantityForCart = 1
         }
         setSelectedItemsForWorkflow(quantItems)
         setIsQuantityModalOpen(true)
      } else {
         handleCommenceWorkflow()
      }
   }

   const handleWorkflowSuccess = (response) => {
      if (response?.SuccessfulWithLoanID?.length > 0) {
         const loanItem =
            response?.SuccessfulWithLoanID[
               response?.SuccessfulWithLoanID.length - 1
            ]
         setWorkflowItem(loanItem)
      } else if (response?.SuccessfulWithKitID?.length > 0) {
         const kitItem =
            response?.SuccessfulWithKitID[
               response?.SuccessfulWithKitID.length - 1
            ]
         setWorkflowItem(kitItem)
      } else {
         setWorkflowItem(null)
      }
   }

   const isLoading =
      isItemLoading ||
      isItemRefetching ||
      isRemoving ||
      isAdding ||
      isAddingToKit ||
      isDeletingKit ||
      isLoadingKits ||
      auditIsLoading ||
      loading

   return (
      <>
         {isLoading && <LoadingOverlay />}
         <SnackBar
            message={snackbar.message}
            open={
               snackbar.message !== '' && snackbar.severity !== Severity.INFO
            }
            severity={snackbar.severity}
            disableAutoClose={snackbar.disableAutoClose}
            resetMessageStateHandler={() => setSnackbar(closedSnackbarState)}
         />
         {selectedAuditFormData.AuditID &&
            selectedAuditFormData.str_Status !== 'Draft' && (
               <div className={styles.tabs}>
                  <button
                     type="button"
                     className={classnames(styles.tab, {
                        [styles.tabActive]:
                           selectedView === ViewName.UNCONFIRMED,
                     })}
                     onClick={(event) =>
                        handleTabClick(ViewName.UNCONFIRMED, event)
                     }
                  >
                     Unconfirmed
                  </button>
                  <button
                     type="button"
                     className={classnames(styles.tab, {
                        [styles.tabActive]: selectedView === ViewName.CONFIRMED,
                     })}
                     onClick={(event) =>
                        handleTabClick(ViewName.CONFIRMED, event)
                     }
                  >
                     Confirmed
                  </button>
                  <button
                     type="button"
                     className={classnames(styles.tab, {
                        [styles.tabActive]: selectedView === ViewName.FOUND,
                     })}
                     onClick={(event) => handleTabClick(ViewName.FOUND, event)}
                  >
                     Found
                  </button>
               </div>
            )}
         <div className={styles.itemList}>
            <div
               className={
                  selectedView === ViewName.UNCONFIRMED
                     ? ''
                     : styles.nonActiveTab
               }
            >
               {!unconfirmed?.length && !isLoading ? (
                  <p>{noItemsText}</p>
               ) : (
                  <TableWrapper
                     columns={columns}
                     data={pagedTableData as any}
                     getItems={handleGetItems}
                     skipItems={unconfirmedSkip}
                     takeItems={unconfirmedTake}
                     totalCount={unconfirmedTotalCount}
                     extraClassName=""
                     isLoading={isLoading}
                     isPageReset={false}
                     searchCriteria=""
                     searchMode
                     setItemId={() => {}}
                     setPageSized={() => null}
                     setSearchMode={() => null}
                     hideResultsTitle
                     listViewData={itemResponse}
                     isListLoading={isItemLoading}
                     isListRefetching={isItemRefetching}
                     isListSuccess={isItemSuccess}
                     getItem={getItem}
                  />
               )}
            </div>

            <div
               className={
                  selectedView === ViewName.CONFIRMED ? '' : styles.nonActiveTab
               }
            >
               {!confirmed?.length && !isLoading ? (
                  <p>{noItemsText}</p>
               ) : (
                  <TableWrapper
                     columns={columns}
                     data={pagedTableData as any}
                     getItems={handleGetItems}
                     skipItems={confirmedSkip}
                     takeItems={confirmedTake}
                     totalCount={confirmedTotalCount}
                     extraClassName=""
                     isLoading={isLoading}
                     isPageReset={false}
                     searchCriteria=""
                     searchMode
                     setItemId={() => {}}
                     setPageSized={() => null}
                     setSearchMode={() => null}
                     hideResultsTitle
                     listViewData={itemResponse}
                     isListLoading={isItemLoading}
                     isListRefetching={isItemRefetching}
                     isListSuccess={isItemSuccess}
                     getItem={getItem}
                  />
               )}
            </div>

            <div
               className={
                  selectedView === ViewName.FOUND ? '' : styles.nonActiveTab
               }
            >
               {!found?.length && !isLoading ? (
                  <p>{noItemsText}</p>
               ) : (
                  <TableWrapper
                     columns={columns}
                     data={pagedTableData as any}
                     getItems={handleGetItems}
                     skipItems={foundSkip}
                     takeItems={foundTake}
                     totalCount={foundTotalCount}
                     extraClassName=""
                     isLoading={isLoading}
                     isPageReset={false}
                     searchCriteria=""
                     searchMode
                     setItemId={() => {}}
                     setPageSized={() => null}
                     setSearchMode={() => null}
                     hideResultsTitle
                     listViewData={itemResponse}
                     isListLoading={isItemLoading}
                     isListRefetching={isItemRefetching}
                     isListSuccess={isItemSuccess}
                     getItem={getItem}
                  />
               )}
            </div>

            {itemToRemove !== null && (
               <Modal
                  isModalVisible={itemToRemove !== null}
                  closeModal={handleCancelRemoveItem}
                  title="Warning"
               >
                  <div className={auditsStyles.deleteForm}>
                     <p>
                        Are you sure you want to remove{' '}
                        <strong>{itemToRemove.Title}</strong> from the Audit?
                     </p>

                     <Row gutterWidth={20}>
                        <Col xs={6}>
                           <Button
                              type="button"
                              minWidth="100%"
                              variant="danger"
                              onClick={handleConfirmRemoveItem}
                           >
                              OK
                           </Button>
                        </Col>
                        <Col xs={6}>
                           <Button
                              type="button"
                              minWidth="100%"
                              variant="tertiary"
                              onClick={handleCancelRemoveItem}
                              isReset
                           >
                              Cancel
                           </Button>
                        </Col>
                     </Row>
                  </div>
               </Modal>
            )}
         </div>
         {isItemModalVisible && (
            <Modal
               isModalVisible={isItemModalVisible}
               closeModal={handleItemModalClose}
               title={itemForm?.Title}
               itemButtons={allItemButtons}
               permissions={itemForm.Permissions || []}
               marginTop={isMobile && '2rem'}
            >
               <CrudItems
                  isNewItemNote={isNewItemNote}
                  setIsNewItemNote={setIsNewItemNote}
                  postItem={postItem}
                  setItemImages={setItemImages}
                  itemImages={itemImages}
                  deleteImage={deleteImage}
                  changePosition={changePosition}
                  isPositionLoading={isPositionLoading}
                  isPositionSuccess={isPositionSuccess}
                  setItemStatus={setItemStatus}
                  itemStatus={itemStatus}
                  activeValue={activeValue}
                  isLoading={isLoading}
                  itemTypes={itemTypeOptions}
                  categories={categoryOptions}
                  manufacturers={manufacturerOptions}
                  locations={locationOptions}
                  conditions={conditionOptions}
                  itemNotesData={itemNotesData}
                  itemHistoryData={itemHistoryData}
                  handleAction={handleActionForm}
                  closeModal={handleItemModalClose}
                  itemId={itemId}
                  itemAction={itemAction}
                  setItemAction={setItemAction}
                  isModalVisible={isItemModalVisible}
                  itemForm={itemForm}
                  setItemForm={setItemForm}
                  resetRef={resetRef}
                  getItemView={getItemView}
                  refetchNoteTableData={refetchNoteTableData}
                  parentPageView={CrudItemsParentView.Audits}
                  handleWorkflowAction={handleWorkflowAction}
                  username={`${profile?.Firstname ?? ''} ${
                     profile?.Lastname ?? ''
                  }`}
                  isAdminCompanyUser
               />
            </Modal>
         )}
         {isCopyPrompt && (
            <WarningModal
               isModalOpen={isCopyPrompt}
               handleClose={() => setIsCopyPrompt(false)}
               title={'Copy Confirmation'}
               message={'Are you sure you want to copy this item?'}
               primaryButtonTitle={'Ok'}
               primaryButtonMethod={handleCopy}
               primaryButtonVariant={'primary'}
               secondaryButtonTitle={'Cancel'}
               secondaryButtonMethod={() => setIsCopyPrompt(false)}
               secondaryButtonVariant={'tertiary'}
            />
         )}
         {isItemLimitPrompt && (
            <WarningModal
               isModalOpen={isItemLimitPrompt}
               handleClose={() => setIsItemLimitPrompt(false)}
               title={'Item Limit Reached'}
               hasChildren
               message="Need more items?"
               children={
                  <Alert message="You have reached the item limit for your free account. Explore ShareMyToolbox paid system for more items and capabilities." />
               }
               zIndex="9999"
               primaryButtonTitle={'Close'}
               primaryButtonVariant={'tertiary'}
               primaryButtonMethod={() => setIsItemLimitPrompt(false)}
            />
         )}
         {isDeletePrompt && (
            <WarningModal
               isModalOpen={isDeletePrompt}
               handleClose={() => setIsDeletePrompt(false)}
               title={'Delete Item'}
               message={'Are you sure you want to delete this item?'}
               primaryButtonTitle={'Delete'}
               primaryButtonMethod={(id) => handleDelete(id)}
               primaryButtonVariant={'danger'}
               secondaryButtonTitle={'Cancel'}
               secondaryButtonMethod={() => setIsDeletePrompt(false)}
               secondaryButtonVariant={'tertiary'}
            />
         )}
         {isManageKitModalOpen && (
            <ManageKitModal
               isModalOpen={isManageKitModalOpen}
               handleClose={() => setIsManageKitModalOpen(false)}
               kitId={itemId}
               itemForm={itemForm}
            />
         )}
         {isAddToKitModal && (
            <AddToKitModal
               isModalOpen={isAddToKitModal}
               handleClose={handleItemModalClose}
               handleAddToKit={handleAddToKit}
               cancel={() => setIsAddToKitModal(false)}
               selectKit={itemKitOption}
               addToKitQty={addToKitQty}
               setAddToKitQty={setAddToKitQty}
               itemForm={itemForm}
            />
         )}
         {isRemoveFromKitModal && (
            <Modal
               isModalVisible={isRemoveFromKitModal}
               closeModal={handleItemModalClose}
               title="Remove from Kit"
            >
               <DeleteKit
                  handleDeleteKit={handleRemoveFromKit}
                  cancel={() => setIsRemoveFromKitModal(false)}
                  title=""
                  subTitle=""
                  actionButton={'REMOVE'}
                  itemForm={itemForm}
               />
            </Modal>
         )}
         {isQuantityModalOpen && (
            <ItemQuantityModal
               isModalOpen={isQuantityModalOpen}
               handleClose={() => setIsQuantityModalOpen(false)}
               handleAdjustTotal={handleIncrementDecrementQuantity}
               handleCustomTotalChange={handleCustomTotalChange}
               startWorkflowAction={handleCommenceWorkflow}
               items={selectedItemsForWorkflow}
               checkoutAction={checkoutAction}
               zIndex={'999'}
            />
         )}
         {isCheckoutModalOpen && (
            <CheckoutModal
               isModalOpen={isCheckoutModalOpen}
               handleClose={() => setIsCheckoutModalOpen(false)}
               checkoutAction={checkoutAction}
               selectedItems={selectedItemsForWorkflow}
               handlePageCancelAndReset={handleResetPageAfterCheckout}
               zIndex={'999'}
               handleWorkflowSuccess={handleWorkflowSuccess}
               viewTitle
               isAdminCompanyUser
            />
         )}
         {auditNoteDetails && (
            <AuditNoteModal
               open={auditNoteModalOpen}
               handleClose={handleAuditNoteModalClose}
               noteDetails={auditNoteDetails}
            />
         )}
      </>
   )
}

export default memo(ItemAuditedList)
