import { useEffect, useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'
import { NotificationTypes } from '../../types'

import {
   UseNotifications,
   UseNotificationsOptions,
   NotificationsResponse,
   NotificationParams,
   UpdateNotificationParams,
   DismissNotificationParams,
} from './types'

export default function ({
   itemsPerPage = 10,
   onChange,
   totalItems = 0,
   notificationType,
   currentPage = 1,
}: UseNotificationsOptions): UseNotifications {
   const request = useAPI()

   async function getNotificationsFromAPI(
      params: NotificationParams
   ): Promise<AxiosResponse<NotificationsResponse>> {
      return await request.get(PATH.DASHBOARD.NOTIFICATIONS, {
         params,
      })
   }

   const { data, isLoading, isRefetching, refetch } = useQuery(
      ['notifications', { notificationType, currentPage }],
      () => {
         const params: NotificationParams = {
            skip: (currentPage - 1) * itemsPerPage,
            take: itemsPerPage,
            category:
               notificationType === NotificationTypes.Global
                  ? 1
                  : notificationType,
            returnCompanyNotifications:
               notificationType === NotificationTypes.Global ? true : undefined,
         }
         return getNotificationsFromAPI(params)
      }
   )

   useEffect(() => {
      refetch()
   }, [currentPage])

   async function dismissNotificationInAPI(id: number): Promise<AxiosResponse> {
      const params: DismissNotificationParams = {
         id,
         accept: false,
         delete: true,
      }
      return await request.post(PATH.DASHBOARD.NOTIFICATION_DISMISS, params)
   }

   const {
      mutate: mutateDismissNotification,
      isLoading: isDismissing,
      isSuccess: isDismissSuccess,
   } = useMutation(dismissNotificationInAPI, {
      onSettled: () => {
         refetch()
         onChange && onChange()
      },
   })

   const dismiss = (id: number) => mutateDismissNotification(id)

   const deleteGlobal = (id: number) => mutateDismissNotification(id)

   async function updateNotificationInAPI(
      params: UpdateNotificationParams
   ): Promise<AxiosResponse> {
      return await request.post(PATH.DASHBOARD.NOTIFICATION_DISMISS, params)
   }

   const {
      mutate: mutateAcceptRejectNotification,
      isSuccess: isAcceptRejectSuccess,
      isLoading: isAcceptRejectLoading,
   } = useMutation(updateNotificationInAPI, {
      onSettled: () => {
         refetch()
         onChange && onChange()
      },
   })

   const acceptReject = (id: number, accept: boolean) =>
      mutateAcceptRejectNotification({ id, accept })

   const list = data?.data?.Data || []

   const isLastPage = useMemo(() => {
      const numberOfItems = list.length
      return numberOfItems % itemsPerPage !== 0 || numberOfItems === totalItems
   }, [list, itemsPerPage, totalItems])

   return {
      isLastPage: isLastPage,
      isLoading:
         isLoading || isRefetching || isDismissing || isAcceptRejectLoading,
      isLoadingMore: isRefetching,
      list,
      acceptReject,
      dismiss,
      deleteGlobal,
      isDismissSuccess,
      isAcceptRejectSuccess,
   }
}
