import React, { ChangeEvent, forwardRef, memo, useRef } from 'react'
import classNames from 'classnames'

import { FieldWrapper } from '..'
import * as styles from './input.module.scss'
import { InputProps } from './types'

const Input = forwardRef(
   (
      {
         className,
         description,
         disabled,
         error,
         id,
         inputProps,
         isControlled = false,
         label,
         noMargin = false,
         onChange,
         placeholder,
         required = false,
         theme,
         withWrappers = true,
         value,
         maxLength,
         isReadonly = false,
      }: InputProps,
      ref
   ) => {
      const inputRef = useRef()

      const elRef: any = ref ?? inputRef

      const inputClasses = classNames(
         {
            [styles.field]: true,
            [styles.fieldDark]: theme === 'dark',
         },
         className
      )

      const handleInputChange = (event: ChangeEvent<HTMLFormElement>) => {
         if (onChange) {
            onChange(event)
         }
      }

      const renderElement = () => (
         <input
            {...(inputProps || {})}
            {...(!isControlled && { defaultValue: value || '' })}
            {...(isControlled && { value: value || '' })}
            className={inputClasses}
            aria-invalid={!!error}
            id={id}
            name={id}
            // @ts-ignore
            onChange={handleInputChange}
            type={inputProps?.type || 'text'}
            disabled={disabled}
            maxLength={maxLength && Number(maxLength)}
            ref={elRef}
            placeholder={placeholder}
            onKeyDown={(event) =>
               event.key === 'e' &&
               inputProps?.type === 'number' &&
               event.preventDefault()
            }
            autoComplete={inputProps?.autoComplete ?? 'auto'}
            readOnly={isReadonly}
         />
      )

      if (!withWrappers) {
         return renderElement()
      }

      return (
         <FieldWrapper
            description={description}
            disabled={disabled}
            error={error}
            id={id}
            label={label}
            required={required}
            noMargin={noMargin}
         >
            {renderElement()}
         </FieldWrapper>
      )
   }
)

export default memo(Input)
