// extracted by mini-css-extract-plugin
export var coordinatesButton = "audits-module--coordinates-button--gk7Wh";
export var deleteForm = "audits-module--delete-form--og+em";
export var description = "audits-module--description--djjIK";
export var divider = "audits-module--divider--12xVz";
export var filterHeading = "audits-module--filter-heading--nT2R1";
export var form = "audits-module--form--K2Wzl";
export var icon = "audits-module--icon--YgxVU";
export var importDownloadExample = "audits-module--import-download-example--XDXEg";
export var importForm = "audits-module--import-form--g9YEN";
export var locationsWrapper = "audits-module--locationsWrapper--hsagn";
export var saveCancelReviewButtonSet = "audits-module--saveCancelReviewButtonSet--vf-B-";
export var searchWrapper = "audits-module--search-wrapper--DqD5x";
export var searchWrapperFilter = "audits-module--search-wrapper-filter--+19Vs";
export var searchWrapperSearch = "audits-module--search-wrapper-search--qBtO-";
export var status = "audits-module--status--CjgqQ";
export var toggle = "audits-module--toggle--P8Vy7";
export var toggleField = "audits-module--toggle-field--p6lzq";
export var toggleLabel = "audits-module--toggle-label--bMIrE";
export var warehouseDescription = "audits-module--warehouse-description--3VZXp";