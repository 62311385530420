import React, { memo } from 'react'

import * as styles from './importModal.module.scss'
import { ImportInitialViewProps, NumberedSectionProps } from './types'
import NumberedSection from './numberedSection'
import { DownloadButton, UploadButton } from './actions'

const ImportInitialView = ({
   variant,
   selectedFile,
   setSelectedFile,
}: ImportInitialViewProps) => {
   const sections: NumberedSectionProps[] = [
      {
         num: 1,
         heading: 'Get the Import Template',
         description: `To ensure proper import to your database, first download the Import Template, then populate with your ${variant} before importing. ${
            variant === 'Connections'
               ? 'This import is for Employee & Virtual roles only. Admin roles must be setup manually.'
               : ''
         }`,
         action: <DownloadButton variant={variant} />,
      },
      {
         num: 2,
         heading: `Upload Your ${variant}`,
         description: `After using the Import Template to fill in your ${variant}, select the file from your computer to upload.`,
         action: (
            <UploadButton
               selectedFile={selectedFile}
               setSelectedFile={setSelectedFile}
            />
         ),
      },
   ]
   return (
      <div className={styles.leftColumn}>
         {sections.map((section) => (
            <NumberedSection
               key={section.num}
               num={section.num}
               heading={section.heading}
               description={section.description}
               action={section.action}
            />
         ))}
      </div>
   )
}

export default memo(ImportInitialView)
