import React from 'react';

import * as styles from './footer.module.scss';

interface Props {
    wrap?: boolean;
}

const APP_COMPANY = process.env.APP_COMPANY;

export const Footer: React.FC<Props> = props => {
    const { wrap = false } = props;

    const getYear = () => {
        const d = new Date();
        return  d.getFullYear();
    };

    return (
        <footer className={`${styles.footer} ${wrap ? styles.footerWrap : ''}`}>
            <div className={styles.footerMessage}>Tool Tracking Made Simple.</div>
            <span>Copyright &#169;{ getYear() }</span> <span>{APP_COMPANY}</span>
        </footer>
    );
};