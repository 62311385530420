import { useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { useAPI } from '..'
import { PATH } from '../../constants/global'

import {
   SubscriptionsListOptions,
   UseSubscriptionsData,
   UpdateSubscriptionPayload,
} from './types'
import { useEffect, useState } from 'react'

export default function (): UseSubscriptionsData {
   const request = useAPI()
   const [listOptions, setListOptions] =
      useState<SubscriptionsListOptions | null>(null)

   async function getSubscriptionsFromAPI(
      options: SubscriptionsListOptions
   ): Promise<AxiosResponse> {
      return await request.post(PATH.SUBSCRIPTIONS.LIST, options)
   }

   const {
      data,
      isLoading,
      isError,
      refetch: refetchSubscriptions,
      isRefetching,
   } = useQuery(
      'subscriptions',
      (): Promise<AxiosResponse> => getSubscriptionsFromAPI(listOptions),
      { enabled: !!listOptions }
   )

   useEffect(() => {
      if (listOptions) {
         refetchSubscriptions()
      }
   }, [listOptions])

   async function updateSubscriptionInAPI(
      subscriptionDetails: UpdateSubscriptionPayload
   ): Promise<AxiosResponse> {
      return await request.post(PATH.SUBSCRIPTIONS.UPDATE, {
         ...subscriptionDetails,
      })
   }

   const {
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      mutate: mutateUpdate,
   } = useMutation(updateSubscriptionInAPI)

   const updateSubscriptionDetails = (
      subscriptionDetails: UpdateSubscriptionPayload
   ) => mutateUpdate(subscriptionDetails)

   async function deleteSubscriptionFromAPI(
      id: string
   ): Promise<AxiosResponse> {
      return await request.post(
         `${PATH.SUBSCRIPTIONS.DELETE}?subscriptionId=${id}`
      )
   }

   const {
      isError: isDeleteError,
      isLoading: isDeleting,
      isSuccess: isDeleteSuccess,
      mutate: mutateDelete,
   } = useMutation(deleteSubscriptionFromAPI)

   const deleteSubscription = (id: string) => mutateDelete(id)

   return {
      subscriptions: data?.data || [],
      isLoading,
      isRefetching,
      isError,
      setListOptions,
      refetchSubscriptions,
      isUpdating,
      isUpdateSuccess,
      isUpdateError,
      updateSubscriptionDetails,
      isDeleting,
      isDeleteError,
      isDeleteSuccess,
      deleteSubscription,
   }
}
