import { useMutation } from 'react-query';

import {useAPI} from '..';
import {PATH} from '../../constants/global';

import { UseAccountTrial, UserTrial } from './useAccountTrial.type';

export default function(): UseAccountTrial {
    const request = useAPI();
    const { error, isLoading, mutate, isSuccess } = useMutation(upgradeUserAPI);

    async function upgradeUserAPI(user: UserTrial) {
        return request.post(PATH.REGISTER.TRIAL_ACCOUNT, user);
    }

    const register = (user: UserTrial) => {
        mutate(user);
    }

    return {
        // @ts-ignore
        error: error?.response.data?.Message,
        isLoading,
        isSuccess,
        register,
    }
}