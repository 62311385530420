import React from 'react'
import { IoMdClose as NoIcon } from 'react-icons/io'
import { HiOutlineCheck as YesIcon } from 'react-icons/hi'

type TrueOrFalseProps = {
   falseLabel?: string
   status?: boolean | null
   trueLabel?: string
}

import * as styles from './trueOrFalse.module.scss'

export const TrueOrFalse = ({
   falseLabel = 'No',
   status = false,
   trueLabel = 'Yes',
}: TrueOrFalseProps) => (
   <span className={styles.wrapper}>
      {status ? (
         <YesIcon className={styles.trueIcon} title={trueLabel} />
      ) : (
         <NoIcon className={styles.falseIcon} title={falseLabel} />
      )}
   </span>
)
