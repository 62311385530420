// @refresh reset
import React, { useEffect, useMemo, useState } from 'react'
import * as styles from './itemGPSView.module.scss'
import ItemGPSMap from './itemGPSMap/itemGPSMap'
import { Col, Row } from 'react-grid-system'
import classNames from 'classnames'
import LocationKeyValue from './locationKeyValue'
import useGpsBluetooth from '../../../../hooks/useGpsBluetooth/useGpsBluetooth'
import { formatLocaleDate } from '../../../../common/utils/functions'
import { GpsLocation } from '../../../../hooks/useGpsBluetooth/useGpsBluetooth.type'
import LoadingOverlay from '../../loadingOverlay/loadingOverlay'
import Svg from '../../svg/svg'

interface Props {
   selectedItem: unknown
}

const MapHeader = ({
   handleCheckBoxToggle,
   isGpsChecked,
   isBluetoothChecked,
   showChecks,
}) => {
   return (
      <div className={styles.subheader}>
         <span className={styles.mapKey}>
            <div className={styles.mapKeySvg}>
               <Svg id="pinNoShadow" fill={'#FFCA05'} width={15} />
            </div>

            <span className={styles.mapKeyValue}>Assigned Location</span>
         </span>
         <span className={styles.mapKey}>
            <div className={styles.mapKeySvg}>
               <Svg id="lastSeenArea" width={15} />
            </div>
            <span className={styles.mapKeyValue}>Area Last Seen</span>
         </span>
         {showChecks && (
            <div className={styles.checkboxWrapper}>
               <div
                  className={styles.checkContainer}
                  onClick={() => handleCheckBoxToggle('GPS')}
               >
                  <input
                     className={styles.nativeCheckbox}
                     type="checkbox"
                     checked={isGpsChecked}
                  />
                  <span className={styles.checkmark}></span> GPS Scans
               </div>

               <div
                  className={styles.checkContainer}
                  onClick={() => handleCheckBoxToggle('BT')}
               >
                  <input
                     className={styles.nativeCheckbox}
                     type="checkbox"
                     checked={isBluetoothChecked}
                  />
                  <span className={styles.checkmark}></span> Bluetooth Pings
               </div>
            </div>
         )}
      </div>
   )
}

export const ItemGPSView: React.FunctionComponent<Props> = ({
   selectedItem,
}) => {
   const [showChecks, setShowChecks] = useState<boolean>(
      !!selectedItem?.GPs && !!selectedItem?.Bluetooth
   )
   const {
      getToolGpsLocations,
      gpsLocationsList,
      isLoadingLocationsList,
      isRefetchingLocationsList,
   } = useGpsBluetooth()

   //    const test =  [
   //       {
   //           "ID": 46,
   //           "ToolID": 565447,
   //           "CreatedById": "54d68457-0278-40bb-9ed5-8955cc5f77e4",
   //           "Event": 0,
   //           "Latitude": 54.124416,
   //           "Longitude": -1.6045650125,
   //           "CreatedByName": "Shout Digital",
   //           "CreatedOn": "2023-04-27T11:06:11.527"
   //       },
   //       {
   //           "ID": 45,
   //           "ToolID": 565447,
   //           "CreatedById": "54d68457-0278-40bb-9ed5-8955cc5f77e4",
   //           "Event": 0,
   //           "Latitude": 53.4328503,
   //           "Longitude": -2.9623865,
   //           "CreatedByName": "Shout Digital",
   //           "CreatedOn": "2023-04-27T10:35:36.85"
   //       },
   //       {
   //           "ID": 37,
   //           "ToolID": 565447,
   //           "CreatedById": "54d68457-0278-40bb-9ed5-8955cc5f77e4",
   //           "Event": 0,
   //           "Latitude": 51.5281534,
   //           "Longitude": -0.0902569,
   //           "CreatedByName": "Shout Digital",
   //           "CreatedOn": "2023-04-27T09:11:55.707"
   //       },
   //       {
   //           "ID": 36,
   //           "ToolID": 565447,
   //           "CreatedById": "54d68457-0278-40bb-9ed5-8955cc5f77e4",
   //           "Event": 0,
   //           "Latitude": 53.4625761,
   //           "Longitude": -2.2910317,
   //           "CreatedByName": "Shout Digital",
   //           "CreatedOn": "2023-04-27T09:11:49.863"
   //       },
   //       {
   //           "ID": 35,
   //           "ToolID": 565447,
   //           "CreatedById": "54d68457-0278-40bb-9ed5-8955cc5f77e4",
   //           "Event": 0,
   //           "Latitude": 54.9713085,
   //           "Longitude": -1.6029093,
   //           "CreatedByName": "Shout Digital",
   //           "CreatedOn": "2023-04-27T09:11:43.067"
   //       },
   //       {
   //           "ID": 34,
   //           "ToolID": 565447,
   //           "CreatedById": "54d68457-0278-40bb-9ed5-8955cc5f77e4",
   //           "Event": 0,
   //           "Latitude": 53.3976041,
   //           "Longitude": -2.9436643,
   //           "CreatedByName": "Shout Digital",
   //           "CreatedOn": "2023-04-27T09:11:38.127"
   //       },
   //       {
   //           "ID": 33,
   //           "ToolID": 565447,
   //           "CreatedById": "54d68457-0278-40bb-9ed5-8955cc5f77e4",
   //           "Event": 0,
   //           "Latitude": 54.969345716,
   //           "Longitude": -1.6034540125,
   //           "CreatedByName": "Shout Digital",
   //           "CreatedOn": "2023-04-27T09:11:31.9"
   //       },
   //       {
   //           "ID": 32,
   //           "ToolID": 565447,
   //           "CreatedById": "54d68457-0278-40bb-9ed5-8955cc5f77e4",
   //           "Event": 0,
   //           "Latitude": 54.969340416,
   //           "Longitude": -1.6036554725,
   //           "CreatedByName": "Shout Digital",
   //           "CreatedOn": "2023-04-27T09:11:02.393"
   //       },
   //       {
   //           "ID": 31,
   //           "ToolID": 565447,
   //           "CreatedById": "54d68457-0278-40bb-9ed5-8955cc5f77e4",
   //           "Event": 0,
   //           "Latitude": 54.969340416,
   //           "Longitude": -1.603650145,
   //           "CreatedByName": "Shout Digital",
   //           "CreatedOn": "2023-04-27T09:10:58.393"
   //       }
   //   ]

   const [isGpsChecked, setIsGpsChecked] = useState<boolean>(true)
   const [isBluetoothChecked, setIsBluetoothChecked] = useState<boolean>(false)
   const [selectedLocation, setSelectedLocation] = useState<GpsLocation | any>(
      null
   )
   const [isMapZooming, setIsMapZooming] = useState<boolean>(false)

   useEffect(() => {
      getToolGpsLocations(selectedItem?.Id)

      return () => getToolGpsLocations(null)
   }, [selectedItem])

   const showCurrentLocation = useMemo(() => {
      if (
         selectedItem.CurrentLocation &&
         selectedItem.Latitude &&
         selectedItem.Longitude
      ) {
         return true
      } else {
         return false
      }
   }, [selectedItem])

   const showLocationsSection = useMemo(() => {
      if (gpsLocationsList.length || showCurrentLocation) {
         return true
      } else {
         return false
      }
   }, [showCurrentLocation, gpsLocationsList])

   const handleCheckBoxToggle = (value: string) => {
      switch (value) {
         case 'GPS':
            setIsGpsChecked(!isGpsChecked)
            break
         case 'BT':
            setIsBluetoothChecked(!isBluetoothChecked)
      }
   }

   const selectedLocationToCurrentLocation = () => {
      if (selectedItem.localUniqueId !== selectedLocation?.ID) {
         setIsInfoCardOpen(false)
         setSelectedLocation({
            CurrentLocation: selectedItem.CurrentLocation,
            ID: selectedItem.localUniqueId,
            Latitude: selectedItem.Latitude,
            Longitude: selectedItem.Longitude,
         })
      }
   }

   const selectedLocationToScanLocation = (location: GpsLocation) => {
      if (location.ID !== selectedLocation?.ID) {
         setIsInfoCardOpen(false)
         setSelectedLocation(location)
      }
   }

   const [isInfoCardOpen, setIsInfoCardOpen] = useState<boolean>(false)

   const handleCloseInfoCard = () => {
      setIsInfoCardOpen(false)
      setSelectedLocation(null)
   }

   const handleOpenInfoCard = () => {
      setIsInfoCardOpen(true)
   }

   return (
      <section>
         {(isLoadingLocationsList || isRefetchingLocationsList) && (
            <LoadingOverlay />
         )}
         <Row gutterWidth={15}>
            <MapHeader
               isGpsChecked={isGpsChecked}
               isBluetoothChecked={isBluetoothChecked}
               handleCheckBoxToggle={handleCheckBoxToggle}
               showChecks={showChecks}
            />
            <Col
               sm={showLocationsSection ? 7 : 12}
               lg={showLocationsSection ? 8.5 : 12}
               className={styles.mapArea}
            >
               <div className={styles.mapContent}>
                  {!isLoadingLocationsList && !isRefetchingLocationsList && (
                     <ItemGPSMap
                        selectedItem={selectedItem}
                        gps_bt_locationsList={gpsLocationsList}
                        isLoadingLocationsList={isLoadingLocationsList}
                        selectedLocation={selectedLocation}
                        selectedLocationToCurrentLocation={
                           selectedLocationToCurrentLocation
                        }
                        selectedLocationToScanLocation={
                           selectedLocationToScanLocation
                        }
                        isInfoCardOpen={isInfoCardOpen}
                        handleCloseInfoCard={handleCloseInfoCard}
                        handleOpenInfoCard={handleOpenInfoCard}
                        setIsMapZooming={setIsMapZooming}
                     />
                  )}
               </div>
            </Col>
            {showLocationsSection && (
               <Col sm={5} lg={3.5} className={styles.locationsWrapper}>
                  <div className={styles.locationsListHeader}>
                     Up to Last 20 Known
                     <br />
                     Locations
                  </div>
                  <div>Tap to view on map.</div>
                  <div
                     className={classNames(styles.locationsList, {
                        [styles.locationsListDisabled]: isMapZooming,
                     })}
                  >
                     {showCurrentLocation && (
                        <>
                           <div style={{ marginBottom: '-0.5rem' }}>
                              <LocationKeyValue
                                 label={selectedItem?.CurrentLocation ?? 'N/A'}
                                 value={' ' ?? 'N/A'}
                                 svgId={'pinNoShadow'}
                                 svgFill={'#FFCA05'}
                                 svgWidth={16}
                                 onClick={selectedLocationToCurrentLocation}
                              />
                           </div>
                           <hr style={{ marginBottom: '1rem' }} />
                        </>
                     )}

                     {!isLoadingLocationsList &&
                        gpsLocationsList.map((location) => {
                           return (
                              <div key={`${location.ToolID}_${location.ID}`}>
                                 <LocationKeyValue
                                    label={location.CreatedByName}
                                    value={formatLocaleDate(location.CreatedOn)}
                                    svgId={'gps'}
                                    onClick={() =>
                                       selectedLocationToScanLocation(location)
                                    }
                                 />
                              </div>
                           )
                        })}
                  </div>
               </Col>
            )}
         </Row>
      </section>
   )
}
