import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import {
   UseAudit,
   Audit,
   AuditQuery,
   AuditRecord,
   DraftAuditItemsList,
   AuditItemsType,
   AuditItem,
} from './useAudit.type'
import { AuditStatus } from '../../components/views/audits/enums'

const formatItems = (items: any[]): AuditItem[] =>
   items.map((item) => {
      return {
         ...item,
         Id: item.ToolID,
         ToolLoanId: item.ToolLoanID,
         KitId: item.kitID,
      }
   })

export default (): UseAudit => {
   const [audit, setAudit] = useState<Audit | null>(null)
   const [auditNonDraftQuery, setAuditNonDraftQuery] =
      useState<AuditQuery | null>(null)
   const [auditDraftQuery, setAuditDraftQuery] = useState<AuditQuery | null>(
      null
   )
   const [itemsType, setItemsType] = useState<AuditItemsType>('confirmed')

   const request = useAPI()

   useEffect(() => {
      if (audit) {
         mutate()
      }
   }, [audit])

   const { isLoading, isSuccess, mutate } = useMutation(postAuditToAPI)

   async function postAuditToAPI(): Promise<AxiosResponse> {
      return request.post(PATH.AUDITS.CREATE, audit)
   }

   const create = (auditObj: Audit) => {
      setAudit(auditObj)
   }

   const getAuditRecord = (
      auditQueryObj: AuditQuery,
      auditStatus: number,
      typeOfItems?: AuditItemsType
   ) => {
      if (auditStatus === AuditStatus.DRAFT) {
         setAuditDraftQuery({ ...auditQueryObj, skip: null, take: null }) // send null skip and take if draft so pagination can be performed on the front end
         setAuditNonDraftQuery(null)
      } else if (auditStatus !== AuditStatus.DRAFT) {
         setAuditNonDraftQuery(auditQueryObj)
         setItemsType(typeOfItems)
         setAuditDraftQuery(null)
      } else {
         setAuditDraftQuery(null)
         setAuditNonDraftQuery(null)
      }
   }

   // get draft details
   const {
      data: draftDetails,
      isLoading: isLoadingDraftDetails,
      refetch: refetchDraftDetails,
      isRefetching: isRefetchingDraft,
   } = useQuery('audit-draft-details', getDraftDetailsFromAPI, {
      enabled: !!auditDraftQuery,
   })

   async function getDraftDetailsFromAPI(): Promise<
      AxiosResponse<AuditRecord>
   > {
      try {
         return await request.post(PATH.AUDITS.DRAFT_DETAILS, auditDraftQuery)
      } catch (error) {
         console.log(error)
         return null
      }
   }

   // get draft items
   const {
      data: draftItems,
      refetch: refetchDraftItems,
      isRefetching: isRefetchingDraftItems,
   } = useQuery(
      'audit-draft-items',
      () => getDraftItemsFromAPI(auditDraftQuery),
      { enabled: !!auditDraftQuery }
   )

   async function getDraftItemsFromAPI(
      auditDraftQuery
   ): Promise<AxiosResponse<DraftAuditItemsList>> {
      try {
         return await request.post(PATH.AUDITS.DRAFT_ITEMS, auditDraftQuery)
      } catch (error) {
         console.log(error)
         return error
      }
   }

   // get any other status audit details (non draft)
   const {
      data: auditNonDraftDetails,
      isLoading: isLoadingAuditDetails,
      refetch: refetchNonDraftDetails,
      isRefetching: isRefetchingNonDraft,
   } = useQuery('audit-details', getNonDraftDetailsFromAPI, {
      enabled: !!auditNonDraftQuery,
   })

   async function getNonDraftDetailsFromAPI(): Promise<
      AxiosResponse<AuditRecord>
   > {
      try {
         return await request.post(PATH.AUDITS.DETAILS, auditNonDraftQuery)
      } catch (error) {
         console.log(error)
         return null
      }
   }

   const {
      data: auditUnconfirmedItems,
      refetch: refetchUnconfirmedItems,
      isRefetching: isRefetchingUnconfirmedItems,
   } = useQuery(
      'audit-unconfirmed-items',
      () => getNonDraftUnconfirmedItemsFromAPI(auditNonDraftQuery),
      { enabled: !!auditNonDraftQuery }
   )

   async function getNonDraftUnconfirmedItemsFromAPI(
      auditNonDraftQuery
   ): Promise<AuditItem[]> {
      try {
         const { data } = await request.post(
            PATH.AUDITS.UNCONFIRMED_ITEMS,
            auditNonDraftQuery
         )
         if (data?.AuditItems) {
            return formatItems(data?.AuditItems)
         }
      } catch (error) {
         console.log(error)
         return []
      }
   }

   const {
      data: auditConfirmedItems,
      refetch: refetchConfirmedItems,
      isRefetching: isRefetchingConfirmedItems,
   } = useQuery(
      'audit-confirmed-items',
      () => getNonDraftConfirmedItemsFromAPI(auditNonDraftQuery),
      { enabled: !!auditNonDraftQuery }
   )

   async function getNonDraftConfirmedItemsFromAPI(
      auditNonDraftQuery
   ): Promise<AuditItem[]> {
      try {
         const { data } = await request.post(
            PATH.AUDITS.CONFIRMED_ITEMS,
            auditNonDraftQuery
         )
         if (data?.AuditItems) {
            return formatItems(data?.AuditItems)
         }
      } catch (error) {
         console.log(error)
         return []
      }
   }

   const {
      data: auditFoundItems,
      refetch: refetchFoundItems,
      isRefetching: isRefetchingFoundItems,
   } = useQuery(
      'audit-found-items',
      () => getNonDraftFoundItemsFromAPI(auditNonDraftQuery),
      { enabled: !!auditNonDraftQuery }
   )

   async function getNonDraftFoundItemsFromAPI(
      auditNonDraftQuery
   ): Promise<AuditItem[]> {
      try {
         const { data } = await request.post(
            PATH.AUDITS.FOUND_ITEMS,
            auditNonDraftQuery
         )
         if (data?.AuditItems) {
            return formatItems(data?.AuditItems)
         }
      } catch (error) {
         console.log(error)
         return []
      }
   }

   useEffect(() => {
      if (auditDraftQuery) {
         refetchDraftItems()
         refetchDraftDetails()
      }
   }, [auditDraftQuery])

   useEffect(() => {
      if (auditNonDraftQuery) {
         switch (itemsType) {
            case 'confirmed':
               refetchConfirmedItems()
               break
            case 'found':
               refetchFoundItems()
               break
            case 'unconfirmed':
            default:
               refetchUnconfirmedItems()
               break
         }
         refetchNonDraftDetails()
      }
      setItemsType('confirmed')
   }, [auditNonDraftQuery])

   // mark audit as reviewed
   const { mutate: markAsReviewed } = useMutation(markAuditAsReviewed)

   async function markAuditAsReviewed(
      AuditId: number | string
   ): Promise<AxiosResponse> {
      try {
         return request.post(`${PATH.AUDITS.MARK_AUDIT_AS_REVIEWED}${AuditId}`)
      } catch (error) {
         console.log(error)
         return null
      }
   }

   const markSelectedAuditAsReviewed = (auditId: number | string) => {
      markAsReviewed(auditId)
   }

   return {
      create,
      isLoading,
      isSuccess,
      auditRecord: auditDraftQuery
         ? draftDetails?.data
         : auditNonDraftQuery
         ? auditNonDraftDetails?.data
         : null,
      isRefetchingAuditRecord:
         isRefetchingDraft || isRefetchingNonDraft ? true : false,
      auditUnconfirmedItemsList: auditNonDraftQuery
         ? auditUnconfirmedItems
         : [],
      auditConfirmedItemsList: auditNonDraftQuery ? auditConfirmedItems : [],
      auditFoundItemsList: auditNonDraftQuery ? auditFoundItems : [],
      draftAuditItemsListAndCount: auditDraftQuery ? draftItems?.data : null,
      getAuditRecord,
      isUpdatingAuditDetails:
         isLoadingAuditDetails || isRefetchingNonDraft ? true : false,
      isUpdatingDraftDetails:
         isLoadingDraftDetails || isRefetchingDraft ? true : false,
      isPaginatingItems:
         isRefetchingConfirmedItems ||
         isRefetchingUnconfirmedItems ||
         isRefetchingFoundItems ||
         isRefetchingDraftItems
            ? true
            : false,
      markSelectedAuditAsReviewed,
      getUnconfirmedItems: getNonDraftUnconfirmedItemsFromAPI,
      getConfirmedItems: getNonDraftConfirmedItemsFromAPI,
      getFoundItems: getNonDraftFoundItemsFromAPI,
   }
}
