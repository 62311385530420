import { AuditRecordDetails } from './../../../hooks/useAudit/useAudit.type'
import { AuditItem } from '../../../hooks/useAudit/useAudit.type'
import { Item, ToolStatus } from '../../../types'
import { Dispatch, SetStateAction } from 'react'
import { Option } from '../form/fields/dropdown/dropdown.type'

export type ItemAuditedListProps = {
   confirmed: Item[] | AuditItem[]
   unconfirmed: Item[] | AuditItem[]
   found: Item[] | AuditItem[]
   onRemoveFromAudit: (item: Item) => void
   selectedAuditFormData: AuditRecordDetails
   unconfirmedTotalCount: number
   confirmedTotalCount: number
   foundTotalCount: number
   itemTypeOptions: Option[]
   categoryOptions: Option[]
   manufacturerOptions: Option[]
   locationOptions: Option[]
   conditionOptions: Option[]
   auditIsLoading: boolean
   handleAddAuditItem: (item: Item) => void
   setItemsWereAddedOrRemoved: Dispatch<SetStateAction<boolean>>
}

export enum ViewName {
   UNCONFIRMED = 1,
   CONFIRMED = 2,
   FOUND = 3,
}

export type CheckoutAction = {
   id: number
   label: string
   status: ToolStatus
   icon: string
}

export type GetItemOptions = {
   Id: number
   ToolLoanId: number
   KitId: number
}

export type AuditNoteDetails = {
   ConfirmationStatus: string | null
   Condition: string | null
   AuditNote: string | null
}

export type AuditNoteModalProps = {
   open: boolean
   handleClose: () => void
   noteDetails: AuditNoteDetails | null
}
