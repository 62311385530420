import React, { memo } from 'react'
import { NumberedSectionProps } from './importItemsModal.type'
import * as styles from './importItemsModal.module.scss'
import { Row, Col } from 'react-grid-system'

const NumberedSection = ({
   num,
   heading,
   description,
   action,
}: NumberedSectionProps) => (
   <Row gutterWidth={20} className={styles.numberedSection}>
      <Col width="auto">
         <div className={styles.yellowCircle}>{num}</div>
      </Col>
      <Col>
         <h3 className={styles.heading}>{heading}</h3>
         <p className={styles.description}>{description}</p>
         {action}
      </Col>
   </Row>
)

export default memo(NumberedSection)
