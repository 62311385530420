import React, { memo } from 'react'
import { ProgressBar as BootstrapProgressBar } from 'react-bootstrap'
import * as styles from './progressBar.module.scss'
import { ProgressBarProps } from './types'

const ProgressBar = ({
   textAbove,
   percentage,
   variant = 'success',
}: ProgressBarProps) => (
   <>
      {textAbove && (
         <p
            className={
               variant === 'danger' ? styles.errorTextAbove : styles.textAbove
            }
         >
            {textAbove}
         </p>
      )}
      <BootstrapProgressBar
         now={percentage}
         variant={variant}
         min={0}
         max={100}
      ></BootstrapProgressBar>
      <div
         className={
            variant === 'danger' ? styles.errorTextBelow : styles.textBelow
         }
      >
         {percentage}% Complete
      </div>
   </>
)

export default memo(ProgressBar)
