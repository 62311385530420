import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import {
   UseActiveLocations,
   UseActiveLocationsOptions,
   LocationsResponse,
} from './useActiveLocations.type'

export default function (
   options?: UseActiveLocationsOptions
): UseActiveLocations {
   const request = useAPI()
   const { data, isLoading, isRefetching } = useQuery(
      options?.cacheIdentifier || 'active-locations',
      getActiveLocationsFromAPI
   )

   async function getActiveLocationsFromAPI(): Promise<
      AxiosResponse<LocationsResponse>
   > {
      const optionsObj = { ...options }
      const params = {
         ...(optionsObj.hasOwnProperty('jobsOnly')
            ? { excludeWarehouses: optionsObj.jobsOnly }
            : {}),
         ...(optionsObj.hasOwnProperty('warehousesOnly')
            ? { warehouseOnly: optionsObj.warehousesOnly }
            : {}),
      }

      try {
         return await request.get(
            `${PATH.LOCATIONS.LIST_ACTIVE}${
               !!optionsObj?.includeActiveFilterOption
                  ? '?includeActiveFilterOption=true'
                  : ''
            }`,
            { params }
         )
      } catch (error) {
         // TODO: handle errors better
         console.log(error)
      }
   }

   return {
      isLoading: isLoading || isRefetching,
      list: data?.data || [],
   }
}
