import React, { memo, useMemo } from 'react'
import Lightbox from 'yet-another-react-lightbox'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'

import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'

const ImageViewer = ({
   open,
   setOpen,
   images,
   startIndex,
}: ImageViewerProps) => {
   const handleClose = () => setOpen(false)

   const zoom = useMemo(
      () => ({
         maxZoomPixelRatio: 8,
         scrollToZoom: true,
      }),
      []
   )

   const plugins = useMemo(
      () =>
         images?.length > 1
            ? [Zoom, Fullscreen, Thumbnails]
            : [Zoom, Fullscreen],
      [images]
   )

   return (
      <Lightbox
         open={open}
         close={handleClose}
         zoom={zoom}
         plugins={plugins}
         slides={images?.length > 0 ? images : []}
         index={startIndex}
      />
   )
}

export default memo(ImageViewer)
