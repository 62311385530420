import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import * as styles from './crudItems.module.scss'
import { Alert, SnackBar } from '../../common'
import { Severity } from '../../../types'
import { formatUsDate, removeMinYear } from '../../../common/utils/functions'
import { useUserItems, useNoteTypes } from '../../../hooks'
import { AddEditNoteModal } from '../../views/notes/addEditNoteModal'
import { Item } from '../../../hooks/useUserItems/useUserItems.type'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'
import { Actions } from '../../../constants/tables'
import { Portal } from 'react-portal'
import { CrudItemsParentView } from '../../../enums/CrudItemsEnums'

interface Props {
   itemId?: string | number
   itemNotesData: any[]
   refetchTableData: () => void
   isNewItemNote: boolean
   setIsNewItemNote: Dispatch<SetStateAction<boolean>>
   itemAction: Actions
   crudItemsParentPageView?: CrudItemsParentView
   toolLoanId?: string | number
}

export const Notes: React.FC<Props> = ({
   itemId,
   toolLoanId,
   itemNotesData,
   refetchTableData,
   isNewItemNote = false,
   setIsNewItemNote,
   itemAction,
   crudItemsParentPageView,
}) => {
   const { list: items } = useUserItems()
   const { list: noteTypes } = useNoteTypes()

   const [isEditNoteModalOpen, setIsEditNoteModalOpen] = useState(false)
   const [selectedNote, setSelectedNote] = useState()
   const [selectedItem, setSelectedItem] = useState<Item>(null)
   const [selectedNoteType, setSelectedNoteType] = useState<Option>(undefined)
   const [updateStatus, setUpdateStatus] = useState({
      IS_UPDATE_SUCCESS: false,
      IS_UPDATE_ERROR: false,
   })

   useEffect(() => {
      if (itemId && !selectedItem?.ID) {
         const item = items.find((item) => item.ID === itemId.toString())
         setSelectedItem(item)
      }
   }) // run on every render

   useEffect(() => {
      if (isNewItemNote) {
         setIsEditNoteModalOpen(true)
      }
   }, [isNewItemNote])

   useEffect(() => {
      if (selectedNote) {
         const noteType = noteTypes.find(
            (noteType) => noteType.Id === selectedNote?.NoteTypeId
         )
         setSelectedNoteType(
            selectedNote?.NoteTypeId && !!noteType
               ? {
                    value: noteType?.Id.toString(),
                    label: noteType?.Title,
                 }
               : undefined
         )
      }
   }, [selectedNote])

   const handleNoteClick = (selectedNoteId: number) => {
      const selectedNote = itemNotesData.find(
         (note) => note.Id === selectedNoteId
      )
      setSelectedNote(selectedNote)
      setIsEditNoteModalOpen(true)
   }

   const getUpdateStatus = (status: any): void => {
      setUpdateStatus(status)
   }

   return (
      <>
         <SnackBar
            message={
               updateStatus?.IS_UPDATE_SUCCESS
                  ? 'Note Updated'
                  : 'Error Updating Note'
            }
            open={
               updateStatus?.IS_UPDATE_SUCCESS || updateStatus?.IS_UPDATE_ERROR
            }
            severity={
               updateStatus?.IS_UPDATE_SUCCESS
                  ? Severity.SUCCESS
                  : Severity.ERROR
            }
            disableAutoClose={false}
         />

         {itemNotesData?.length > 0 &&
            itemNotesData.map((item, _index) => (
               <div
                  onClick={() => handleNoteClick(item.Id)}
                  className={styles.itemNotesContainer}
                  key={item.Id}
               >
                  <div className={styles.note}>
                     <div className={styles.columnOne}>
                        <p>
                           <strong>Sent by: </strong>
                           {item.CreatedByName || '-'}
                        </p>

                        <p>
                           <strong>Note Type: </strong>
                           {item.NoteTypeTitle || '-'}
                        </p>

                        <p>
                           <strong>Due Date: </strong>
                           {item.Completed ? (
                              <span>
                                 {removeMinYear(formatUsDate(item.DueDate))}
                              </span>
                           ) : (
                              <span className={styles.notCompleted}>
                                 {removeMinYear(formatUsDate(item.DueDate))}
                              </span>
                           )}
                        </p>

                        <p>
                           <strong>Completed: </strong>
                           {item.Completed ? 'Yes' : 'No'}
                        </p>

                        <p>
                           <strong>Completed Date: </strong>
                           {removeMinYear(formatUsDate(item.CompletedDate)) ||
                              'N/A'}
                        </p>

                        <p>
                           <strong>Completed By: </strong>
                           {item.CompletedBy || 'N/A'}
                        </p>

                        <p>
                           <strong>Completed Cost: </strong>
                           {item.CompletedCost ? `$${item.CompletedCost}` : '-'}
                        </p>

                        <p>
                           <strong>Notes: </strong>
                           {item.NoteText || 'N/A'}
                        </p>
                     </div>
                  </div>
               </div>
            ))}
         {isEditNoteModalOpen && (
            <Portal>
               <AddEditNoteModal
                  selectedNote={selectedNote}
                  setSelectedNote={setSelectedNote}
                  setIsNewItemNote={setIsNewItemNote}
                  isNewItemNote={isNewItemNote}
                  isEditModalOpen={isEditNoteModalOpen}
                  setIsEditModalOpen={setIsEditNoteModalOpen}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  selectedNoteType={selectedNoteType}
                  setSelectedNoteType={setSelectedNoteType}
                  refetchNotes={refetchTableData}
                  getUpdateStatus={getUpdateStatus}
                  crudItemsParentPageView={crudItemsParentPageView}
                  toolLoanId={toolLoanId}
                  noteTypes={noteTypes}
                  items={items}
               />
            </Portal>
         )}
      </>
   )
}
