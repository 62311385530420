import React, { useContext, useEffect, useState } from 'react'

import { Severity } from '../../../types'
import { Button, Modal, Svg } from '..'
import { isBrowser } from '../../../common/utils/functions'
import {
   GlobalStateContext,
   GlobalDispatchContext,
} from '../../../context/globalContextProvider'
import { setUser } from '../../../common/utils/auth'
import { Paths } from '../../../constants/structure'
import { navigate } from 'gatsby'
import * as styles from './networkAlert.module.scss'

const COMPANY_NAME = process.env.APP_COMPANY
const ENVIRONMENT = process.env.NODE_ENV

export const NetworkAlert: React.FunctionComponent = () => {
   const { globalError } = useContext(GlobalStateContext)
   const dispatch = useContext(GlobalDispatchContext)

   const [errorType, setErrorType] = useState<string>('')

   useEffect(() => {
      if (globalError?.message) {
         const match = globalError?.message?.match(/(\d+)/)
         setErrorType(match[0])
      }
   }, [globalError])

   const handleClose = () => {
      dispatch({
         type: 'SET_GLOBAL_ERROR',
         globalError: { message: null, stack: null },
      })
   }

   const handleLoginResetClose = () => {
      setUser({})
      navigate(Paths.LOGIN)
      handleClose()
   }

   if (!isBrowser() || !globalError?.message) return null

   return (
      <Modal
         maxWidth="600px"
         isModalVisible={true}
         closeModal={errorType === '401' ? handleLoginResetClose : handleClose}
         title="Error"
         zIndex="9999"
      >
         <div className="alert-content">
            <Svg
               id={Severity.ERROR}
               width="60"
               height="70"
               overrideViewBox="0 0 7 6"
            />
            {errorType === '401' ? (
               <p>
                  Dang! Something went wrong. It's us, not you. Let's start
                  over. Click the button below and we'll take you to the sign-in
                  screen.
               </p>
            ) : (
               <p>
                  The {COMPANY_NAME} website was unable to process your last
                  request. The error has been logged and our technical team have
                  been notified and will look into this as soon as possible.
               </p>
            )}

            {ENVIRONMENT === 'development' && (
               <div className={styles.stack}>{globalError?.stack}</div>
            )}

            <Button
               onClick={
                  errorType === '401' ? handleLoginResetClose : handleClose
               }
               variant="primary"
            >
               Close
            </Button>
         </div>
      </Modal>
   )
}
