import React from 'react'
import classNames from 'classnames'

import { Props } from './trialDiagram.type'
import * as styles from './trialDiagram.module.scss'
import Svg from '../svg/svg'

const TrialDiagram = ({ days, users }: Props): JSX.Element => (
   <div className={styles.wrapper}>
      {users && (
         <>
            <div className={styles.item}>
               <span className={styles.icon}>
                  <Svg id="face" width="55" height="55" />
               </span>
               <span className={styles.number}>{users}</span>
               <span className={styles.label}>Users</span>
            </div>
            <div className={classNames(styles.item, styles.itemCenter)}>
               <span className={styles.forLabel}>for</span>
            </div>
         </>
      )}
      <div className={styles.item}>
         <span className={styles.icon}>
            <Svg id="clock" width="55" height="55" />
         </span>
         <span className={styles.number}>{days}</span>
         <span className={styles.label}>Days</span>
      </div>
   </div>
)

export default TrialDiagram
