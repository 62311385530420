import React from 'react'

import { Props } from './mapPin.type'
import * as styles from './mapPin.module.scss'

import { ItemGPSInfoCard } from './../../crudItems/itemGPSView/itemGPSInfoCard/itemGPSInfoCard'
import Svg from '../../svg/svg'

const MapPin: React.FC<Props> = (props) => {
   const {
      color = '#ffca05',
      onClick,
      title,
      svgId = 'mapPin',
      svgWidth,
      displayHighlight,
      infoCardId,
      isInfoCardOpen,
      handleCloseInfoCard,
      selectedLocation,
      reverseGeoLookupResults,
   } = props

   return (
      <>
         <span title={title} className={styles.pin} onClick={onClick}>
            <Svg id={svgId} fill={color} width={svgWidth} />
         </span>
         {isInfoCardOpen && infoCardId === selectedLocation?.ID && (
            <ItemGPSInfoCard
               isInfoCardOpen={isInfoCardOpen}
               handleCloseInfoCard={handleCloseInfoCard}
               selectedLocation={selectedLocation}
               reverseGeoLookUpResults={reverseGeoLookupResults}
            />
         )}
         {displayHighlight && <div className={styles.highlight}></div>}
      </>
   )
}

export default MapPin
