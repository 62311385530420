import { useMutation } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { UseItemImportItems } from './useItemImportItems.type'
import { useEffect, useState } from 'react'

export default function (): UseItemImportItems {
   const request = useAPI()
   const [totalItemsImported, setTotalItemsImported] = useState<number>(0)
   const [totalImagesImported, setTotalImagesImported] = useState<number>(0)

   const {
      data,
      error,
      isLoading: isImportingItems,
      isSuccess: isImportItemsSuccess,
      reset: resetImportItems,
      mutate,
   } = useMutation(importItemsFile)

   async function importItemsFile({
      file,
      addImagesWithAI,
   }: {
      file: File
      addImagesWithAI?: boolean
   }): Promise<AxiosResponse<any>> {
      const formData = new FormData()
      const requestOptions = {
         headers: {
            'Content-Type': 'multipart/form-data',
         },
         params: {
            RequiredImageUpload: addImagesWithAI,
         },
      }
      formData.append('file', file)
      return await request.post(
         PATH.ITEMS.IMPORT_ITEMS,
         formData,
         requestOptions
      )
   }

   const importItems = async (file: File, addImagesWithAI?: boolean) =>
      await mutate({ file, addImagesWithAI })

   useEffect(() => {
      if (data) {
         const itemCount = Number(data.data?.Result[0]) ?? 0
         const imageCount = data.data?.ImageUploadedCount ?? 0
         setTotalItemsImported(itemCount)
         setTotalImagesImported(imageCount)
      }
   }, [data])

   return {
      importError: error?.response?.data?.Message as string,
      importFileErrors: error?.response.data?.ModelState?.file as
         | string[]
         | null,
      importItems,
      isImportingItems,
      isImportItemsSuccess,
      resetImportItems,
      totalItemsImported,
      totalImagesImported,
   }
}
