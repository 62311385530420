import { useState } from 'react';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI } from '..';

import { UseAuditors, AuditorsAPIResponse, AuditorAvailabilityResponse } from './useAuditors.type';

export default () => {
    const [auditedBy, setAuditedBy] = useState<string | null>(null);

    const request = useAPI();
    const { data, isLoading } = useQuery('auditors', getAuditorsFromAPI);
    const { data: availabilityData } = useQuery(['auditor-availability', auditedBy], () => getAuditorAvailabilityFromAPI(auditedBy), {
        enabled: !!auditedBy
    })

    async function getAuditorsFromAPI(): Promise<AxiosResponse<AuditorsAPIResponse>> {
        return request.get(PATH.AUDITORS.LIST);
    }

    async function getAuditorAvailabilityFromAPI(auditorId: string): Promise<AxiosResponse<AuditorAvailabilityResponse>> {
        return request.get(PATH.AUDITORS.VALIDATE, {
            params: { auditedBy: auditorId }
        });
    }

    const validateAuditorAvailability = async (auditorId: string): Promise<void> => {
        setAuditedBy(auditorId);
    }

    return {
        auditorAvailabilityStatus: !!availabilityData ? {
            available: availabilityData?.data?.IsValid,
            message: availabilityData?.data?.ErrorMessage || '',
        } : null,
        isLoading,
        list: data?.data || [],
        validateAuditorAvailability,
    }
}