import React from 'react';
import classNames from 'classnames';
import { GrClose } from 'react-icons/gr';

import { Props } from './slidePanel.type';
import * as styles from './slidePanel.module.scss';

const SlidePanel: React.FC<Props> = props => {
    const {
        children,
        isOpen = false,
        onClose,
    } = props;

    const classes = classNames(
        styles.wrapper,
        {
            [styles.wrapperOpen]: !!isOpen,
        }
    )

    return (
        <div className={classes}>
            <button title='Close filters' onClick={onClose} className={styles.close}>
                <GrClose />
            </button>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};

export default SlidePanel;