import { useState } from 'react'
import { useMutation, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI } from '..';

import { UseItemKit } from './useItemKit.type';


export default function(): UseItemKit {
    const request = useAPI();


    const { error, isLoading: isLoadingKits, isSuccess: isSuccessKits, mutate } = useMutation(getItemKits);

    const { error: createError, isError: isErrorCreate, isLoading: isCreating, isSuccess: isSuccessCreate, mutate: mutateCreate, reset: resetCreate } = useMutation(addKitAPI, {
        onSettled: async () => {

        },
    });

    const { isError: isErrorDeleteKit, isLoading: isDeletingKit, isSuccess: isSuccessDeleteKit, mutate: mutateDelete } = useMutation(deleteKitAPI, {
        onSettled: async () => {

        },
    });


    async function addKitAPI(obj: any): Promise<AxiosResponse> {
        return request.post(PATH.ITEMS.ADD_TO_KIT, obj);
    }

    async function deleteKitAPI(obj: any): Promise<AxiosResponse> {
        return request.post(PATH.ITEMS.REMOVE_FROM_KIT, obj);
    }

    const [ result, setResult ] = useState<any>([])

    async function getItemKits(obj: {}): Promise<AxiosResponse<any>> {
       const res = await request.post(
           PATH.ITEMS.GET_KITS,
           obj
        );
        setResult(res)
       return res
    }

    const getKits = async (obj: {}) => {
        await mutate(obj);
        // refetch();
    }

    const addKit = async (obj: any) => {
        mutateCreate(obj);
    }

    const deleteKit = async (obj: any) => {
        mutateDelete(obj);
    }


    return {
        error: error?.response?.data?.Message as string,
        getKits,
        addKit,
        deleteKit,
        isLoadingKits,
        isSuccessKits,
        createError,
        isErrorCreate,
        isCreating,
        isSuccessCreate,
        isErrorDeleteKit,
        isDeletingKit,
        isSuccessDeleteKit,
        result
    };
};