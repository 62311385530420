import React from 'react'

import * as styles from './locationKeyValue.module.scss'
import Svg from '../../svg/svg'

interface Props {
   label: string
   value: string
   svgId?: string
   svgFill?: string
   svgWidth?: number
   svgHeight?: number
   onClick?: () => any
}

const LocationKeyValue: React.FC<Props> = ({
   label,
   value,
   svgId,
   svgFill,
   svgWidth,
   svgHeight,
   onClick,
}) => {
   return (
      <div className={styles.locationKeyValue} onClick={onClick}>
         <span className={styles.labelWrapper}>
            {svgId && (
               <div className={styles.svgWrapper}>
                  <Svg
                     id={svgId}
                     width={svgWidth}
                     height={svgHeight}
                     fill={svgFill}
                  />
               </div>
            )}
            <span className={styles.label}>{label}</span>
         </span>
         <span className={styles.value}>{value ?? 'N/A'}</span>
      </div>
   )
}

export default LocationKeyValue
