import React from 'react'

import { Props } from './tag.type'
import * as styles from './tag.module.scss'
import Svg from '../svg/svg'

const Tag: React.FC<Props> = ({ label, onClick, title }) => {
   return (
      <span className={styles.wrapper}>
         <span className={styles.label} title={title}>
            {label}
         </span>
         {onClick && (
            <button className={styles.button} onClick={onClick}>
               <span className={styles.icon}>
                  <Svg id="close" width={36} height={36} />
               </span>
            </button>
         )}
      </span>
   )
}

export default Tag
