import React, { useEffect } from 'react'
import IconButton  from '../button/IconButton'
import * as styles from './pagination.module.scss'
import { PaginationPagesProps } from './pagination.types'
import { PAGINATION } from '../../../constants/tables'

export const PaginationPages: React.FC<PaginationPagesProps> = ({
    currentPage,
    nextAllowed,
    isPreviousEllipsis,
    isNextEllipsis,
    previousAllowed,
    pageGroup,
    pagesAvailable,
    pageButtonArray,
    handleGotoPage,
    handleNextPage,
    handlePreviousPage,
    totalCount,
    handleButtonArray,
    count
}) => {

    useEffect(() => {
        handleButtonArray(1, totalCount)
    }, [count])

    return (
        <div className={styles.paginationPages}>
            <IconButton
                id='fast-forward'
                action={ event => {
                    event.preventDefault();
                    return handleGotoPage(1)
                }}
                disabled={!previousAllowed}
                flipHorizontal={true}
            />
            <IconButton
                id='forward'
                action={ event => {
                    event.preventDefault();
                    return handlePreviousPage()
                } }
                disabled={!previousAllowed}
                flipHorizontal={true}
            />
            {isPreviousEllipsis() &&
                <IconButton
                    id='ellipsis'
                    action={ event => {
                        event.preventDefault();
                        return handleGotoPage(pageGroup - PAGINATION.pageSized)
                    } }
                    disabled={!previousAllowed}
                />
            }

            <span className={styles.paginationNumbers}>
                {
                    pageButtonArray.map((item, index) => {
                        const isActive = item === currentPage ? `${styles.pageButtonActive}` : '';
                        return <button
                            key={`page-${index}`}
                            className={`${styles.pageButton} ${isActive}`}
                            onClick={event => {
                                event.preventDefault();
                                return handleGotoPage( item );
                            } }>
                            { item }
                        </button>
                    })
                }
            </span>

            <span className={styles.pageSize}>
                <select
                    value={currentPage}
                    className={styles.paginationSelector}
                    onChange={el => {
                        handleGotoPage(Number(el.target.value))
                    }}
                >
                    {pageButtonArray.map((page, index) => (
                        <option key={index} value={page}>
                            { page }
                        </option>
                    ))}
                </select>
            </span>

            {isNextEllipsis() &&
                <IconButton
                    id='ellipsis'
                    action={ event => {
                        event.preventDefault();
                        return handleGotoPage(pageGroup + PAGINATION.pageSized)
                    } }
                    disabled={!nextAllowed}
                />
            }
            <IconButton
                id='forward'
                action={ event => {
                    event.preventDefault();
                    return handleNextPage()
                } }
                disabled={!nextAllowed}
            />
            <IconButton
                id='fast-forward'
                action={ event => {
                    event.preventDefault();
                    return handleGotoPage(pagesAvailable)
                } }
                disabled={!nextAllowed}
            />
        </div>
    )
}
