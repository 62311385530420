import React from 'react'
import { Col, Row } from 'react-grid-system'

import { Footer } from '../../../common/footer'
import { Checklist, TrialDiagram, Logo } from '../../../common'

import { RegisterFlowProps } from './types'
import * as styles from './registerFlow.module.scss'

const COMPANY_NAME = process.env.APP_COMPANY

export const RegisterIntro = ({ children }: RegisterFlowProps) => (
   <div className={styles.intro}>
      <p>{children}</p>
   </div>
)

const RegisterFlow = ({ children, fullWidth, title }: RegisterFlowProps) => {
   const listItems: string[] = [
      'Tool tracking',
      'Unlimited hosting',
      'Unlimited phone support',
      'Unlimited e-mail and chat support',
      'Connect the web interface to Apple & Android apps',
      'Full barcode functionality',
   ]

   const rowProps = { gutterWidth: 30 }
   const colProps = { xs: 12, md: 6 }

   if (fullWidth) {
      return (
         <div className={styles.wrapper}>
            <header className={styles.header}>
               <Logo width="130px" />
            </header>
            <main className={styles.content}>{children}</main>
            <footer className={styles.footer}>
               <Footer />
            </footer>
         </div>
      )
   }

   return (
      <div className={styles.wrapper}>
         <header className={styles.header}>
            <Logo width="130px" />
         </header>
         <main className={styles.content}>
            <Row {...rowProps}>
               <Col {...colProps}>
                  <h1 className={styles.heading}>Register</h1>
               </Col>
               <Col {...colProps}>
                  <h2 className={styles.heading}>{title}</h2>
               </Col>
            </Row>
            <Row {...rowProps} direction="row-reverse">
               <Col {...colProps} className={styles.column}>
                  {children}
               </Col>
               <Col {...colProps} className={styles.column}>
                  <p className={styles.trialIntro}>
                     You will have{' '}
                     <strong>full access to {COMPANY_NAME} for:</strong>
                  </p>
                  <TrialDiagram days={14} />
                  <Checklist
                     intro={
                        <>
                           Including <strong>all of the following</strong>:
                        </>
                     }
                     items={listItems}
                  />
               </Col>
            </Row>
         </main>
         <footer className={styles.footer}>
            <Footer />
         </footer>
      </div>
   )
}

export default RegisterFlow
