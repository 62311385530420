const permissions = {
   AssignedToKitName: 'KitEnabled',
   Quantity: 'QuantityEnabled',
   StatusQuantity: 'QuantityEnabled',
   CustomRentalRate1: 'CustomRentalRate1Enabled',
   CustomRentalRate2: 'CustomRentalRate2Enabled',
   CustomRentalRate3: 'CustomRentalRate3Enabled',
   CustomRentalRate4: 'CustomRentalRate4Enabled',
   CustomText1Value: 'CustomText1Enabled',
   CustomText2Value: 'CustomText2Enabled',
   CustomDate1Value: 'CustomDate1Enabled',
   CustomDate2Value: 'CustomDate2Enabled',
   CustomURL1Value: 'CustomURL1Enabled',
   CustomURL2Value: 'CustomURL2Enabled',
   DefaultLocation: 'EnableLocationTracking',
   CurrentLocation: 'EnableLocationTracking',
   LastAuditDate: 'AuditEnabled',
   LastAuditConditionDesc: 'AuditEnabled',
}

const biColumnsStyle = {
   marginRight: '0.5rem',
   marginTop: '-0.5rem',
}

export { permissions, biColumnsStyle }
