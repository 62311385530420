import React, { memo } from 'react'

import * as styles from './importItemsModal.module.scss'
import {
   ImportOptionsViewProps,
   NumberedSectionProps,
} from './importItemsModal.type'
import NumberedSection from './numberedSection'
import { DownloadButton, UploadButton } from './actions'

const ImportOptionsView = ({
   selectedFile,
   setSelectedFile,
   addImagesWithAI,
   setAddImagesWithAI,
}: ImportOptionsViewProps) => {
   const sections: NumberedSectionProps[] = [
      {
         num: 1,
         heading: 'Get the Import Template',
         description:
            'To ensure proper import to your database, first download the Import Template, then populate with your Items before importing.',
         action: <DownloadButton />,
      },
      {
         num: 2,
         heading: 'Upload Your Items',
         description:
            'After using the Import Template to fill in your Items, select the file from your computer to upload.',
         action: (
            <UploadButton
               selectedFile={selectedFile}
               setSelectedFile={setSelectedFile}
            />
         ),
      },
      // {
      //    num: 3,
      //    heading: (
      //       <>
      //          Add Images with AI{' '}
      //          <StaticImage
      //             src={'../../../assets/images/importItems/ai-icon.png'}
      //             className={styles.aiIcon}
      //             formats={['png']}
      //             alt={'Excel template'}
      //          />
      //       </>
      //    ),
      //    description: (
      //       <>
      //          If you <strong>include a manufacturer and model number,</strong>{' '}
      //          our Toolbox AI will search the web for an image to add to those
      //          Items.
      //       </>
      //    ),
      //    action: (
      //       <AddWithAIToggle
      //          addImagesWithAI={addImagesWithAI}
      //          setAddImagesWithAI={setAddImagesWithAI}
      //       />
      //    ),
      // },
   ]
   return (
      <div className={styles.leftColumn}>
         {sections.map((section) => (
            <NumberedSection
               key={section.num}
               num={section.num}
               heading={section.heading}
               description={section.description}
               action={section.action}
            />
         ))}
      </div>
   )
}

export default memo(ImportOptionsView)
