import { useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

interface UseRecaptcha {
  ReCAPTCHA?: ({ ref, size, sitekey, onChange }) => JSX.Element,
  sitekey: string,
  size: string,
  recaptchaRef: React.MutableRefObject<any>,
  disableRecaptcha: boolean,
  
}

export default function (): UseRecaptcha {
  const recaptchaRef = useRef(void 0)
  const ReCAPTCHA_KEY = process.env.GRECAPTCHA_API_KEY

  const disableRecaptcha = process.env.DISABLE_RECAPTCHA === 'true' ? true : false

  return {
    ReCAPTCHA,
    sitekey: ReCAPTCHA_KEY,
    size: 'normal',
    recaptchaRef: recaptchaRef,
    disableRecaptcha,
  }
}
