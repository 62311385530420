import React, { ChangeEvent, MouseEvent, useRef } from 'react'
import * as styles from './actions.module.scss'
import {
   DownloadButtonProps,
   HelpButtonProps,
   UploadButtonProps,
} from './types'
import { BsCloudArrowUp as ImportIcon } from 'react-icons/bs'
import Svg from '../svg/svg'

export const DownloadButton = ({ variant }: DownloadButtonProps) => (
   <a
      href={`/${variant}ImportTemplate.csv`}
      download={`${variant}ImportTemplate`}
      className={styles.linkButton}
   >
      <Svg
         id="download"
         width="20"
         height="20"
         overrideViewBox="0 0 8 8"
         fill="#003e64"
      />
      <span>Download Template</span>
   </a>
)

export const UploadButton = ({
   selectedFile,
   setSelectedFile,
}: UploadButtonProps) => {
   const fileInputRef = useRef(null)

   const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      fileInputRef.current.click()
   }

   const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0]
      setSelectedFile(file)
   }
   return (
      <div className={styles.uploadButtonWrapper}>
         <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            multiple={false}
            className={styles.hiddenFileInput}
         />
         <button
            onClick={handleButtonClick}
            type="button"
            className={styles.uploadButton}
         >
            <ImportIcon size={20} />
            <span>Select File</span>
         </button>
         {selectedFile && <span>{selectedFile.name}</span>}
      </div>
   )
}

export const HelpButton = ({ path }: HelpButtonProps) => (
   <a
      href={`https://help.sharemytoolbox.com/knowledge/${path}`}
      target="_blank"
      className={styles.helpButton}
   >
      <Svg id="help" fill="#2A4E8D" width={17} height={17} />{' '}
      <span>Get Help</span>
   </a>
)
