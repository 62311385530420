import React, { memo } from 'react'
import { Modal } from '../modal'
import { AuditNoteModalProps } from './itemAuditedList.type'
import Button from '../button/button'
import * as styles from './itemAuditList.module.scss'
import { Col, Row } from 'react-grid-system'
import { Input, Textarea } from '../form/fields'

const AuditNoteModal = ({
   open,
   handleClose,
   noteDetails,
}: AuditNoteModalProps) => (
   <Modal isModalVisible={open} closeModal={handleClose} title="Audit Note">
      <div className={styles.modalContent}>
         <Row>
            <Col xs={12} sm={6}>
               <Input
                  className={styles.input}
                  id="ConfirmationStatus"
                  label="Confirmation Status"
                  value={noteDetails?.ConfirmationStatus}
                  isReadonly
               />
            </Col>
            <Col xs={12} sm={6}>
               <Input
                  className={styles.input}
                  id="Condition"
                  label="Condition"
                  value={noteDetails?.Condition}
                  isReadonly
               />
            </Col>
         </Row>
         <Row>
            <Col xs={12}>
               <Textarea
                  className={styles.input}
                  height={100}
                  id="AuditNote"
                  label="Audit Note"
                  value={noteDetails?.AuditNote}
                  isReadonly
               />
            </Col>
         </Row>
         <Button minWidth="100%" variant="tertiary" onClick={handleClose}>
            Close
         </Button>
      </div>
   </Modal>
)

export default memo(AuditNoteModal)
