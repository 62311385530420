import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'
import * as styles from './navItem.module.scss'
import Svg from '../../svg/svg'

export const FailureLogout = () => {
   return (
      <li className={classNames(styles.navItem, styles.navItemPrimary)}>
         <Link className={styles.navItemLink} to={'/logout/'}>
            <span className={styles.navItemIcon}>
               <Svg id={'logout'} height={26} />
            </span>
            {'Sign Out'}
         </Link>
      </li>
   )
}
