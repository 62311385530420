import { StaticImage } from 'gatsby-plugin-image'
import React, { memo } from 'react'
import { ProgressBar } from '../progressBar'
import Button from '../button/button'
import * as styles from './imageMatchModal.module.scss'
import { ProgressViewProps } from './types'

const ProgressView = ({ percentage, handleClose }: ProgressViewProps) => (
   <div className={styles.imageMatchContent}>
      <h1>Performing Image Match</h1>
      <div className={styles.headerImage}>
         <StaticImage
            src="../../../assets/images/importItems/ai-icon-large.png"
            formats={['png']}
            alt="Match in progress"
         />
      </div>
      <div className={styles.progressBarContainer}>
         <ProgressBar
            textAbove="Sending the robots to work..."
            percentage={percentage}
            variant="success"
         />
      </div>
      <div className={styles.actionButtonsContainer}>
         <Button variant="tertiary" onClick={handleClose}>
            Cancel
         </Button>
      </div>
   </div>
)

export default memo(ProgressView)
