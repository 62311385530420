import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI } from '..';

import { UseNoteTypes, NoteTypesResponse } from './useNoteTypes.type';


export default function(): UseNoteTypes {
    const request = useAPI();
    const { data, isLoading } = useQuery('note-types', getNoteTypesFromAPI);

    async function getNoteTypesFromAPI(): Promise<AxiosResponse<NoteTypesResponse>> {
        try {
            return await request.get(PATH.NOTE_TYPES.LIST);
        } catch (error) {
            // TODO: handle errors better
            console.log(error);
        }
    }

    return {
        isLoading,
        list: data?.data?.Items || [],
    };
};