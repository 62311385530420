import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Modal } from '../../../common';
import KitItemSelector  from '../kitItemSelector';


interface Props {
    isModalOpen: boolean;
    handleClose: () => void;
    maxWidth?: string;
    zIndex?: string;
    kitId?: number;
    itemForm: {}
}

export const ManageKitModal: React.FunctionComponent<Props> = ({
    isModalOpen,
    handleClose,
    maxWidth,
    zIndex,
    kitId,
    itemForm
}) => { 

return (
    <Modal
        isModalVisible={isModalOpen}
        closeModal={handleClose}
        title={'Manage Kit'}
        maxWidth={maxWidth}
        zIndex={zIndex}
    >
      
        <Row gutterWidth={20}>
            <Col xs={12} lg={12}>
                <KitItemSelector 
                    kitId={kitId} 
                    itemForm={itemForm} />
            </Col>
            
        </Row>
       
    </Modal>
)

}