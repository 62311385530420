import React, { useEffect, useState } from 'react'


import Map from '../../../../../components/common/map/map'
import { Pin } from '../../../../../components/common/map/map'

import * as styles from './itemGPSMap.module.scss'
import { GpsLocation } from '../../../../../hooks/useGpsBluetooth/useGpsBluetooth.type'
import NoResults from '../../../noResults/noResults'
import Alert from '../../../alert/alert'
import { Severity } from '../../../../../types'

interface Props {
   selectedItem: unknown
   gps_bt_locationsList: GpsLocation[]
   isLoadingLocationsList: boolean
   selectedLocation: GpsLocation
   selectedLocationToCurrentLocation: () => void
   selectedLocationToScanLocation: (location: GpsLocation) => void
   isInfoCardOpen: boolean,
   handleCloseInfoCard: () => void
   handleOpenInfoCard: () => void
   setIsMapZooming: React.Dispatch<React.SetStateAction<boolean>>
}

const ItemGPSMap: React.FC<Props> = ({
   selectedItem,
   gps_bt_locationsList,
   isLoadingLocationsList,
   selectedLocation,
   selectedLocationToCurrentLocation,
   selectedLocationToScanLocation,
   isInfoCardOpen,
   handleCloseInfoCard,
   handleOpenInfoCard,
   setIsMapZooming,
}) => {

   // const test = [
   //    {
   //       ID: 46,
   //       ToolID: 565447,
   //       CreatedById: '54d68457-0278-40bb-9ed5-8955cc5f77e4',
   //       Event: 0,
   //       Latitude: 54.124416,
   //       Longitude: -1.6045650125,
   //       CreatedByName: 'Shout Digital',
   //       CreatedOn: '2023-04-27T11:06:11.527',
   //    },
   //    {
   //       ID: 45,
   //       ToolID: 565447,
   //       CreatedById: '54d68457-0278-40bb-9ed5-8955cc5f77e4',
   //       Event: 0,
   //       Latitude: 53.4328503,
   //       Longitude: -2.9623865,
   //       CreatedByName: 'Shout Digital',
   //       CreatedOn: '2023-04-27T10:35:36.85',
   //    },
   //    {
   //       ID: 37,
   //       ToolID: 565447,
   //       CreatedById: '54d68457-0278-40bb-9ed5-8955cc5f77e4',
   //       Event: 0,
   //       Latitude: 51.5281534,
   //       Longitude: -0.0902569,
   //       CreatedByName: 'Shout Digital',
   //       CreatedOn: '2023-04-27T09:11:55.707',
   //    },
   //    {
   //       ID: 36,
   //       ToolID: 565447,
   //       CreatedById: '54d68457-0278-40bb-9ed5-8955cc5f77e4',
   //       Event: 0,
   //       Latitude: 53.4625761,
   //       Longitude: -2.2910317,
   //       CreatedByName: 'Shout Digital',
   //       CreatedOn: '2023-04-27T09:11:49.863',
   //    },
   //    {
   //       ID: 35,
   //       ToolID: 565447,
   //       CreatedById: '54d68457-0278-40bb-9ed5-8955cc5f77e4',
   //       Event: 0,
   //       Latitude: 54.9713085,
   //       Longitude: -1.6029093,
   //       CreatedByName: 'Shout Digital',
   //       CreatedOn: '2023-04-27T09:11:43.067',
   //    },
   //    {
   //       ID: 34,
   //       ToolID: 565447,
   //       CreatedById: '54d68457-0278-40bb-9ed5-8955cc5f77e4',
   //       Event: 0,
   //       Latitude: 53.3976041,
   //       Longitude: -2.9436643,
   //       CreatedByName: 'Shout Digital',
   //       CreatedOn: '2023-04-27T09:11:38.127',
   //    },
   //    {
   //       ID: 33,
   //       ToolID: 565447,
   //       CreatedById: '54d68457-0278-40bb-9ed5-8955cc5f77e4',
   //       Event: 0,
   //       Latitude: 54.969345716,
   //       Longitude: -1.6034540125,
   //       CreatedByName: 'Shout Digital',
   //       CreatedOn: '2023-04-27T09:11:31.9',
   //    },
   //    {
   //       ID: 32,
   //       ToolID: 565447,
   //       CreatedById: '54d68457-0278-40bb-9ed5-8955cc5f77e4',
   //       Event: 0,
   //       Latitude: 54.969340416,
   //       Longitude: -1.6036554725,
   //       CreatedByName: 'Shout Digital',
   //       CreatedOn: '2023-04-27T09:11:02.393',
   //    },
   //    {
   //       ID: 31,
   //       ToolID: 565447,
   //       CreatedById: '54d68457-0278-40bb-9ed5-8955cc5f77e4',
   //       Event: 0,
   //       Latitude: 54.969340416,
   //       Longitude: -1.603650145,
   //       CreatedByName: 'Shout Digital',
   //       CreatedOn: '2023-04-27T09:10:58.393',
   //    },
   // ]

   const [hasAssignedLocation, setHasAssignedLocation] =
      useState<boolean>(false);

   const [totalMarkers, setTotalMarkers] = useState<
      { lat: number; lng: number }[]
   >([]);

   useEffect(() => {
      const scanAndPingMarkers = gps_bt_locationsList.map(({ Latitude, Longitude }) => ({
         lat: Latitude,
         lng: Longitude,
      }))

      if (selectedItem?.Latitude && selectedItem?.Longitude) {
         const assignedLocation = {
            lat: selectedItem?.Latitude,
            lng: selectedItem?.Longitude,
         }
         //Add assigned to markers if one exists with lat, lng
         setTotalMarkers([assignedLocation, ...scanAndPingMarkers])
         setHasAssignedLocation(true)
         
      } else {
         setTotalMarkers([...scanAndPingMarkers])
         
      }
   }, [selectedItem, gps_bt_locationsList])

   const [reverseGeoLookUpResults, setReverseGeoLookupResults] =
      useState<string>('');

   return (
      <>
    
        <div className={styles.mapWrapper}>
         {!isLoadingLocationsList && !totalMarkers.length &&
         <Alert message={"No results have been found."} severity={Severity.INFO} /> 
        }
         {!isLoadingLocationsList && totalMarkers.length > 0 &&(
         <Map
            markers={totalMarkers}
            selectedLocation={selectedLocation}
            handleCloseInfoCard={handleCloseInfoCard}
            handleOpenInfoCard={handleOpenInfoCard}
            isInfoCardOpen={isInfoCardOpen}
            fitToBounds={false}
            retrieveGeoLookupResults={setReverseGeoLookupResults}
            setIsMapZooming={setIsMapZooming}
         >
            {hasAssignedLocation && (
               <Pin
                  color={'#FFCA05'}
                  key={selectedItem.localUniqueId}
                  lat={selectedItem.Latitude}
                  lng={selectedItem.Longitude}
                  title={selectedItem?.CurrentLocation}
                  svgId={'pinNoShadow'}
                  svgWidth={16}
                  displayHighlight={true}
                  onClick={selectedLocationToCurrentLocation}
                  infoCardId={selectedItem.localUniqueId}
                  isInfoCardOpen={isInfoCardOpen}
                  handleCloseInfoCard={handleCloseInfoCard}
                  selectedLocation={selectedLocation}
                  reverseGeoLookupResults={reverseGeoLookUpResults}
               />
            )}
            {gps_bt_locationsList.map((location, index) => (
               <Pin
                  color={null}
                  key={location?.ID}
                  lat={location?.Latitude}
                  lng={location?.Longitude}
                  title={location?.CreatedByName}
                  svgId={'gps'}
                  displayHighlight={false}
                  onClick={() => selectedLocationToScanLocation(location)}
                  infoCardId={location?.ID}
                  isInfoCardOpen={isInfoCardOpen}
                  handleCloseInfoCard={handleCloseInfoCard}
                  selectedLocation={selectedLocation}
                  reverseGeoLookupResults={reverseGeoLookUpResults}
               />
            ))}
         </Map>
         )}
        </div>
         
      </>
   )
}

export default ItemGPSMap
