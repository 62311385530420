import React from 'react'
import { CsvInnerProps } from './csvDownload.types'
import * as styles from './csvDownloadForm.module.scss'
import Svg from '../svg/svg'

export const CsvInnerButton: React.FunctionComponent<CsvInnerProps> = ({
   height,
   id,
   overrideViewBox = null,
   text,
   width,
}) => (
   <div className={styles.innerButton}>
      <Svg
         id={id}
         height={height}
         width={width}
         overrideViewBox={overrideViewBox}
      />
      <span>{text}</span>
   </div>
)
