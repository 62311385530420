// extracted by mini-css-extract-plugin
export var aiIcon = "importModal-module--aiIcon--PLFoQ";
export var bottomImage = "importModal-module--bottomImage--8zqos";
export var bottomSection = "importModal-module--bottomSection--AlgTw";
export var buttonsWrapper = "importModal-module--buttonsWrapper--04zkv";
export var closeWindowInfo = "importModal-module--closeWindowInfo--olDE6";
export var column = "importModal-module--column--t7Y1P";
export var content = "importModal-module--content--lBJdN";
export var contentWrapper = "importModal-module--contentWrapper--VOxpn";
export var description = "importModal-module--description--Cpr7E";
export var errorContent = "importModal-module--errorContent--vF-DA";
export var greenTriangle = "importModal-module--greenTriangle--NOmJp";
export var heading = "importModal-module--heading--D6FDq";
export var helpLinkWrapper = "importModal-module--helpLinkWrapper--ughqX";
export var hideButton = "importModal-module--hideButton--IFcTC";
export var importResults = "importModal-module--importResults--03uDk";
export var leftColumn = "importModal-module--leftColumn--Ir0Ye";
export var middleImage = "importModal-module--middleImage--DqGVp";
export var numberedSection = "importModal-module--numberedSection--4FKXf";
export var progressIndicatorWrapperCenter = "importModal-module--progressIndicatorWrapperCenter--aUzfZ";
export var progressIndicatorWrapperLeft = "importModal-module--progressIndicatorWrapperLeft--AKBmO";
export var rightColumn = "importModal-module--rightColumn--Pi2uC";
export var topImage = "importModal-module--topImage---h3ek";
export var yellowCircle = "importModal-module--yellowCircle--MZcea";