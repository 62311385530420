import { useContext } from 'react'

import {
   initialFormState,
   RegisterContext,
} from '../../context/registerContext'

import { UseRegister } from './useRegister.type'
import { FormState } from '../../context/registerContext/registerContext.type'

export default function (): UseRegister {
   const { formState, save } = useContext(RegisterContext)

   const reset = () => save(initialFormState)

   const update = (values: FormState) => save({ ...formState, ...values })

   return {
      formState,
      reset,
      update,
   }
}
