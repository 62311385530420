import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import * as styles from './progressIndicator.module.scss'

interface props {
   totalViews: number
   currentView: number
}

export const ProgressIndicator: React.FunctionComponent<props> = ({
   totalViews,
   currentView,
}) => {
      const [navigatedViews, setNavigatedViews] = useState<number[]>([]);

      useEffect(() => {
         if (!navigatedViews.includes(currentView)) {
            setNavigatedViews([...navigatedViews, currentView])
         }
      }, [currentView]);

   return (
      <ul className={styles.progressIndicator}>
         {Array(totalViews)
            .fill('')
            .map((x, i) => {
               const isActive = currentView === i;
               return (
                  <li
                     className={classNames(styles.progressStep, {
                        [styles.progressStepVisited]: navigatedViews.includes(i),
                        [styles.progressStepActive]: isActive,
                     })}
                     key={`indicator_${i}`}
                     value={i}
                  ></li>
               )
            })}
      </ul>
   )
}
