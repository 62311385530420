import React, { useMemo } from 'react';

import * as styles from './pagination.module.scss';
import { PaginationTotalProps } from './pagination.types';

type PaginationTotals = {
    from: number;
    to: number;
}

export const PaginationTotal: React.FC<PaginationTotalProps> = ({
    currentPage,
    itemsPerPage,
    pagesAvailable,
    totalCount,
}) => {
    const fromTo: PaginationTotals = useMemo(() => {
        return {
            from: (currentPage - 1) * itemsPerPage + 1,
            to: currentPage === pagesAvailable ? totalCount : currentPage * itemsPerPage,
        }
    }, [currentPage, itemsPerPage, totalCount, pagesAvailable]);

    return (
        <div className={styles.paginationTotal}>
            {fromTo.from} - {itemsPerPage === 999999 ? totalCount : fromTo.to} of {totalCount} { totalCount === 1 ? 'item' : 'items' }
        </div>
    )
}
