import React from 'react'
import classNames from 'classnames'

import { Severity } from '../../../types'
import * as styles from './alert.module.scss'
import Svg from '../svg/svg'

interface Props {
   message: string
   severity?: Severity
   action?: () => void
}

export const Alert: React.FC<Props> = ({
   message,
   severity = Severity.ERROR,
   action,
}) => {
   const classes = classNames(styles.alert, {
      [styles[severity]]: true,
   })

   return (
      <div className={styles.alert + ' ' + classes}>
         <div className={styles.icon}>
            <Svg id={severity} />
         </div>
         <div className={styles.message}>{message}</div>
         {action && (
            <div className={styles.close} onClick={action}>
               <Svg id="close" />
            </div>
         )}
      </div>
   )
}

export default Alert
