import { useEffect, useState } from 'react'

import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { GetItemsViewRequestOptions } from '../../types'
import { useAPI } from '../'
import { PATH } from '../../constants/global'

export default function () {
   const request = useAPI()
   const [pageOptions, setPageOptions] = useState({
      Id: null,
      ToolLoanId: null,
      KitId: null,
   })

   async function getItemsFromAPI(
      options
   ): Promise<AxiosResponse<GetItemsViewRequestOptions[]>> {
      let { Id, ToolLoanId, KitId } = options
      if (Id) {
         return request.get(
            `${PATH.ITEMS.LIST_VIEW}?Id=${Id}&ToolLoanId=${ToolLoanId}&KitId=${KitId}`
         )
      }
   }

   const { data, refetch, isLoading, isRefetching, isSuccess, remove } =
      useQuery<Promise<AxiosResponse>>('itemsListView', () =>
         getItemsFromAPI(pageOptions)
      )

   const getItemView = (obj) => {
      setPageOptions(obj)
   }

   useEffect(() => {
      refetch()
   }, [pageOptions])

   useEffect(() => () => remove(), [])

   return {
      data: data || [],
      getItemView,
      isLoading,
      isRefetching,
      isSuccess,
   }
}
