// @ts-ignore
import React, { useState, useEffect, Fragment } from 'react'
import { isEmployeeRole } from '../../../common/utils/roles'
import useProfile from '../../../hooks/useProfile/useProfile'
import * as modalButtonStyles from './itemButtons.module.scss'
import { ItemButtonsProps } from './types'
import Svg from '../svg/svg'

export const ItemButtons = ({
   itemButtons = [],
   permissions = [],
}: ItemButtonsProps) => {
   const { profile } = useProfile()
   const userRoles = profile?.Roles || []
   const isEmployeeUser = isEmployeeRole(userRoles)

   const isPermitted = (arr: string[], value: string) => {
      if (!isEmployeeUser) {
         if (value === '') return true // return as this doesn't require a permission or type
      }
      return arr.some((item) => item === value)
   }

   return (
      <>
         <div className="item-buttons" style={{ marginTop: '.25rem' }}>
            {itemButtons.map((item, index) => (
               <Fragment key={`${item.title}_${index}`}>
                  {isPermitted(permissions, item.type) && (
                     <button
                        id={String(index)}
                        className={`itemButton-${index}`}
                        onClick={item.action}
                        title={item.title}
                        type="button"
                        style={{ padding: '.1rem', margin: '.3rem .2rem' }}
                     >
                        <div className={modalButtonStyles.innerButtonContent}>
                           <div className={modalButtonStyles.iconWrapper}>
                              <Svg
                                 id={item.name}
                                 width={24}
                                 height={24}
                                 overrideViewBox={item?.overrideViewBox}
                              />
                           </div>
                           <div className={modalButtonStyles.iconTitle}>
                              {item.title.toUpperCase()}
                           </div>
                        </div>
                     </button>
                  )}
               </Fragment>
            ))}
         </div>
      </>
   )
}
