import React from 'react'
import Button from '../button/button'
import { Actions } from '../../../constants/tables'
import * as styles from './crudForm.module.scss'
import { CrudFormProps } from './types'

export const CrudForm = ({
   actionState,
   primaryActionText,
   secondaryActionText,
   primaryDeleteText,
   children,
   handleClose,
   onReset,
   buttonRef,
}: CrudFormProps) => {
   const isDeleteState = actionState === Actions.Delete

   const closeAndReset = () => {
      handleClose()
      onReset()
   }

   return (
      <div className="crud-form">
         <div className="value">{children}</div>
         <div className={styles.buttonContainer}>
            <div className={styles.buttonItem}>
               <Button
                  minWidth="0"
                  variant={isDeleteState ? 'danger' : 'primary'}
               >
                  {isDeleteState ? primaryDeleteText : primaryActionText}
               </Button>
            </div>
            <div className={styles.buttonItem}>
               <Button
                  isReset
                  buttonRef={buttonRef}
                  onClick={closeAndReset}
                  minWidth="0"
                  variant="tertiary"
               >
                  {secondaryActionText}
               </Button>
            </div>
         </div>
      </div>
   )
}
