import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import {
   UseManufacturers,
   ManufacturersResponse,
} from './useManufacturers.type'

export default function (): UseManufacturers {
   const request = useAPI()
   const { data, isLoading, refetch, isRefetching } = useQuery(
      'manufacturers',
      getManufacturersFromAPI
   )

   async function getManufacturersFromAPI(): Promise<
      AxiosResponse<ManufacturersResponse>
   > {
      try {
         return await request.get(PATH.MANUFACTURERS.FILTER)
      } catch (error) {
         // TODO: handle errors better
      }
   }

   return {
      isLoadingManufacturers: isLoading || isRefetching,
      list: data?.data?.Manufacturers ?? [],
      refetchManufacturers: refetch,
   }
}
