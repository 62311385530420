import { PATH } from '../../../constants/global'
import { TABLE, CRUD } from '../../../constants/tables'
import { UserRoles } from '../../../constants/users'
import { Severity } from '../../../types'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'

export const CRUD_TABLE = {
   downloadPath: PATH.CONNECTIONS.DOWNLOAD,
   extraClassName: 'table-actions',
   params: '',
   modalTitle: ' Connection',
   placeholder: ' connections',
   addButtonText: 'Add Connection',
   modalAddTitle: 'Title',
   key: 'Connections',
   defaultActiveValue: {
      id: '',
      title: '',
      sendNotification: false,
      DueDateReminder: true,
   },
   defaultActionState: 0,
   objectKey: 'title',
   objectKeyTitleCase: 'Title',
   objectKeyNotificationCase: 'SendNotification',
   primaryActionText: CRUD.primaryActionText,
   secondaryActionText: CRUD.secondaryActionText,
   primaryDeleteText: CRUD.primaryDeleteText,
   crudPaths: PATH.CONNECTIONS,
   tableTitle: TABLE.TITLE.CONNECTIONS,
   header: 'Title',
   toggleTittle: 'Creation Notification',

   downloadRequest: {
      skip: 0,
      take: 0,
   },
}

export const allRoleOptions: Option[] = [
   { value: UserRoles.ADMIN.toString(), label: 'Admin' },
   { value: UserRoles.EMPLOYEE.toString(), label: 'Employee' },
   { value: UserRoles.VIRTUAL.toString(), label: 'Virtual' },
]

export const itemDropDownChangeConstants = {
   ROLE: 'Role',
   LOCATION: 'Location',
}

export const warningModalRoleChangeMessage: string =
   'If you are changing roles please make sure user logs out and logs back in on his mobile device so that their dashboard reflects new role.'

export const defaultConnection = {
   ID: '',
   PictureURL: '',
   FirstName: '',
   LastName: '',
   Phone: '',
   Address1: '',
   Address2: '',
   Address3: '',
   Address: '',
   City: '',
   State: '',
   ZipCode: '',
   Email: '',
   Status: null,
   Role: null,
   AutoAcceptToolAssignments: false,
   AllowToolTransferToVirtualConnections: false,
   RequireScanToAccept: false,
   Location: null,
   Password: null,
   ConfirmPassword: null,
   RoleId: null,
   LocationId: null,
   SetPassword: null,
   AllowAddTool: false,
   RestrictItemDelete: false,
}

export const defaultSnackbar = {
   disableAutoClose: false,
   message: '',
   severity: Severity.INFO,
}

export const trialConnectionLimitMessage = `You have reached the connections limit for your free account. Explore ShareMyToolbox's paid system for more connections and capabilities.`

export const defaultConnectionLimitMessage =
   'You have reached the Employee and Admin Connections limit for your current Subscription. To increase the number of Users on your account, click on Subscription in the menu. Need help? Email us at info@sharemytoolbox.com.'
