enum UserRoles {
   SUPERUSER = 1,
   COMPANY = 2,
   ADMIN = 3,
   EMPLOYEE = 4,
   VIRTUAL = 6,
}

const isEmployeeRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.EMPLOYEE)

const isAdminRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.ADMIN)

const isCompanyRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.COMPANY)

const isSuperuserRole = (roles: number[]): boolean =>
   roles.includes(UserRoles.SUPERUSER)

export {
   UserRoles,
   isEmployeeRole,
   isAdminRole,
   isCompanyRole,
   isSuperuserRole,
}
