import { UseTableOptions } from './useTableOptions.type'
import { useSettings } from '../index'

// THIS IS USED FOR TABLE COLUMN REORDERING
export default function (tableId: string): UseTableOptions {
   const {
      isLoading,
      isUpdating,
      settings,
      update: updateSettings,
   } = useSettings()

   const columnOptionsAsJSON = settings?.ColumnOptions
      ? JSON.parse(settings?.ColumnOptions)
      : {}

   const update = (columnList: string[]) => {
      const newTableOptions = {
         ...columnOptionsAsJSON,
         [tableId]: columnList,
      }

      updateSettings({
         ...settings,
         ColumnOptions: JSON.stringify(newTableOptions),
      })
   }

   return {
      isLoading: isLoading || isUpdating,
      tableColumnOptions: columnOptionsAsJSON[tableId],
      update,
   }
}
