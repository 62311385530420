import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI } from '..';

import { UseConditions, ConditionResponse } from './useConditions.type';

export default function(): UseConditions {
    const request = useAPI();
    const { data, isLoading } = useQuery('conditions', getConditionsFromAPI);

    async function getConditionsFromAPI(): Promise<AxiosResponse<ConditionResponse>> {
        try {
            return await request.get(PATH.CONDITIONS.LIST);
        } catch (error) {
            // TODO: handle errors better
            console.log(error);
        }
    }

    return {
        isLoading,
        list: data?.data?.Data || [],
    };
};