import React, { MouseEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import { HiOutlinePlusSm, HiOutlineMinusSm } from 'react-icons/hi';

import { Props } from './accordion.type';
import * as styles from './accordion.module.scss';

const Accordion: React.FC<Props> = props => {
    const { children, className, id, isOpen, onClick, title } = props;
    const [open, setOpen] = useState(isOpen || false);

    const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setOpen(!open);

        if (onClick) onClick(id, !open);
    }

    const sectionId = `sect-${id}`;
    const buttonId = `btn-${id}`;

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen])

    return (
        <div className={classNames(
            styles.wrapper,
            className
        )}>
            <h3 className={styles.title}>
                <button
                    aria-expanded={open}
                    aria-controls={sectionId}
                    id={buttonId}
                    onClick={handleOnClick}
                    className={classNames(
                        styles.button,
                        {
                            [styles.buttonActive]: open
                        }
                    )}
                >
                    <span>{title}</span>
                    <span className={styles.icon}>
                        {open ? <HiOutlineMinusSm /> : <HiOutlinePlusSm />}
                    </span>
                </button>
            </h3>
            <div
                className={styles.content}
                id={sectionId}
                role='region'
                aria-labelledby={buttonId}
                hidden={!open}
            >
                {children}
            </div>
        </div>
    );
};

export default Accordion;