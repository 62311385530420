import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { UseCategories, CategoriesResponse } from './useCategories.type'

export default function (): UseCategories {
   const request = useAPI()
   const { data, isLoading, refetch, isRefetching } = useQuery(
      'categories',
      getCategoriesFromAPI
   )

   async function getCategoriesFromAPI(): Promise<
      AxiosResponse<CategoriesResponse>
   > {
      try {
         return await request.get(PATH.CATEGORIES.LIST)
      } catch (error) {
         // TODO: handle errors better
         console.log(error)
      }
   }

   return {
      isLoadingCategories: isLoading || isRefetching,
      list: data?.data?.Categories ?? [],
      refetchCategories: refetch,
   }
}
