import React, { memo } from 'react'
import * as styles from './warningModal.module.scss'
import { Row, Col } from 'react-grid-system'
import { Button, Modal } from '..'
import { WarningModalProps } from './types'

export const WarningModal = ({
   isModalOpen,
   handleClose,
   title,
   maxWidth,
   message,
   zIndex,
   primaryButtonTitle,
   primaryButtonMethod,
   primaryButtonVariant,
   secondaryButtonTitle,
   secondaryButtonMethod,
   secondaryButtonVariant,
   hasChildren,
   children,
   fixedSize = true,
}: WarningModalProps) => (
   <Modal
      isModalVisible={isModalOpen}
      closeModal={handleClose}
      title={title}
      maxWidth={maxWidth}
      zIndex={zIndex}
   >
      <div
         className={
            fixedSize
               ? styles.warningModalMessageWrapper
               : styles.warningModalMessageWrapperNoFixedSize
         }
      >
         <p>{message}</p>
         {hasChildren && <div>{children}</div>}
         <Row>
            {primaryButtonTitle && !secondaryButtonTitle && (
               <Col xs={12}>
                  <Button
                     minWidth="100%"
                     variant={primaryButtonVariant}
                     onClick={primaryButtonMethod ? primaryButtonMethod : null}
                     isReset
                  >
                     {primaryButtonTitle}
                  </Button>
               </Col>
            )}
            {primaryButtonTitle && secondaryButtonTitle && (
               <>
                  <Col xs={6}>
                     <Button
                        minWidth="100%"
                        variant={primaryButtonVariant}
                        onClick={
                           primaryButtonMethod ? primaryButtonMethod : null
                        }
                        isReset
                     >
                        {primaryButtonTitle}
                     </Button>
                  </Col>
                  <Col xs={6}>
                     <Button
                        minWidth="100%"
                        variant={secondaryButtonVariant}
                        onClick={
                           secondaryButtonMethod ? secondaryButtonMethod : null
                        }
                        isReset
                     >
                        {secondaryButtonTitle}
                     </Button>
                  </Col>
               </>
            )}
         </Row>
      </div>
   </Modal>
)

export default memo(WarningModal)
