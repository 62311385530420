import React from 'react';

interface Props {
    children: any
}

export const ClearFix: React.FunctionComponent<Props> = ({children}) => {
    return (
        <>
            <body>
                {children}
            </body>
        </>
    )
}