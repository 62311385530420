// extracted by mini-css-extract-plugin
export var available = "tile-new-module--available--DyqYY";
export var borrowed = "tile-new-module--borrowed--HQhIQ";
export var column = "tile-new-module--column--eYCss";
export var loaned = "tile-new-module--loaned--TEtLi";
export var pending = "tile-new-module--pending--yHH7H";
export var tile = "tile-new-module--tile--PKlE7";
export var tile_content = "tile-new-module--tile_content--+Z6V7";
export var tile_dueBack = "tile-new-module--tile_dueBack---UlJ0";
export var tile_footer = "tile-new-module--tile_footer--qW+uR";
export var tile_header = "tile-new-module--tile_header--gd6rj";
export var tile_icons = "tile-new-module--tile_icons--XdTS2";
export var tile_image = "tile-new-module--tile_image--e1tpB";
export var tile_info = "tile-new-module--tile_info--MVar5";
export var tile_keyValue = "tile-new-module--tile_keyValue--giRoT";
export var tile_location = "tile-new-module--tile_location--78ZjX";
export var tile_locationDue = "tile-new-module--tile_locationDue--8X05e";
export var tile_locationIcon = "tile-new-module--tile_locationIcon--KnrFt";
export var tile_owner = "tile-new-module--tile_owner--x-yiM";
export var tile_status = "tile-new-module--tile_status--OTLP3";
export var tile_statusLabel = "tile-new-module--tile_statusLabel--BZ0dn";
export var tile_subheader = "tile-new-module--tile_subheader--BBlO3";
export var tile_top = "tile-new-module--tile_top--aAZk1";
export var unavailable = "tile-new-module--unavailable--f6ilf";