import React from 'react';

import * as styles from './fieldError.module.scss';

interface Props {
    text?: string;
}

const FieldError: React.FC<Props> = ({ text }) => (
    <div className={styles.wrapper}>{text}</div>
);

export default FieldError;