import { useMutation } from 'react-query';

import { useAPI } from '..';
import { PATH } from '../../constants/global';

import { ChangePasswordValues, useChangePassword } from './useChangePassword.type';

export default function(): useChangePassword {
    const request = useAPI();
    const { error, isLoading, isSuccess, mutate } = useMutation((values: ChangePasswordValues) =>
        request.post(PATH.PROFILE.RESET_PASSWORD, values));

    const save = (values: ChangePasswordValues): void => {
        mutate(values);
    }

    const { ModelState = {}, Message } = error?.response?.data || {};
    const ModelStateErrorsAsArray = Object.values(ModelState);

    return {
        error: !!ModelStateErrorsAsArray?.length ? ModelStateErrorsAsArray[0] : Message,
        isLoading,
        isSuccess,
        save,
    }
}
