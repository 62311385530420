import { ROUTES } from '../../routes'

export const itemsNavList = [
   {
      label: 'Items',
      route: ROUTES.items,
   },
   {
      label: 'Mass Transfer',
      route: ROUTES.items_massTransfer,
   },
]
