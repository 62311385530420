import React from 'react'
import { Row, Col } from 'react-grid-system'
import Dropdown from '../../common/form/fields/dropdown/dropdown'
import { WarehouseTransferViewProps } from '../actionsWorkflow.type'

export const WarehouseTransferView = ({
   validationErrors,
   handleDropdownChange,
   locationOptions,
}: WarehouseTransferViewProps) => (
   <>
      <Row gutterWidth={20}>
         <Col md={6} lg={6}>
            <Dropdown
               error={validationErrors?.NewLocation}
               name="NewLocation"
               onChange={(option) => handleDropdownChange('Location', option)}
               options={locationOptions}
               id="NewLocation"
               label="NewLocation"
               placeholder="New Location"
               isMulti={false}
               required
            />
         </Col>
         <Col md={6} lg={6}></Col>
      </Row>
      <Row gutterWidth={20}>
         <Col md={6} lg={6}></Col>
         <Col md={6} lg={6}></Col>
      </Row>
   </>
)
