import React, { useState, useEffect } from 'react'
import { ItemButtons } from './ItemButtons'
import { ModalProps } from './types'
import Svg from '../svg/svg'

export const Modal = ({
   isModalVisible,
   closeModal,
   title,
   children,
   maxWidth,
   zIndex,
   marginTop,
   marginLeft,
   itemButtons = [],
   permissions = [],
   left,
   stickyHeader = false,
   headerColour,
   closeSvgFill,
   hideCloseIcon,
   displayOverlay = true,
   modalInnerStyles,
}: ModalProps) => {
   const [isItemButtons, setIsItemButtons] = useState(
      Boolean(itemButtons.length)
   )

   const isModalClosed = isModalVisible ? '' : 'modal-hidden'

   useEffect(() => {
      setIsItemButtons(Boolean(itemButtons.length))
   }, [itemButtons])

   return (
      <>
         <div
            id="modalOverlay"
            className={displayOverlay ? `modal-overlay ` + isModalClosed : ''}
            style={{ zIndex }}
         />
         <div
            id="modalWrapper"
            className={displayOverlay ? `modal-wrapper ` + isModalClosed : ''}
            style={{ zIndex, marginTop, marginLeft, left }}
         >
            <div
               className="modal-inner"
               style={{ maxWidth, ...modalInnerStyles }}
            >
               <header
                  className="modal-header"
                  style={{
                     zIndex: stickyHeader ? 999 : 0,
                     backgroundColor: headerColour,
                  }}
               >
                  <div className="title">{title}</div>

                  {isItemButtons && (
                     <ItemButtons
                        permissions={permissions}
                        itemButtons={itemButtons}
                     />
                  )}
                  {!hideCloseIcon && (
                     <button
                        type="reset"
                        onClick={() => closeModal()}
                        className="close-button icon-button"
                     >
                        <Svg id={'close'} fill={closeSvgFill} />
                     </button>
                  )}
               </header>
               <main className="modal-content">{children}</main>
            </div>
         </div>
      </>
   )
}
