export enum UserRoles {
    SUPERUSER = 1,
    COMPANY = 2,
    ADMIN = 3,
    EMPLOYEE = 4,
    VIRTUAL = 6
}

export const isEmployeeRole = (roles: number[]): boolean =>
    roles.includes(UserRoles.EMPLOYEE);

export const isAdminRole = (roles: number[]): boolean =>
    roles.includes(UserRoles.ADMIN);

export const isCompanyRole = (roles: number[]): boolean =>
    roles.includes(UserRoles.COMPANY);

export const isSuperuserRole = (roles: number[]): boolean =>
    roles.includes(UserRoles.SUPERUSER);