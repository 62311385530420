import React, { useState } from 'react'
import { Asserts, ObjectSchema, ValidationError } from 'yup'

type FormValues = { [k: string]: string }
type SubmitHandler = (values: FormValues) => any

export default (schema: ObjectSchema<any>, handleSubmit: SubmitHandler) => {
   const [validationErrors, setValidationErrors] = useState<
      Record<string, string>
   >({})

   async function validate<T>(
      schema: ObjectSchema<any>,
      values: any
   ): Promise<T> {
      await schema.validate(values, { abortEarly: false })
      return schema.cast(values)
   }

   function onReset() {
      setValidationErrors({})
   }

   function onSubmit(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault()
      onReset()

      const values = Object.fromEntries(
         new FormData(e.currentTarget).entries()
      ) as FormValues

      validate<Asserts<typeof schema>>(schema, values)
         .then(() => {
            handleSubmit(values)
         })
         .catch((err) => {
            if (err instanceof ValidationError) {
               const errors: Record<string, string> = {}
               err.inner.forEach((error) => {
                  if (error.path) {
                     errors[error.path] = error.message
                  }
               })

               setValidationErrors(errors)
            }
         })
   }

   return {
      onSubmit,
      onReset,
      validationErrors,
   }
}
