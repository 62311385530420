import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI } from '..';

import { UseGpsBluetooth, GpsResponse } from './useGpsBluetooth.type';
import { useState } from 'react';

export default function(): UseGpsBluetooth {
    const request = useAPI();
    const [toolId, setToolId] = useState<string | number | null>(null);

    // GPS
    const { data, isLoading: isLoadingLocationsList, refetch, isRefetching: isRefetchingLocationsList } = 
        useQuery('gps-tool-locations', getGpsLocationsFromAPI, {enabled: !!toolId});

    async function getGpsLocationsFromAPI(): Promise<AxiosResponse<GpsResponse>> {
        try {
            return await request.get(`${PATH.ITEM_GPS_LOCATIONS.LIST}${toolId}`);
        } catch (error) {
            // TODO: handle errors better
        }
    }

    const getToolGpsLocations = (toolId: string | number) => {
        setToolId(toolId);
    }

    return {
        getToolGpsLocations,
        isLoadingLocationsList,
        isRefetchingLocationsList,
        gpsLocationsList: data?.data?.Data?.GPS || [],
        refetchGpsLocations: refetch
    };
};