
 export const executeReverseGeocodeLookup = (latlngObject, retrieveResultsFunction) => {
    const geocoder = new google.maps.Geocoder();
    geocoder
    .geocode( {location: latlngObject})
    .then((response) => {
      if (response.results[0]) {
        retrieveResultsFunction(response.results[0].formatted_address)
      } else {
        retrieveResultsFunction("No Lookup details found")
      }
    })
    .catch((e) => console.log("Geocoder failed due to: " + e));
}
