import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI, useSettings } from '..';

import { UseItemTotals, ItemCountResponse } from './useItemTotals.type';


export default function(): UseItemTotals {
    const request = useAPI();
    const { settings } = useSettings();

    const { data, isLoading, isRefetching } = useQuery('item-count', getItemTotalsFromAPI);

    async function getItemTotalsFromAPI(): Promise<AxiosResponse<ItemCountResponse>> {
        try {
            return await request.get(PATH.DASHBOARD.ITEM_COUNT);
        } catch (error) {
            // TODO: handle errors better
            console.log(error);
        }
    }

    const {
        MyAvailable: available,
        MyUnavailable: unavailable,
        MyLoaned: loaned,
        MyBorrowed: borrowed,
        PendingTools: pending,
        MyConsumableAvailable: consumableAvailable,
    } = data?.data || {};

 
  
    return {
        isLoading: isLoading || isRefetching,
        data: {
            available,
            pending,
            borrowed,
            loaned,
            ...(!!settings?.ConsumablesEnabled ? { consumableAvailable } : {}),
            unavailable,
        },
    };
};