import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from '../../common'
import { ImageMatchModalProps } from './types'
import { useProfile } from '../../../hooks'
import ProgressView from './progressView'
import CompleteView from './completeView'

const ImageMatchModal = ({
   isOpen,
   isSuccess,
   errorMessage,
   handleClose,
   matchedImages,
   handleImageSelection,
   handleAddMatchedImages,
   maxAllowedImages,
}: ImageMatchModalProps) => {
   const { profile } = useProfile()
   const [percentage, setPercentage] = useState<number>(0)

   useEffect(() => {
      if (isOpen) {
         const intervalId = setInterval(() => {
            setPercentage((prevCount) => prevCount + 1)
         }, 200)

         // stop progress bar at 98 in case request has not completed
         if (percentage === 98) {
            return clearInterval(intervalId)
         }

         if (isSuccess || errorMessage) {
            setPercentage(100)
            return clearInterval(intervalId)
         }

         return () => clearInterval(intervalId)
      } else {
         setPercentage(0)
      }
   }, [isOpen, percentage])

   const heading = useMemo(() => {
      if (errorMessage) {
         return 'Sorry! Image Match Could Not Complete.'
      } else if (isSuccess) {
         return `${
            profile?.Firstname ? profile.Firstname + ', ' : ''
         }Your Image Match is Complete!`
      } else {
         return 'Performing Image Match'
      }
   }, [isSuccess, errorMessage])

   return (
      <Modal
         isModalVisible={isOpen}
         closeModal={handleClose}
         title="AI Image Match"
         maxWidth="713px"
      >
         {!isSuccess && !errorMessage ? (
            <ProgressView percentage={percentage} handleClose={handleClose} />
         ) : (
            <CompleteView
               heading={heading}
               error={errorMessage !== null}
               errorMessage={errorMessage}
               handleClose={handleClose}
               matchedImages={matchedImages}
               handleImageSelection={handleImageSelection}
               handleAddMatchedImages={handleAddMatchedImages}
               maxAllowedImages={maxAllowedImages > 4 ? 4 : maxAllowedImages}
            />
         )}
      </Modal>
   )
}

export default ImageMatchModal
