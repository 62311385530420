import React, { useState, useEffect } from 'react'
import * as noteStyles from '../../views/notes/notes.module.scss'
import { Severity } from '../../../types';
import { Col, Row } from 'react-grid-system';
import { Button, LoadingOverlay } from '../../common';
import { DateInput, Dropdown, Input, Textarea, Toggle } from '../../common/form/fields';

import { useCrudItems } from '../../../hooks';
import { CRUD_TABLE } from '../../views/notes/constants'

interface Props {
      itemForm: {},
      noteTypeOptions: {}[],
      handleClose: () => {},
      isVisibleSnackbar: boolean,
      setIsVisibleSnackbar: () => {},
      severityStatus: number,
      setSeverityStatus: () => {}
      setMessage: () => {}
      setIsModalVisible: any
      setItemStatus: any,
      refetchTableData: () => void
}

const AddNote: React.FC<Props> = ({
      itemForm,
      noteTypeOptions,
      handleClose,
      isVisibleSnackbar,
      setIsVisibleSnackbar,
      severityStatus,
      setSeverityStatus,
      setMessage,
      setIsModalVisible,
      setItemStatus,
      refetchTableData
   }) => {

   const {
      add,
      setPath,
      isSuccessCreate,
      isErrorCreate,
      isLoading,
      isRefetching,
      isCreating,
      isUpdating,
      isSuccessUpdate,
      isErrorUpdate,
      isRemoving,
      isSuccessRemove,
      isErrorRemove,
   } = useCrudItems({ query: '', skip: 0, take: 10, apiMethod: 'post' });

   const initialNoteForm = {
      "Completed": false,
      "CompletedBy": "",
      "CompletedDate": null,
      "DueDate": "",
      "Id": itemForm.Id,
      "NoteText": "",
      "NoteTypeId": "",
      "ToolId": itemForm.ToolId
   }

   const [ isError, setIsError ] = useState(true)
   const [ noteCompleted, setNoteCompleted ] = useState(false)
   const [noteForm, setNoteForm] = useState(initialNoteForm)


   function handleMessage(message, severity) {
      setSeverityStatus(severity)
      setMessage(message)
      setIsVisibleSnackbar(true)
   }

   const handleCompletedToggle = () => {
      setNoteCompleted(!noteCompleted);
      if (!noteCompleted) {
         setNoteForm({...noteForm, CompletedDate: new Date()})
      } else {
         setNoteForm({...noteForm, CompletedDate: ''})
      }
   }

   useEffect(() => {
      setNoteForm({
         ...noteForm,
         Completed: noteCompleted
      })
   },[noteCompleted])

   useEffect(() => {
      setNoteForm({
         ...noteForm,
         ToolId: itemForm.Id || null
      })
   },[itemForm])

   useEffect(() => {
      if (isSuccessCreate) {
         handleMessage("Note has been created.", Severity.SUCCESS)
         setNoteForm(initialNoteForm)
         setIsModalVisible(true);
         refetchTableData();
         setItemStatus(4); //TODO CHANGE TO CONSTANTS
         handleClose();  
      }
   },[isSuccessCreate])

   const onSubmitErrorMessage = `This Field is Required`;
   const [completedDateError, setCompletedDateError] = useState<string>('');
   const [completedByError, setCompletedByError] = useState<string>('')

   // handle errors
   useEffect(() => {
      if (isErrorCreate) {
         handleMessage("Creating a note has been unsuccessful.", Severity.ERROR);

         if (noteCompleted) {
            if (!noteForm.CompletedDate) {
               setCompletedDateError(onSubmitErrorMessage);
            }
            if (!noteForm.CompletedBy) {
               setCompletedByError(onSubmitErrorMessage)
            } 
         }    
      }
   },[isErrorCreate])

   // handle remove errors when rectified
   useEffect(() => {
      if (noteCompleted && noteForm.CompletedDate) {
         setCompletedDateError('');
      }
      if (noteCompleted && noteForm.CompletedBy) {
         setCompletedByError('');
      }
   }, [noteForm])

   const handleDropdown = (e: { value: number; label: string; }) => {
      setDefaultNoteType({
         value: e.value,
         label: e.label
      })
      setNoteForm({
         ...noteForm,
         NoteTypeId: e.value
      })
   }

   const handleSave = () => {
      add(noteForm);
   }

   const handleDate = (key: string, value: Date) => {
      updateNotes(key, value)
   }

   const handleUpdate = (key: string, e: React.ChangeEvent<HTMLFormElement>) => {
      updateNotes(key, e.target.value)
   }

   const updateNotes = (key: string, value: number | string | Date) => {
      setNoteForm({
         ...noteForm,
         [key]: value
      })
   }

   const showSnackbar = isSuccessUpdate || isSuccessCreate || isSuccessRemove || isErrorCreate || isErrorUpdate || isErrorRemove;
   const showLoadingSpinner = isLoading || isUpdating || isCreating || isRemoving || isRefetching || isUpdating || isUpdating;

   const {
      Title,
      SerialNumber,
      Barcode,
   } = itemForm;

   const {
      Completed,
      CompletedBy,
      CompletedDate,
      DueDate,
      NoteText,
      NoteTypeId
   } = noteForm;

   const {
      key,
      crudPaths,
   } = CRUD_TABLE


   useEffect(() => {
      setPath(crudPaths, key)
   },[])

   const [ defaultNoteType, setDefaultNoteType ] = useState('')

   useEffect(() => {
      if ( defaultNoteType !== '') setIsError(false)
   },[defaultNoteType])

   return (
      <div className={noteStyles.form}>
         {showLoadingSpinner && <LoadingOverlay />}
          <Input
             id='ToolId'
             label='Item Title'
             disabled
             value={ Title }
          />
          <Row gutterWidth={20}>
             <Col xs={6} md={6}>
                <Input
                   id='ToolSerialNumber'
                   label='Serial Number'
                   disabled={true}
                   noMargin
                   value={SerialNumber}
                />
             </Col>
             <Col xs={6} md={6}>
                <Input
                   id='ToolBarcode'
                   label='Bar Code'
                   disabled={true}
                   noMargin
                   value={Barcode}
                />
             </Col>
          </Row>
          <hr />
          <Textarea
             id='NoteText'
             height={100}
             label='Text'
             value={NoteText}
             onChange={e => handleUpdate('NoteText', e)}
          />

          <Row gutterWidth={20}>
             <Col xs={6} md={6}>
                <Dropdown
                   description='Note types are used for reporting and are setup on the Settings tab.'
                   name='NoteTypeId'
                   onChange={handleDropdown}
                   defaultValue={defaultNoteType || null}
                   options={noteTypeOptions}
                   id='NoteTypeId'
                   label='Note Type'
                   placeholder='Note Type'
                   isMulti={false}
                   required
                />
                <DateInput
                   id='DueDate'
                   label='Due Date'
                   description='If a due date is entered both the admin and tool holder will receive a notification on this date.'
                   onChange={e => handleDate('DueDate', e)}
                   selectedDate={DueDate ? new Date(Date.parse(DueDate)) : null}
                />
             </Col>
             <Col xs={6} md={6}>
                <div className={noteStyles.toggle}>
                   <div className={noteStyles.toggleField}>
                      <Toggle
                         id='Completed'
                         size='md'
                         isChecked={noteCompleted}
                         onToggle={handleCompletedToggle}
                      />
                   </div>
                   <div className={noteStyles.toggleLabel}>Completed</div>
                </div>
                <DateInput
                   id='CompletedDate'
                   label='Completed Date'
                   onChange={e => handleDate('CompletedDate', e)}
                   selectedDate={CompletedDate ? new Date(Date.parse(CompletedDate)) : null}
                   error={completedDateError}
               />
                <Input
                   id='CompletedBy'
                   label='Completed By'
                   value={CompletedBy}
                   onChange={e => handleUpdate('CompletedBy', e)}
                   error={completedByError}
                />
             </Col>
          </Row>

          <Row gutterWidth={20}>
             <Col xs={6}>
                <Button
                   onClick={handleSave}
                   disabled={isError}
                   minWidth='100%'>Save
                </Button>
             </Col>
             <Col xs={6}>
                <Button
                   minWidth='100%'
                   variant='tertiary'
                   onClick={() =>handleClose()}
                   isReset
                >
                   Cancel
                </Button>
             </Col>
          </Row>
       </div>
   )
}

export default AddNote;