import React, { useState, useEffect, useMemo } from 'react'
import * as stylesCrudForm from '../crud/crudForm.module.scss'
import { Col, Row } from 'react-grid-system'
import { AddToKitProps } from './crudItems.type'
import { Input, Dropdown } from '../form/fields'
import { Button, Modal } from '..'

export const AddToKitModal = ({
   isModalOpen,
   handleClose,
   handleAddToKit,
   setAddToKitQty,
   cancel,
   selectKit,
   addToKitQty = 1,
   itemForm,
}: AddToKitProps) => {
   const [query, setQuery] = useState<object>({})
   const [isToolType, setIsToolType] = useState<boolean>(false)
   const [name, setName] = useState<string>('')

   useEffect(() => {
      setIsToolType(itemForm.ToolType === 2 || itemForm.ToolType === 4)
   }, [itemForm])

   const handleDropdownChange = (name: {
      value: string | number
      label: string
   }) => {
      setName(String(name.value))

      setQuery({
         KitID: name.value,
         KitItems: [
            {
               KitItemID: itemForm.Id,
               Quantity: addToKitQty,
               ToolLoanId: itemForm.ToolLoanId,
            },
         ],
      })
   }

   const updateQty = (q: number) => {
      setAddToKitQty(q)

      setQuery({
         KitID: name,
         KitItems: [
            {
               KitItemID: itemForm.Id,
               Quantity: q,
               ToolLoanId: itemForm.ToolLoanId,
            },
         ],
      })
   }

   const buttonDisabled = useMemo(
      () =>
         name === '' || !addToKitQty || addToKitQty > itemForm.StatusQuantity,
      [addToKitQty, name, itemForm]
   )

   return (
      <Modal
         isModalVisible={isModalOpen}
         closeModal={handleClose}
         title="Add to Kit"
         stickyHeader={true}
      >
         <div className={stylesCrudForm.addToKitModalMessageWrapper}>
            <Row>
               <Col>
                  <div>
                     <Dropdown
                        isMulti={false}
                        id="SelectKit"
                        label="Select Kit"
                        name="SelectKit"
                        //@ts-ignore
                        onChange={handleDropdownChange}
                        options={selectKit}
                        isClearable={false}
                        menuPosition={'fixed'}
                        className={stylesCrudForm.addToKitModalDropdown}
                     />
                  </div>
               </Col>
            </Row>
            <Row>
               {isToolType && (
                  <Col>
                     <Input
                        id="KitQuantity"
                        onChange={(e) => updateQty(e.target.value)}
                        label="Kit Quantity"
                        placeholder="-"
                        value={addToKitQty}
                        inputProps={{
                           type: 'number',
                           max: itemForm.StatusQuantity,
                        }}
                        error={
                           addToKitQty > itemForm.StatusQuantity &&
                           `There is a maximum quantity of ${itemForm.StatusQuantity} available for this item.`
                        }
                     />
                  </Col>
               )}
            </Row>
            <Row>
               <Col>
                  <Button
                     minWidth="100%"
                     variant="primary"
                     disabled={buttonDisabled}
                     onClick={() => handleAddToKit(query)}
                  >
                     Add to Kit
                  </Button>
               </Col>
               <Col>
                  <Button minWidth="100%" variant="tertiary" onClick={cancel}>
                     Cancel
                  </Button>
               </Col>
            </Row>
         </div>
      </Modal>
   )
}
