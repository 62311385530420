import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { PATH } from '../../constants/global';
import { useAPI } from '..';

import { UseItemTypes, ItemTypesResponse } from './useItemTypes.type';


export default function(): UseItemTypes {
    const request = useAPI();
    const { data, isLoading } = useQuery('item-types', getItemTypesFromAPI);

    async function getItemTypesFromAPI(): Promise<AxiosResponse<ItemTypesResponse>> {
        try {
            return await request.get(PATH.ITEM_TYPES.LIST);
        } catch (error) {
            // TODO: handle errors better
            console.log(error);
        }
    }

    return {
        isLoading,
        list: data?.data || [],
    };
};