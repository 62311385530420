import React from 'react'
import * as noteStyles from './notes.module.scss'
import { Col, Row } from 'react-grid-system'
import { Button, LoadingOverlay, Svg } from '../../common/'

import { AttachmentBadge } from './attachmentBadge'
import { ToolNotePermission } from '../../../types'
import { FileAttachment } from './noteTypes'

interface NoteAttachmentPickerProps {
   attachments: FileAttachment[] | File[]
   handleAddAttachment: () => void
   handleCheckRemoveAttachment: (
      e: any,
      attachment: File | FileAttachment
   ) => void
   isUploading: boolean
   isDeleteAttachmentPending: boolean
   selectedNotePermission: ToolNotePermission
   selectedNoteHasId: boolean
   isReadonly: boolean
}

export const NoteAttachmentPicker: React.FunctionComponent<NoteAttachmentPickerProps> =
   ({
      attachments,
      handleAddAttachment,
      handleCheckRemoveAttachment,
      isUploading,
      isDeleteAttachmentPending,
      selectedNotePermission,
      selectedNoteHasId,
      isReadonly,
   }) => {
      return (
         <section className={noteStyles.attachmentWrapper}>
            <header className={noteStyles.attachmentHeader}>
               <Svg id="attachmentPaperclip" width={23} />
               Attachments
               {(isUploading || isDeleteAttachmentPending) && (
                  <>
                     <div className={noteStyles.attachmentLoadingSpinner}>
                        <div>
                           <LoadingOverlay positionAbsolute xs />
                        </div>

                        <small
                           style={{
                              fontSize: '1rem',
                              zIndex: '9999',
                              fontWeight: '900',
                           }}
                        >
                           {isUploading
                              ? '...uploading'
                              : isDeleteAttachmentPending
                              ? '...removing'
                              : ''}
                        </small>
                     </div>
                  </>
               )}
            </header>

            <div className={noteStyles.attachmentInner}>
               <Row gutterWidth={20}>
                  {attachments.map((attachment, i) => {
                     return (
                        <AttachmentBadge
                           key={`${attachment.FileName}_${i}`}
                           attachment={attachment}
                           handleCheckRemoveAttachment={
                              handleCheckRemoveAttachment
                           }
                           selectedNotePermission={selectedNotePermission}
                           selectedNoteHasId={selectedNoteHasId}
                           isReadonly={isReadonly}
                        />
                     )
                  })}
                  <Col xs={12} md={4}>
                     {(selectedNotePermission > ToolNotePermission.View ||
                        !selectedNoteHasId) &&
                        !isReadonly && (
                           <Button
                              minWidth="100%"
                              variant="plainV2"
                              isReset
                              onClick={handleAddAttachment}
                              small={true}
                           >
                              <Svg id="plus" />
                           </Button>
                        )}
                  </Col>
               </Row>
            </div>
         </section>
      )
   }
