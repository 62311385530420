import React from 'react'
import * as noteStyles from './notes.module.scss'
import { Col } from 'react-grid-system'
import { Button, Svg } from '../../common/'
import { truncateMiddle } from '../../../common/utils/functions'
import { FileAttachment } from './noteTypes'
import { ToolNotePermission } from '../../../types'

interface AttachmentBadgeProps {
   attachment: FileAttachment | File
   handleCheckRemoveAttachment: (e: any, attachment: FileAttachment) => void
   selectedNotePermission: ToolNotePermission
   selectedNoteHasId: boolean
   isReadonly: boolean
}

export const AttachmentBadge: React.FunctionComponent<AttachmentBadgeProps> = ({
   attachment,
   handleCheckRemoveAttachment,
   selectedNotePermission,
   selectedNoteHasId,
   isReadonly,
}) => {
   return (
      <Col xs={12} md={4} style={{ marginBottom: '0.5rem' }}>
         <Button
            minWidth="100%"
            variant="plainV2"
            isReset
            additionalStyles={{
               justifyContent: 'flex-start',
               alignItems: 'center',
               textDecoration: 'none',
               color: '#000',
            }}
            small={true}
            type={'link'}
            href={attachment?.URL}
            download={true}
         >
            <div>
               <div className={noteStyles.attachmentBtnContent}>
                  <Svg
                     id={
                        attachment.Type?.includes('image')
                           ? 'imageIcon'
                           : 'documentIcon'
                     }
                  />

                  <div className={noteStyles.fileText}>
                     {truncateMiddle(attachment?.FileName)}
                  </div>
                  <div
                     className={noteStyles.deleteIcon}
                     style={{
                        visibility:
                           (selectedNotePermission >=
                              ToolNotePermission.Delete ||
                              !selectedNoteHasId) &&
                           !isReadonly
                              ? 'visible'
                              : 'hidden',
                     }}
                     onClick={(e) => handleCheckRemoveAttachment(e, attachment)}
                  >
                     <Svg id={'delete'} width={1000} height={1200} />
                  </div>
               </div>
            </div>
         </Button>
      </Col>
   )
}
