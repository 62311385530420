import { isBrowser } from './functions'

const getUser = () => {
   if (!isBrowser()) return null

   return JSON.parse(window?.localStorage?.getItem('ShareMyToolboxUser'))
}

const setUser = (data) => {
   if (!isBrowser()) return

   return window?.localStorage?.setItem(
      'ShareMyToolboxUser',
      JSON.stringify(data)
   )
}

const handleLogin = (data) => setUser(data)

const isLoggedIn = (): boolean => {
   const user = getUser()

   return !!user?.access_token
}

export { getUser, setUser, handleLogin, isLoggedIn }
