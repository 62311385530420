import React from 'react';
import * as styles from './radioButton.module.scss';
import { RadioButtonProps } from './radioButton.type'
import classNames from 'classnames';

const RadioButtonContainer: React.FunctionComponent<RadioButtonProps> = ({
        children,
        variant = 'standard',
        maxWidth = '100%'
    }) => {

    const classes = classNames(
        styles.radioButtonForm,
        {
            [styles.radioFormStandard]: variant === 'standard',
            [styles.radioFormIcon]: variant === 'icons',
            [styles.radioFormFilter]: variant === 'filter',
        }
    );

    const baseProps = {
        className: classes,
    }

    return (
        <div { ...baseProps } style={{ maxWidth }}>
            { variant !== 'icons' &&
                <div className={styles.radioButton}>
                    { children }
                </div>
            }
            { variant === 'icons' &&
                <span>
                    <div className={styles.radioButton}>
                        { children }
                    </div>
                </span>
            }
        </div>
    )
}

export default RadioButtonContainer;
