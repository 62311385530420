import React, { useState, useEffect, useMemo } from 'react'
import * as styles from './crudItems.module.scss'
import * as stylesCrudForm from '../crud/crudForm.module.scss'
import { Col, Row } from 'react-grid-system'
import { Actions } from '../../../constants/tables'
import { CrudItemsProps } from './crudItems.type'
import {
   RadioButtonContainer,
   RadioButton,
   Button,
   Modal,
   Svg,
   Alert,
   ImageCard,
   ImageViewer,
   ImageMatchButton,
   UploadImageButton,
} from '../../common'
import { ItemDetails, Rental, OtherDetails, Notes, History } from './'
import { useSettings } from '../../../hooks'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { LoadingOverlay, ImageMatchModal } from '../../common'
import { Severity, ToolTypes } from '../../../types'
import { ItemGPSView } from './itemGPSView/itemGPSView'
import useImageMatch from '../../../hooks/useImageMatch/useImageMatch'
import {
   CatalogueImage,
   ImageMatchPayload,
   UploadMatchedImagesPayload,
} from '../../../hooks/useImageMatch/types'
import { CrudItemsParentView } from '../../../enums/CrudItemsEnums'
import { randomString } from '../../../common/utils/functions'

const CrudItems = ({
   isNewItemNote,
   setIsNewItemNote,
   postItem,
   itemImages,
   setItemImages,
   changePosition,
   deleteImage,
   isPositionLoading,
   isPositionSuccess,
   setItemStatus,
   itemStatus,
   activeValue,
   isLoading,
   itemTypes,
   categories,
   manufacturers,
   locations,
   conditions,
   itemNotesData,
   itemHistoryData,
   handleAction,
   closeModal,
   itemAction,
   setItemAction,
   itemId,
   isModalVisible,
   setItemForm,
   itemForm,
   resetRef,
   getItemView,
   refetchNoteTableData,
   handleAddOrRemoveFromCart,
   isItemAlreadyAddedToCart,
   isEmployeeUserViewingItemsPage,
   parentPageView = null,
   handleWorkflowAction,
   username,
   createItemSuccess,
   createItemResponse,
   isAdminCompanyUser,
}: CrudItemsProps) => {
   const { settings } = useSettings()
   const [toggleValue, setToggleValue] = useState(
      Boolean(itemForm.IsRentalTool)
   ) // changed from item settings
   const [isSecondaryModalVisible, setIsSecondaryModalVisible] = useState(false)
   const [isTitleError, setIsTitleError] = useState<boolean | string>('')

   const [isQuantityOrConsumableToolType, setIsQuantityOrConsumableToolType] =
      useState(false)
   const [isQuantityItem, setIsQuantityItem] = useState(false)

   const isItemCurrentlyInActionCart = useMemo(() => {
      if (isItemAlreadyAddedToCart) {
         return isItemAlreadyAddedToCart(itemForm.localUniqueId)
      }
      return false
   }, [itemForm])

   useEffect(() => {
      setIsQuantityOrConsumableToolType(
         activeValue?.ToolType == ToolTypes['Quantity Item'] ||
            activeValue?.ToolType == ToolTypes['Consumable']
      )
      setIsQuantityItem(activeValue?.ToolType == ToolTypes['Quantity Item'])
   }, [activeValue])

   useEffect(() => {
      if (itemAction === Actions.Add) {
         setIsQuantityOrConsumableToolType(
            itemForm.ToolType == ToolTypes['Quantity Item'] ||
               itemForm.ToolType == ToolTypes['Consumable']
         )
         setIsQuantityItem(itemForm.ToolType == ToolTypes['Quantity Item'])
      }
   }, [itemForm])

   useEffect(() => {
      // if employeeUser on items page and are not adding an item -> override any other action to become readonly
      if (
         isEmployeeUserViewingItemsPage &&
         setItemAction &&
         itemAction !== Actions.Add
      ) {
         setItemAction(Actions.Readonly)
      }
   }, [])

   const closeSecondaryModal = () => {
      setIsSecondaryModalVisible(false)
      resetRef.current.click()
   }

   const closeAllModals = () => {
      setIsSecondaryModalVisible(false)
      resetRef.current.click()
      closeModal()
   }

   const handleToggle = () => {
      setItemForm({
         ...itemForm,
         IsRentalTool: !toggleValue,
      })
      setToggleValue(!toggleValue)
   }

   const [updateAction, setUpdateAction] = useState<any>({})

   const updateForm = (key, value) => {
      setItemForm({
         ...itemForm,
         [key]: value,
      })
      setUpdateAction({ key, value, itemForm })
   }

   const updateFormSelect = (obj: {}) => {
      setUpdateAction({ obj })
   }

   useEffect(() => {
      const { key, value, obj } = updateAction
      if (!updateAction.hasOwnProperty('key')) {
         // @ts-ignore
         setItemForm({
            ...itemForm,
            ...obj,
         })
      }

      if (
         (updateAction.hasOwnProperty('key') &&
            key === 'Title' &&
            value !== '') ||
         key !== 'Title'
      ) {
         setIsTitleError('')
         // @ts-ignore
         setItemForm({
            ...itemForm,
            ...obj,
            [key]: value,
         })
      } else {
         setIsTitleError('Title field is required')
      }
   }, [updateAction])

   const radioItemStatus = [
      { label: 'Details', status: 0 },
      { label: 'Images', status: 1 },
      { label: 'Other', status: 2 },
      { label: 'Rental', status: 3 },
      { label: 'Notes', status: 4 },
      { label: 'History', status: 5 },
      { label: 'Map', status: 6 },
   ]
   const [radioItems, _setRadioItems] = useState(radioItemStatus)

   const { PictureURLs } = itemForm

   const [shadowPictureUrlsList, setShadowPictureUrlsList] = useState(
      PictureURLs ?? []
   ) // used to track image urls

   // START: Drag image
   const onDragEnd = (result: {
      destination: { index: number }
      source: { index: number }
   }) => {
      if (!result.destination) {
         return
      }

      const _items = reorder(
         itemImages,
         result.source.index,
         result.destination.index
      )

      const _urls = reorder(
         shadowPictureUrlsList,
         result.source.index,
         result.destination.index
      )

      setShadowPictureUrlsList(_urls)
      setItemImages(_items)
   }
   // END: Drag image

   const resetImageShuffle = () => {
      // setIsImageAdding(false)
      setUploadImageDisabled(false)
   }

   const handleShuffle = () => {
      getItemView({
         Id: itemForm.Id,
         ToolLoanId: itemForm.ToolLoanId,
         KitId: itemForm.KitId,
      })
      setTimeout(resetImageShuffle, 500)
   }

   useEffect(() => {
      if (itemImages.length && isPositionSuccess === true) {
         // setIsImageAdding(true)
         setUploadImageDisabled(true)
         handleShuffle()
      }
   }, [isPositionSuccess])

   const [deleteImageData, setDeleteImageData] = useState([])
   const [existingPositions, _setExistingPositions] = useState(
      PictureURLs?.map((url) => url.Position) ?? []
   )

   const removeItemImage = (id: number, index: number) => {
      const deleteByPosition = itemImages
         .map((image) => {
            if (image.Id === id) return image.OriginalPosition
         })
         .filter(Number)

      setDeleteImageData([
         ...deleteImageData,
         deleteByPosition.length !== 0 ? deleteByPosition : [0],
      ])

      const updatedItemImages = itemImages
         .filter((item) => item.Id !== id)
         .map((image, index) => {
            // update image positions after removing
            image.Position = index
            return image
         })

      const updatedUrls = shadowPictureUrlsList
         .filter((url, urlIndex) => urlIndex !== index)
         .map((url, urlIndex) => {
            url.Position = urlIndex
            if (urlIndex === 0) {
               url.IsPrimary = true
            }
            return url
         })

      const arr = actionList.filter((el) => {
         return id !== el.uiId
      })

      setActionList(arr)
      setItemImages(updatedItemImages)
      setShadowPictureUrlsList(updatedUrls)
   }

   const reorder = (
      list: Iterable<any> | ArrayLike<any>,
      startIndex: number,
      endIndex: number
   ) => {
      const result = Array.from(list)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      const processed = result.map((item, index) => {
         return {
            ...item,
            Position: index,
         }
      })
      return processed
   }

   const [actionList, setActionList] = useState<any[]>([{}])

   const onImageUpload = (
      file: Blob,
      newFile: Blob,
      mimeType: string,
      imageUrl: string
   ) => {
      const nextItem = `item-${itemImages.length + 1}-${randomString()}`
      const addedImage = {
         justAdded: true,
         Id: nextItem,
         Toolid: itemForm.Id,
         Position: itemImages.length,
         FullUrl: imageUrl,
         PictureUrl: imageUrl,
         MimeType: mimeType,
         ThumbnailUrl: imageUrl,
         'File[0]': file,
         'File[1]': newFile,
         IsMatchedImage: false,
      }
      setItemImages((prevState) => [...prevState, addedImage])
   }

   const [uploadImageDisabled, setUploadImageDisabled] =
      useState<boolean>(false)

   useEffect(() => {
      setItemForm({
         ...itemForm,
         itemImages: remapId(itemImages),
      })
   }, [itemImages])

   const remapId = (arr: []) => {
      return arr.map((item: {}, index: number) => {
         return {
            ...item,
            Id: index,
            Position: index,
         }
      })
   }

   const handleSecondaryModal = () => {
      closeAllModals()
   }

   useEffect(() => {
      // handle existing images on load
      if (PictureURLs && !itemForm.copyId) {
         const existingImages = []
         PictureURLs.map((obj) => {
            const newId = `item-${itemImages.length + 1}-${randomString()}`
            const imageToAdd = {
               ...obj,
               Id: newId,
               OriginalId: obj.Id,
               OriginalPosition: obj.Position,
            }
            existingImages.push(imageToAdd)
         })
         setItemImages(existingImages)
      }
   }, [PictureURLs])

   const removeImage = () => {
      const toolId = itemForm.Id
      const deleteImageDataFlat = deleteImageData.flat()
      deleteImageDataFlat.forEach((position) => {
         deleteImage({ toolId, position: existingPositions[position] })
      })
   }

   const handleImageAdditionsAndDeletions = () => {
      // if item wasn't copied then update positions
      if (!itemForm.copyId) {
         if (shadowPictureUrlsList.length && itemForm.Id) {
            const positionsToUpdate = shadowPictureUrlsList.map((url) => ({
               Id: url.Id,
               Position: url.Position,
            }))
            changePosition({
               toolId: itemForm.Id,
               images: positionsToUpdate,
            })
         }

         // if any image deleted remove
         if (deleteImageData.length) {
            removeImage()
         }

         // if item already existed then add any added images
         if (itemForm.Id) {
            const addedImages = itemImages.filter((image) => image.justAdded)
            addedImages?.length > 0 &&
               addedImages.forEach((img) =>
                  postItem(
                     {
                        Toolid: itemForm.Id ? itemForm.Id : null,
                        Position: img.Position,
                        'File[0]': img['File[0]'],
                        'File[1]': img['File[1]'],
                     },
                     itemForm.Id
                  )
               )
         }
      }
   }

   const crudItemsPrimaryButtonMessage = useMemo(() => {
      if (itemAction !== Actions.Readonly) {
         return 'Save'
      } else if (
         itemAction === Actions.Readonly &&
         parentPageView === CrudItemsParentView.Actions &&
         isItemCurrentlyInActionCart
      ) {
         return 'Remove From Cart'
      } else if (
         itemAction === Actions.Readonly &&
         parentPageView === CrudItemsParentView.Actions &&
         !isItemCurrentlyInActionCart
      ) {
         return 'Add to Cart'
      } else {
         return 'Save'
      }
   }, [itemForm])

   // Image gallery / viewer functionality
   const [openImageGallery, setOpenImageGallery] = useState<boolean>(false)

   const imagesForGallery = useMemo(
      () =>
         itemImages.map((img) => ({ src: img.FullUrl ?? img.PictureFullURL })),
      [itemImages]
   )

   const allowViewGallery = useMemo(
      () => imagesForGallery?.length > 0,
      [imagesForGallery]
   )

   const viewGalleryTooltip = useMemo(
      () => (allowViewGallery ? '' : 'Item has no images to view'),
      [allowViewGallery]
   )

   const handleViewGalleryClick = () => setOpenImageGallery(!openImageGallery)

   // Image match functionality
   const {
      imageMatch,
      imageMatchData,
      imageMatchError,
      isImageMatchLoading,
      isImageMatchError,
      isImageMatchSuccess,
      uploadMatchedImages,
   } = useImageMatch()

   const [imageMatchModalOpen, setImageMatchModalOpen] =
      useState<boolean>(false)
   const [matchedImages, setMatchedImages] = useState<CatalogueImage[]>([])

   useEffect(() => {
      if (isImageMatchLoading) {
         setImageMatchModalOpen(true)
      }
   }, [isImageMatchLoading])

   useEffect(() => {
      if (imageMatchData && imageMatchData?.CatalogueImages?.length > 0) {
         setMatchedImages(imageMatchData.CatalogueImages)
      }
   }, [imageMatchData])

   const handleImageMatchClick = async () => {
      const payload: ImageMatchPayload = {
         Manufacturer: itemForm?.Manufacturer,
         ManufacturerID: Number(itemForm?.ManufacturerId),
         ModelNumber: itemForm?.ModelNumber,
      }
      await imageMatch(payload)
   }

   const handleImageSelection = (selectedImage: CatalogueImage) => {
      const updatedImages = matchedImages.map((image) =>
         image.PictureFullURL === selectedImage.PictureFullURL &&
         image.Position === selectedImage.Position
            ? {
                 ...image,
                 selected: image?.selected ? false : true,
              }
            : image
      )
      setMatchedImages(updatedImages)
   }

   const handleAddMatchedImages = () => {
      matchedImages
         .filter((image) => image.selected)
         .map((image, index) => handleAddMatchedImage(image, index))
      setMatchedImages([])
      setImageMatchModalOpen(false)
   }

   const handleAddMatchedImage = (image: CatalogueImage, index: number) => {
      const isDuplicateImage = !!itemImages.find(
         (img) => img.FullUrl === image.PictureFullURL
      )
      // if its a duplicate image then dont add it to images array
      if (!isDuplicateImage) {
         const newId = `item-${itemImages.length + 1}-${randomString()}`
         const Position = itemImages.length + index
         const newImage = {
            ...image,
            Id: newId,
            Toolid: itemForm.Id,
            Position: Position,
            FullUrl: image.PictureFullURL,
            IsMatchedImage: true,
         }
         setItemImages((prevState) => [...prevState, newImage])
      }
   }

   const uploadCatalogueImages = async (toolId?: string) => {
      const imagesToUpload: CatalogueImage[] = itemImages?.filter(
         (img: CatalogueImage) => img?.IsMatchedImage
      )
      if (imagesToUpload?.length > 0) {
         const reqPayload: UploadMatchedImagesPayload = {
            toolId: toolId,
            Manufacturer: itemForm?.Manufacturer,
            ManufacturerID: Number(itemForm?.ManufacturerId),
            ModelNumber: itemForm?.ModelNumber,
            selectedImages: imagesToUpload,
         }
         await uploadMatchedImages(reqPayload)
      }
   }

   const handleSave = async () => {
      if (!itemForm?.Title) {
         return setIsTitleError(true)
      }
      handleImageAdditionsAndDeletions()

      if (itemAction !== Actions.Add) {
         await uploadCatalogueImages(itemForm?.Id)
      }
      handleAction(itemAction, itemId, itemForm)

      if (itemAction !== Actions.Add) {
         resetRef?.current.click()
         closeModal()
      }
   }

   useEffect(() => {
      if (createItemSuccess) {
         const createdItemId = createItemResponse?.data?.Data?.Id
         uploadCatalogueImages(createdItemId)
         resetRef?.current.click()
         closeModal()
      }
   }, [createItemSuccess, createItemResponse])

   const allowImageMatch = useMemo(
      () =>
         isAdminCompanyUser &&
         itemForm?.Manufacturer !== '' &&
         itemForm?.ManufacturerId !== 132 &&
         itemForm?.ModelNumber &&
         itemForm?.ModelNumber !== ''
            ? true
            : false,
      [itemForm, isAdminCompanyUser]
   )

   const imageLimitReached = useMemo(
      () => itemImages?.length >= 5,
      [itemImages]
   )

   const imageMatchTooltip = useMemo(
      () =>
         imageLimitReached
            ? 'Maximum 5 images allowed. Delete one to run Image Match.'
            : '',
      [imageLimitReached]
   )

   const maxAllowedImages = useMemo(
      () => 5 - (itemImages?.length ?? 0),
      [itemImages]
   )

   const imageMatchErrorMessage: string | null = useMemo(() => {
      if (isImageMatchError && imageMatchError) {
         return imageMatchError
      }
      if (isImageMatchSuccess && matchedImages.length === 0) {
         return 'No Images were matched to this item.'
      }
      return null
   }, [isImageMatchError, imageMatchError, matchedImages, isImageMatchSuccess])

   return (
      <div className={styles.crudItems}>
         <RadioButtonContainer variant="filter" id={''} groupName={''}>
            {radioItems.map(
               (item: { label: string; status: number }, index: number) => {
                  return (
                     <RadioButton
                        resetRef={index === 0 ? resetRef : null}
                        key={index}
                        id={`${index}radio-item-modal`}
                        groupName="itemsAddEdit"
                        variant="filter"
                        checked={item.status === itemStatus}
                        action={() => setItemStatus(item.status)}
                        hide={
                           itemAction !== Actions.Edit &&
                           itemAction !== Actions.Readonly &&
                           (item.label === 'Notes' || item.label === 'History')
                        }
                        disabled={isLoading}
                     >
                        {item.label}
                     </RadioButton>
                  )
               }
            )}
         </RadioButtonContainer>

         {/*Item Details*/}
         {itemStatus === 0 && isModalVisible && (
            <ItemDetails
               activeValue={activeValue}
               isQuantityOrConsumableToolType={isQuantityOrConsumableToolType}
               isQuantityItem={isQuantityItem}
               itemTypes={itemTypes}
               itemForm={itemForm}
               itemAction={itemAction}
               locations={locations}
               manufacturers={manufacturers}
               categories={categories}
               updateForm={updateForm}
               updateFormSelect={updateFormSelect}
               isTitleError={isTitleError}
               showEditItems={
                  itemAction === Actions.Edit || itemAction === Actions.Readonly
               }
               settings={settings}
               isEmployeeUser={isEmployeeUserViewingItemsPage}
            />
         )}
         {/* Images Tab */}
         {itemStatus === 1 && (
            <>
               {isPositionLoading && <LoadingOverlay />}
               <div
                  className={styles.itemColumns}
                  style={{
                     pointerEvents:
                        itemAction === Actions.Readonly ? 'none' : 'auto',
                  }}
               >
                  <div className={styles.columnImages}>
                     {itemForm && (
                        <DragDropContext onDragEnd={onDragEnd}>
                           <Droppable
                              droppableId="droppable"
                              direction="horizontal"
                           >
                              {(provided, _snapshot) => (
                                 <div
                                    ref={provided.innerRef}
                                    className={stylesCrudForm.addItemsDraggable}
                                    {...provided.droppableProps}
                                 >
                                    {itemImages.map((img, index) => (
                                       <Draggable
                                          key={img.Id}
                                          draggableId={img.Id}
                                          index={index}
                                       >
                                          {(provided, _snapshot) => (
                                             <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                             >
                                                <ImageCard
                                                   alt={img.content}
                                                   src={img.FullUrl}
                                                   isPrimaryImage={index === 0}
                                                   isMatchedImage={
                                                      img.IsMatchedImage ||
                                                      img.AIImageId > 0
                                                   }
                                                   onDeleteClick={() =>
                                                      removeItemImage(
                                                         img.Id,
                                                         index
                                                      )
                                                   }
                                                />
                                             </div>
                                          )}
                                       </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    {!imageLimitReached && (
                                       <UploadImageButton
                                          isDisabled={uploadImageDisabled}
                                          onImageUpload={onImageUpload}
                                       />
                                    )}
                                 </div>
                              )}
                           </Droppable>
                        </DragDropContext>
                     )}
                  </div>
                  <div className={styles.columnImageActionButtons}>
                     <Button
                        type="button"
                        variant={!allowViewGallery ? 'tertiary' : 'primary'}
                        preserveText
                        onClick={handleViewGalleryClick}
                        disabled={!allowViewGallery}
                        title={viewGalleryTooltip}
                        icon={
                           <Svg
                              id="view"
                              fill={!allowViewGallery ? '#373737' : 'white'}
                           />
                        }
                     >
                        View Gallery
                     </Button>
                     {allowViewGallery && (
                        <ImageViewer
                           open={openImageGallery}
                           setOpen={setOpenImageGallery}
                           images={imagesForGallery}
                        />
                     )}
                     {allowImageMatch && (
                        <>
                           <ImageMatchButton
                              isTertiary={
                                 imageMatchModalOpen || imageLimitReached
                              }
                              disabled={
                                 itemAction === Actions.Readonly ||
                                 imageLimitReached
                              }
                              tooltip={imageMatchTooltip}
                              onClick={handleImageMatchClick}
                           />
                           <ImageMatchModal
                              isOpen={imageMatchModalOpen}
                              handleClose={() => setImageMatchModalOpen(false)}
                              isSuccess={isImageMatchSuccess}
                              errorMessage={imageMatchErrorMessage}
                              matchedImages={matchedImages}
                              handleImageSelection={handleImageSelection}
                              handleAddMatchedImages={handleAddMatchedImages}
                              maxAllowedImages={maxAllowedImages}
                           />
                        </>
                     )}
                  </div>
               </div>
               {itemAction === Actions.Edit && (
                  <div className={styles.placeholder} />
               )}
            </>
         )}

         {itemStatus === 2 && isModalVisible && (
            <>
               <div className={styles.itemColumns}>
                  <div
                     className={styles.columnOne}
                     style={{
                        pointerEvents:
                           itemAction === Actions.Readonly ? 'none' : 'auto',
                     }}
                  >
                     {/* Other tab */}
                     <OtherDetails
                        activeValue={activeValue}
                        itemForm={itemForm}
                        updateForm={updateForm}
                        updateFormSelect={updateFormSelect}
                        settings={settings}
                        isQuantityOrConsumableToolType={
                           isQuantityOrConsumableToolType
                        }
                        conditions={conditions}
                        handleWorkflowAction={handleWorkflowAction}
                        isEmployeeUser={isEmployeeUserViewingItemsPage}
                     />
                  </div>
               </div>
               {itemAction === Actions.Edit && (
                  <div className={styles.placeholder} />
               )}
            </>
         )}
         {itemStatus === 3 && isModalVisible && (
            <>
               <div className={styles.itemColumns}>
                  <div
                     className={styles.columnOne}
                     style={{
                        pointerEvents:
                           itemAction === Actions.Readonly ? 'none' : 'auto',
                     }}
                  >
                     <Rental
                        activeValue={activeValue}
                        itemForm={itemForm}
                        updateForm={updateForm}
                        updateFormSelect={updateFormSelect}
                        settings={settings}
                        toggleValue={toggleValue}
                        handleToggle={handleToggle}
                     />
                  </div>
               </div>
               {itemAction === Actions.Edit && (
                  <div className={styles.placeholder} />
               )}
            </>
         )}
         {itemStatus === 4 && (
            <>
               {itemNotesData?.length === 0 && (
                  <Alert
                     message="No notes have been found."
                     severity={Severity.INFO}
                  />
               )}
               <div className={styles.note}>
                  <Notes
                     itemId={itemId}
                     toolLoanId={itemForm?.ToolLoanId}
                     itemNotesData={itemNotesData}
                     refetchTableData={refetchNoteTableData}
                     isNewItemNote={isNewItemNote}
                     setIsNewItemNote={setIsNewItemNote}
                     itemAction={itemAction}
                     crudItemsParentPageView={parentPageView}
                  />
               </div>
               {itemAction === Actions.Edit && (
                  <div className={styles.placeholder} />
               )}
            </>
         )}
         {itemStatus === 5 && (
            <>
               <div className={styles.itemColumns}>
                  <History itemHistoryData={itemHistoryData} />
               </div>
               {itemAction === Actions.Edit && (
                  <div className={styles.placeholder} />
               )}
            </>
         )}
         {itemStatus === 6 && (
            <>
               <ItemGPSView selectedItem={itemForm} />
            </>
         )}
         <div className={stylesCrudForm.basicForm}>
            <Row>
               {itemAction !== Actions.Readonly && (
                  <Col>
                     <Button
                        disabled={isTitleError}
                        onClick={
                           // @ts-ignore
                           itemAction !== Actions.Readonly
                              ? handleSave
                              : () =>
                                   handleAddOrRemoveFromCart(
                                      itemForm.localUniqueId
                                   )
                        }
                     >
                        {crudItemsPrimaryButtonMessage}
                     </Button>
                  </Col>
               )}
               <Col>
                  <Button variant="tertiary" onClick={handleSecondaryModal}>
                     Cancel
                  </Button>
               </Col>
            </Row>
         </div>

         <Modal
            isModalVisible={isSecondaryModalVisible}
            closeModal={() => closeSecondaryModal()}
            title="Cancel"
         >
            <div className={stylesCrudForm.basicForm}>
               <p>Are you sure you wish to cancel?</p>
               <Row>
                  <Col>
                     <Button variant="primary" onClick={() => closeAllModals()}>
                        Yes
                     </Button>
                  </Col>
                  <Col>
                     <Button
                        variant="tertiary"
                        onClick={() => closeSecondaryModal()}
                     >
                        No
                     </Button>
                  </Col>
               </Row>
            </div>
         </Modal>
      </div>
   )
}

export default CrudItems
