import { NavItems } from '../../../hooks/useNavigation/useNavigation.type';
import { ROUTES } from '../../routes';

export const profileNavList: NavItems = [
    {
        label: 'Details',
        route: ROUTES.profile,
    },
    {
        label: 'Account Settings',
        route: ROUTES.profile_accountSettings,
    },
    {
        label: 'Reset Password',
        route: ROUTES.profile_reset_password,
    },
];