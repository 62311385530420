import { useMutation } from 'react-query';

import { useAPI } from "..";
import { PATH } from '../../constants/global';

import { UseItemKit  } from './useItemKit.type';

export default function(): UseItemKit {
    const request = useAPI();
    const { error, isSuccess, isError, reset: resetRemoveSuccess, mutate } = useMutation((kitPrimeKey: number) =>
       request.post(PATH.ITEMS.REMOVE_FROM_KIT, {"KitRemoveIDs":[kitPrimeKey]}));

    const removeFromKit = (kitPrimeKey: number) => {
        mutate(kitPrimeKey);
    };

    return {
        // @ts-ignore
        error: error?.response?.data?.Message,
        isRemoveFromKitSuccess: isSuccess ? true : isError ? false : null,
        removeFromKit,
        resetRemoveSuccess
    };
}