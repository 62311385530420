import React from 'react'

import * as styles from './keyValue.module.scss'
import { Props } from './keyValue.type'
import Svg from '../svg/svg'

const KeyValue: React.FC<Props> = ({ className, label, value, svgId }) => {
   return (
      <div className={className}>
         <div className={styles.label}>
            {svgId && <Svg id={svgId} width={22} height={17} />}
            {label}
         </div>
         <div className={styles.value}>{value || 'n/a'}</div>
      </div>
   )
}

export default KeyValue
