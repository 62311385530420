import React from 'react'
import classNames from 'classnames'

import { ImageProps } from './image.type'
import * as styles from './image.module.scss'

const Image = ({
   alt,
   className,
   defaultImagePadding,
   height,
   padding = 0,
   url,
   width,
}: ImageProps) => {
   const defaultImagePath = '/icon_image_missing_no_background.svg'
   const imageHeight = !!height ? height : width
   const imageUrl = !!url ? url : defaultImagePath

   return (
      <span
         className={classNames(styles.wrapper, className)}
         style={{
            height: imageHeight,
            padding: !url ? defaultImagePadding || padding : padding,
            width,
         }}
      >
         <img className={styles.image} src={imageUrl} alt={alt} />
      </span>
   )
}

export default Image
