import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { UseUserItems, Item } from './useUserItems.type'

export default (): UseUserItems => {
   const request = useAPI()
   const { data, isLoading } = useQuery('user-items', getUserItemsFromAPI)

   async function getUserItemsFromAPI(): Promise<AxiosResponse<Item[]>> {
      try {
         return await request.get(PATH.USER_ITEMS.LIST)
      } catch (error) {
         // TODO: handle errors better
         console.log(error)
      }
   }

   return {
      isLoading,
      list: data?.data || ([] as Item[]),
   }
}
