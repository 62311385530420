import { Profile } from '../../hooks/useProfile/useProfile.type'

enum PlanTypes {
   Starter = 'Starter',
   Trial = 'Trial',
   EnterpriseYearly = 'Annually',
   EnterpriseMonthly = 'Monthly',
}

const getPlanTypeFromId = (planTypeId: number) => {
   switch (planTypeId) {
      case 0:
         return PlanTypes.Trial
      case 1:
         return PlanTypes.EnterpriseMonthly
      case 2:
         return PlanTypes.EnterpriseYearly
      case 3:
         return PlanTypes.Starter
      default:
         return 'Unknown'
   }
}

const isStarterPlan = (profile?: Profile) =>
   profile && profile.PlanType === PlanTypes.Starter

const isEnterprisePlan = (profile?: Profile) =>
   profile &&
   (profile.PlanType === PlanTypes.EnterpriseYearly ||
      profile.PlanType === PlanTypes.EnterpriseMonthly)

const isTrialPlan = (profile?: Profile) =>
   profile && profile.PlanType === PlanTypes.Trial

export {
   PlanTypes,
   isStarterPlan,
   isEnterprisePlan,
   isTrialPlan,
   getPlanTypeFromId,
}
