import React, { memo } from 'react'
import * as styles from './helpLink.module.scss'
import { HelpLinkProps } from './helpLink.types'
import Svg from '../svg/svg'

const HelpLink = ({ href }: HelpLinkProps) => (
   <a href={href} target="_blank" className={styles.helpLink} title="Help">
      <Svg id="help" fill="#FFCA05" width={40} height={40} />
   </a>
)

export default memo(HelpLink)
