import { useState } from 'react'
import { ObjectSchema, ValidationError } from 'yup'

import {
   UseFilters,
   Filters,
   FilterErrors,
   FilterValue,
   ModifyObject,
} from './useFilters.type'
import {
   cleanFilterObject,
   flattenFilterObj,
   removeFilter,
   validate,
} from './useFilters.helpers'
import { isEmptyObject } from '../../common/utils/functions'

export default function (
   schema: ObjectSchema<any>,
   initialFilters?: Filters
): UseFilters {
   const defaultFilters = initialFilters || {}

   const [tmpFilters, setTmpFilters] = useState<Filters>(defaultFilters)
   const [savedFilters, setSavedFilters] = useState<Filters>({})
   const [errors, setErrors] = useState<FilterErrors>({})

   const save = async (): Promise<boolean> => {
      setErrors({})

      const flattenedFilters = flattenFilterObj(tmpFilters)

      try {
         await validate(schema, flattenedFilters)
         setSavedFilters({ ...tmpFilters })
         return true
      } catch (error) {
         if (error instanceof ValidationError) {
            const errors: FilterErrors = {}
            error.inner.forEach((error) => {
               if (error.path) {
                  errors[error.path] = error.message
               }
            })

            setErrors(errors)
         }
         return false
      }
   }

   const remove = (name: string, id: string): Filters => {
      const newFilters = removeFilter(tmpFilters, name, id)
      const filters = isEmptyObject(newFilters) ? defaultFilters : newFilters

      setTmpFilters(filters)
      setSavedFilters(newFilters)

      return filters
   }

   const modify = (name: string, label: string, value: FilterValue) => {
      const cleanedFilters = cleanFilterObject({
         ...tmpFilters,
         [name]: { label, value },
      })

      setTmpFilters(
         isEmptyObject(cleanedFilters) ? defaultFilters : cleanedFilters
      )
   }

   const modifyMulti = (filters: ModifyObject[]) => {
      const newFilters = filters.reduce((filters, { name, label, value }) => {
         return { ...filters, [name]: { label, value } }
      }, {})

      setTmpFilters({ ...tmpFilters, ...newFilters })
   }

   const reset = () => {
      setTmpFilters(defaultFilters)
      setSavedFilters({})
      setErrors({})
   }

   return {
      errors,
      modify,
      modifyMulti,
      remove,
      reset,
      save,
      savedFilters,
      tmpFilters,
   }
}
