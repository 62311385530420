import { useState } from 'react';

import { UseGeocoder } from './useGeocoder.type';

export default (): UseGeocoder => {
    const [loading, setLoading] = useState(false);

    const geocode = async (address: string) => {
        const geocoder = new google.maps.Geocoder();
        let lat: string = '';
        let lng: string = '';

        if (!address) {
            return { lat, lng };
        }

        setLoading(true);

        try {
            await geocoder.geocode({address}, function (results, status) {
                if (status === 'OK' && results?.length > 0) {
                    lat = results[0].geometry.location.lat().toString();
                    lng = results[0].geometry.location.lng().toString();
                } else {
                    console.log(`Geocode was not successful for the following reason: ${status}`);
                }
            });

            setLoading(false);

            return { lat, lng }
        } catch(error) {
            setLoading(false);

            return { lat, lng }
        }
    }

    return {
        geocode,
        loading,
    }
}