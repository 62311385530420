enum AuditStatus {
   DRAFT = 1,
   SENT = 2,
   IN_PROGRESS = 3,
   RECEIVED = 4,
   REVIEWED = 5,
}

enum AddAuditView {
   Default = 1,
   ItemSelector = 2,
}

export { AuditStatus, AddAuditView }
