import { StaticImage } from 'gatsby-plugin-image'
import React, { memo, useMemo } from 'react'
import { ProgressBar } from '../progressBar'
import Button from '../button/button'
import * as styles from './imageMatchModal.module.scss'
import { CompleteViewProps } from './types'
import MatchedImage from '../matchedImage/matchedImage'
import { CatalogueImage } from '../../../hooks/useImageMatch/types'

const CompleteView = ({
   heading,
   error,
   errorMessage,
   handleClose,
   matchedImages,
   handleImageSelection,
   handleAddMatchedImages,
   maxAllowedImages,
}: CompleteViewProps) => {
   const numberOfSelectedImages = useMemo(
      () => matchedImages.filter((image) => image.selected).length,
      [matchedImages]
   )
   const disableSelection = useMemo(
      () => numberOfSelectedImages >= maxAllowedImages,
      [matchedImages, maxAllowedImages]
   )
   const onImageClick = (image: CatalogueImage) =>
      image.selected || !disableSelection ? handleImageSelection(image) : null

   return (
      <div className={styles.imageMatchContent}>
         <h1>{heading}</h1>
         {!error && (
            <>
               <p className={styles.infoText}>
                  Select the images you want to add to this Item, then click
                  Add.
               </p>
               <p className={styles.infoText}>
                  You can select up to a maximum of{' '}
                  <strong>{maxAllowedImages}</strong> image
                  {maxAllowedImages === 1 ? '' : 's'}.
               </p>
            </>
         )}
         {error ? (
            <>
               <div className={styles.headerImage}>
                  <StaticImage
                     src="../../../assets/images/importItems/ai-icon-large.png"
                     formats={['png']}
                     alt="Image Match Error"
                  />
               </div>
               <div className={styles.progressBarContainer}>
                  <ProgressBar
                     textAbove={errorMessage}
                     percentage={100}
                     variant="danger"
                  />
               </div>
            </>
         ) : (
            <div className={styles.imagesContainer}>
               {matchedImages.map((img, index) => (
                  <MatchedImage
                     key={index}
                     image={img}
                     onClick={onImageClick}
                     disabled={disableSelection}
                  />
               ))}
            </div>
         )}
         <div className={styles.actionButtonsContainer}>
            {!error && (
               <Button
                  variant="primary"
                  onClick={handleAddMatchedImages}
                  disabled={numberOfSelectedImages === 0}
               >
                  Add
               </Button>
            )}
            <Button variant="tertiary" onClick={handleClose}>
               {errorMessage ? 'Close' : 'Cancel'}
            </Button>
         </div>
      </div>
   )
}

export default memo(CompleteView)
