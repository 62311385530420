import React, { useState } from 'react'

import { Button, Svg } from '..'

import { ConfirmationProps } from './confirmation.type'
import * as styles from './confirmation.module.scss'

const Confirmation = ({
   children,
   onClick,
   startLink,
   startText,
}: ConfirmationProps) => {
   const [loading, setLoading] = useState(false)

   const handleClick = () => {
      onClick()
      setLoading(true)
   }

   return (
      <div className={styles.wrapper}>
         <div className={styles.icon}>
            <Svg id="tick-circle" width="130" height="130" />
         </div>

         <div className={styles.content}>{children}</div>

         <div>
            <Button href={startLink} loading={loading} onClick={handleClick}>
               {startText}
            </Button>
         </div>
      </div>
   )
}

export default Confirmation
