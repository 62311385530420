import React, { useMemo } from 'react'
import * as styles from './itemGPSInfoCard.module.scss'
import { formatLocaleDate } from '../../../../../common/utils/functions'
import { GpsLocation } from '../../../../../hooks/useGpsBluetooth/useGpsBluetooth.type'
import { GPSScanEvents } from './../../../../../enums/GPS_Bluetooth'
import { Modal } from '../../../modal'
import Svg from '../../../svg/svg'

interface props {
   isInfoCardOpen: boolean
   handleCloseInfoCard: () => void
   selectedLocation: GpsLocation
   reverseGeoLookUpResults: string
}

export const ItemGPSInfoCard: React.FunctionComponent<props> = ({
   isInfoCardOpen,
   handleCloseInfoCard,
   selectedLocation,
   reverseGeoLookUpResults,
}) => {
   const isCurrentLocation = !!selectedLocation.CurrentLocation

   const infoSvgId = useMemo(() => {
      switch (selectedLocation?.Event) {
         case GPSScanEvents.AuditScan: //
            return 'audit' //
         case GPSScanEvents.BorrowToolRequest: //
            return 'actionReturn' //
         case GPSScanEvents.AcceptedBorrowToolRequest: //
            return 'actionReturn' //
         case GPSScanEvents.BarcodeScan: //
            return 'cardViewBarcode' //
         case GPSScanEvents.LoanToolRequest: //
            return 'actionLoan' //
         case GPSScanEvents.AcceptedLoanToolRequest: //
            return 'actionLoan' //
         case GPSScanEvents.ReturnRequest: //
            return 'actionReturn' //
         case GPSScanEvents.AcceptedReturnRequest: //
            return 'actionReturn' //
         case GPSScanEvents.TransferToolFromRequest: //
            return 'actionTransfer' //
         case GPSScanEvents.TransferToolToRequest:
            return 'actionTransfer'
         case GPSScanEvents.ToolNoteCreated: //
            return 'notes' //
         case GPSScanEvents.UpdateItemUnavailable: //
            return 'make-unavailable' //
         case GPSScanEvents.UpdateItemAvailable: //
            return 'make-available' //
         case GPSScanEvents.LocationTransferred: //
            return 'actionTransfer' //
         case GPSScanEvents.AddedTokit: //
            return 'add-to-kit' //
         case GPSScanEvents.RemovedFromKit: //
            return 'remove-from-kit' //
         case GPSScanEvents.WarehouseTransfer: //
            return 'actionTransfer' //
         case GPSScanEvents.AcceptedTransferToolFromRequest: //
            return 'actionTransfer' //
         case GPSScanEvents.AcceptedTransferToolToRequest: //
            return 'actionTransfer' //
         default:
            return ''
      }
   }, [selectedLocation])

   return (
      <Modal
         isModalVisible={isInfoCardOpen}
         closeModal={handleCloseInfoCard}
         title={isCurrentLocation ? 'Current Location' : 'GPS Scan'}
         displayOverlay={false}
         zIndex="999"
         modalInnerStyles={{
            top: '0',
            left: '0',
            marginRight: '2vw',
            opacity: '0.8',
            position: 'absolute',
         }}
      >
         {!isCurrentLocation && (
            <div className={styles.infoContent}>
               <div className={styles.scannedDetails}>
                  <span className={styles.scannedInner}>
                     <div className={styles.svgWrapper}>
                        <Svg id={'gps'} width={22} />
                     </div>
                     <div className={styles.label}>
                        {selectedLocation?.CardHeading}
                     </div>
                  </span>
                  <span className={styles.label}>
                     {formatLocaleDate(selectedLocation.CreatedOn)}
                  </span>
               </div>

               <div className={styles.scannedDetails}>
                  <span className={styles.scannedInner}>
                     <div className={styles.svgWrapper}>
                        <Svg id={infoSvgId} width={18} height={16} />
                     </div>
                     <div className={styles.label}>
                        {selectedLocation?.CardDescription ?? 'N/A'}
                     </div>
                  </span>
               </div>
               <div className={styles.geoLocation}>{`Near ${
                  reverseGeoLookUpResults ?? 'N/A'
               }`}</div>
               <div className={styles.geoLocation}>
                  {`${selectedLocation.Latitude}`}&nbsp;
                  {`${selectedLocation.Longitude}`}
               </div>
            </div>
         )}
         {isCurrentLocation && (
            <div className={styles.infoContent}>
               <div className={styles.scannedDetails}>
                  <span>
                     <span className={styles.svgWrapper}>
                        <Svg
                           id={'pinNoShadow'}
                           width={12}
                           height={16}
                           fill={'#FFCA05'}
                        />
                     </span>
                     <span className={styles.label}>
                        {selectedLocation.CurrentLocation}
                     </span>
                  </span>
               </div>
               <div
                  className={styles.geoLocation}
               >{`Near ${reverseGeoLookUpResults}`}</div>
               <div
                  className={styles.geoLocation}
               >{`${selectedLocation.Latitude}  ${selectedLocation.Longitude}`}</div>
            </div>
         )}
      </Modal>
   )
}
