export type FilterValue = any

export type Filters = {
   [key: string]: {
      label: string
      value: FilterValue
   }
}

export type FiltersForAPI = {
   [key: string]: FilterValue
}

export type ModifyObject = {
   name: string
   label: string
   value: FilterValue
}

export type FilterErrors = { [key: string]: string }

export interface UseFilters {
   errors: FilterErrors
   modify: (name: string, label: string, value: FilterValue) => void
   modifyMulti: (filters: ModifyObject[]) => void
   remove: (name: string, id: string) => Filters
   reset: () => void
   save: () => Promise<boolean>
   savedFilters: Filters
   tmpFilters: Filters
}

export type SortObject = {
   sortBy: string
   sortDirection: SortDirection
}

export enum SortDirection {
   ASCENDING = 'asc',
   DESCENDING = 'desc',
}
