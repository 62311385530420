import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import {
   UseNotificationTotals,
   NotificationTotalsResponse,
} from './useNotificationTotals.type'

export default function useNotificationTotals(): UseNotificationTotals {
   const request = useAPI()
   const {
      data: regularTotals,
      isLoading: isLoadingRegular,
      isRefetching: isRefetchingRegular,
      refetch: refetchRegular,
   } = useQuery('notification-totals', getTotalsFromAPI)
   const {
      data: globalTotalData,
      isLoading: isLoadingGlobal,
      isRefetching: isRefetchingGlobal,
      refetch: refetchGlobal,
   } = useQuery('global-notification-total', getGlobalTotalFromAPI)

   async function getTotalsFromAPI(): Promise<
      AxiosResponse<NotificationTotalsResponse>
   > {
      return request.get(PATH.DASHBOARD.NOTIFICATION_TOTALS)
   }

   async function getGlobalTotalFromAPI(): Promise<{
      notificationTotals: NotificationTotalsResponse
      globalTotal: number
   }> {
      try {
         const totalsResponse = await request.get<NotificationTotalsResponse>(
            PATH.DASHBOARD.NOTIFICATION_TOTALS,
            {
               params: {
                  returnCompanyNotifications: true,
               },
            }
         )

         const globalTotal = totalsResponse.data.Data.ActionTasks || 0

         return {
            notificationTotals: totalsResponse.data,
            globalTotal: globalTotal,
         }
      } catch (error) {
         return {
            notificationTotals: {} as NotificationTotalsResponse,
            globalTotal: 0,
         }
      }
   }

   const {
      ActionTasks = 0,
      InformationalTasks = 0,
      ReminderTasks = 0,
   } = regularTotals?.data?.Data || {}

   const GlobalTasks = globalTotalData?.globalTotal || 0

   const totalNotifications = ActionTasks + InformationalTasks + ReminderTasks

   const refresh = () => {
      refetchRegular()
      refetchGlobal()
   }

   return {
      isLoading:
         isLoadingRegular ||
         isRefetchingRegular ||
         isLoadingGlobal ||
         isRefetchingGlobal,
      refresh,
      totals: {
         ActionTasks,
         InformationalTasks,
         ReminderTasks,
         GlobalTasks,
      },
      totalNotifications,
   }
}
