import React, { useMemo } from 'react'
import * as styles from '../checkoutModal.module.scss'
import { ErrorViewProps, View } from '../actionsWorkflow.type'

export const ErrorView = ({ view }: ErrorViewProps) => {
   const errorMessage = useMemo(() => {
      switch (view) {
         case View.MakeAvailable:
            return 'Your Make Available request has been unsuccessful!'
         case View.MakeUnavailable:
            return 'Your Make Unavailable request has been unsuccessful!'
         default:
            return `Your ${view} request has been unsuccessful!`
      }
   }, [view])

   return (
      <div className={styles.centeredViewWrapper}>
         <div className={styles.centeredViewHeading}>Error!</div>
         <p className={styles.centeredViewMessage}>{errorMessage}</p>
      </div>
   )
}
