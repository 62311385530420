import React from 'react'
import * as styles from './crudItems.module.scss'
import * as stylesCrudForm from '../crud/crudForm.module.scss'
import { Col, Row } from 'react-grid-system'
import { CopyPromptProps } from './crudItems.type'
import { Button } from '../../common'

export const Prompt: React.FunctionComponent<CopyPromptProps> = ({
   action,
   cancel,
   title,
   actionButton = 'Ok',
}) => {
   return (
      <div className={styles.copyPrompt}>
         <div className={stylesCrudForm.basicForm}>
            <p>{title}</p>
            <Row>
               <Col>
                  <Button variant="primary" onClick={action}>
                     {actionButton}
                  </Button>
               </Col>
               <Col>
                  <Button variant="tertiary" onClick={cancel}>
                     Cancel
                  </Button>
               </Col>
            </Row>
         </div>
      </div>
   )
}
