import React, { memo } from 'react'
import classNames from 'classnames'

import { LoadingOverlay } from '..'

import * as styles from './widget.module.scss'
import { WidgetProps } from './types'

const Widget = ({
   children,
   className,
   heading,
   hideSpinner = false,
   isLoading = false,
}: WidgetProps) => (
   <div className={classNames(styles.wrapper, className)}>
      {heading && <h2 className={styles.heading}>{heading}</h2>}
      {children}
      {!hideSpinner && isLoading && <LoadingOverlay positionAbsolute small />}
   </div>
)

export default memo(Widget)
