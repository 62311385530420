import * as React from 'react'
import classNames from 'classnames'

type ToggleProps = {
   id: any
   size: string
   label?: string
   isChecked: boolean
   onToggle: (id: any, isChecked: boolean) => void
   disabled?: boolean
}

export const Toggle = ({
   size,
   label,
   isChecked,
   onToggle,
   id,
   disabled = false,
}: ToggleProps) => {
   const handleToggle = () => {
      onToggle(id, !isChecked)
   }

   const classes = classNames({
      toggle: true,
      [`toggle-${size}`]: true,
      isChecked: isChecked,
      disabled: disabled,
   })

   return (
      <label className={classes} htmlFor={id} onClick={disabled ? undefined : handleToggle}>
         <span className="toggle-text">&nbsp;{label}</span>
      </label>
   )
}
