import { PATH } from '../../../constants/global'
import { TABLE, CRUD } from '../../../constants/tables'

export const CRUD_TABLE = {
   downloadPath: PATH.NOTES.DOWNLOAD,
   extraClassName: 'table-actions',
   params: '',
   modalTitle: ' Note',
   placeholder: ' notes',
   addButtonText: 'Add Note',
   modalAddTitle: 'Title',
   key: 'ToolNotes',
   defaultActiveValue: {
      id: '',
      title: '',
      sendNotification: false,
      DueDateReminder: true,
   },
   defaultActionState: 0,
   objectKey: 'title',
   objectKeyTitleCase: 'Title',
   objectKeyNotificationCase: 'SendNotification',
   primaryActionText: CRUD.primaryActionText,
   secondaryActionText: CRUD.secondaryActionText,
   primaryDeleteText: CRUD.primaryDeleteText,
   crudPaths: PATH.NOTES,
   tableTitle: TABLE.TITLE.NOTES,
   header: 'Title',
   toggleTittle: 'Creation Notification',

   downloadRequest: {
      skip: 0,
      take: 0,
   },
}
