import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import { useForm } from '../../../hooks'
import { CrudForm } from './CrudForm'
import { Actions } from '../../../constants/tables'
import { Input } from '../form/fields'
import { CrudActionsProps } from './types'
import { Toggle, ToggleInfo } from '../form/fields'
import * as styles from './crudForm.module.scss'

const CrudActions = ({
   actionState,
   primaryActionText,
   secondaryActionText,
   primaryDeleteText,
   id,
   name,
   modalAddTitle,
   handleAction,
   handleClose,
   handleToggle,
   toggleValue = false,
   toggleTittle,
   toggleLabel,
   inputRef,
   buttonRef,
   valueName,
}: CrudActionsProps) => {
   const yupValue = valueName
      ? `${valueName} field is required`
      : 'Name field is required'
   const schema = yup.object().shape({
      name: yup.string().required(yupValue),
   })

   const { onSubmit, validationErrors, onReset } = useForm(schema, (values) => {
      if (actionState === Actions.Add) {
         handleAction(Actions.Add, id, text)
         //@ts-ignore
         inputRef.current.value = ''
      }
      if (actionState === Actions.Edit) handleAction(Actions.Edit, id, text)
      if (actionState === Actions.Delete) handleAction(Actions.Delete, id, text)
   })

   const [text, setText] = useState('')

   // Set the prop name for useState text
   useEffect(() => {
      setText(name)
   }, [name])

   const captureText = (event: React.ChangeEvent<HTMLFormElement>) => {
      const newTitle = event.target.value
      setText(newTitle)
      onReset()
   }

   const isToggleUsed = handleToggle

   return (
      <form onSubmit={onSubmit}>
         {actionState === Actions.Edit && (
            <CrudForm
               handleClose={handleClose}
               actionState={actionState}
               primaryActionText={primaryActionText}
               secondaryActionText={secondaryActionText}
               onReset={onReset}
            >
               <>
                  <Input
                     key={id + name}
                     id="name"
                     error={validationErrors.name}
                     label={modalAddTitle}
                     value={name}
                     ref={inputRef}
                     onChange={captureText}
                     required
                  />

                  {isToggleUsed && (
                     <>
                        <div className={styles.crudItemWrapper}>
                           <div className={styles.crudItemToggle}>
                              <Toggle
                                 id={id}
                                 size="md"
                                 isChecked={toggleValue}
                                 onToggle={handleToggle}
                              />
                           </div>
                           <div className={styles.crudItemToggleInfo}>
                              <ToggleInfo
                                 name={toggleTittle}
                                 value={toggleValue}
                              />
                           </div>
                        </div>
                        <div className={styles.crudItemDescription}>
                           {toggleLabel}
                        </div>
                     </>
                  )}
               </>
            </CrudForm>
         )}

         {actionState === Actions.Delete && (
            <CrudForm
               handleClose={handleClose}
               actionState={actionState}
               primaryActionText={primaryActionText}
               secondaryActionText={secondaryActionText}
               primaryDeleteText={primaryDeleteText}
               onReset={onReset}
               buttonRef={buttonRef}
            >
               <>
                  <p>
                     Are you sure you wish to delete <strong>{name}</strong>?
                  </p>
                  <Input
                     key={id + name}
                     id="name"
                     ref={inputRef}
                     label=""
                     value={name}
                     inputProps={{
                        type: 'hidden',
                     }}
                  />
               </>
            </CrudForm>
         )}

         {actionState === Actions.Add && (
            <CrudForm
               handleClose={handleClose}
               actionState={actionState}
               primaryActionText={primaryActionText}
               secondaryActionText={secondaryActionText}
               onReset={onReset}
               buttonRef={buttonRef}
            >
               <>
                  <Input
                     id="name"
                     ref={inputRef}
                     label={modalAddTitle}
                     error={validationErrors.name}
                     onChange={captureText}
                     required
                  />

                  {isToggleUsed && (
                     <>
                        <div className={styles.crudItemWrapper}>
                           <div className={styles.crudItemToggle}>
                              <Toggle
                                 id={id}
                                 size="md"
                                 isChecked={toggleValue}
                                 onToggle={handleToggle}
                              />
                           </div>
                           <div className={styles.crudItemToggleInfo}>
                              <ToggleInfo
                                 name={toggleTittle}
                                 value={toggleValue}
                              />
                           </div>
                        </div>
                        <div className={styles.crudItemDescription}>
                           {toggleLabel}
                        </div>
                     </>
                  )}
               </>
            </CrudForm>
         )}
      </form>
   )
}

export default CrudActions
